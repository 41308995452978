import React, {useState, useEffect, useRef, useContext} from 'react';
import {AppUserApi} from "../../../shared/services/AppUserService";
import {OnboardingForm} from "../../../shared/components/onboarding-form";
import {withAuthInfo} from "../../../shared/services/UserInfo";
import UserInfo from "../../../shared/services/UserInfo";
import SharedContext from "../../../shared/context/SharedContext";
import {QuestionnaireApi} from "../../../shared/services/QuestionnaireService";
import {AppUser, Questionnaire} from "../../../shared/interfaces/loopback";
import ToastService from "../../../shared/services/ToastService";
import AlertService from "../../../shared/services/AlertService";
import {ProjectApi} from "../../../shared/services/ProjectService";
import FileUploader from "../../../shared/components/FileUploader/FileUploader";


const Edit = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const refIsMounted = useRef(true);
  const [appUserForm, setAppUserForm] = useState<Questionnaire | null>(null);
  const [appUser, setAppUser] = useState<AppUser | any>();

  const currentUserId = props.authInfo.currentUserId;
  const appUserId = (props.match.params.id === 'me' ? currentUserId : props.match.params.id) || props.authInfo.currentUserId;

  console.log('appUserId', appUserId);


  useEffect(() => {
    sharedContext.updateHeaderTitle('Mi Perfil');
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, [sharedContext]);

  useEffect(() => {
    QuestionnaireApi.findOne({where: {name: 'editAppUser'}})
      .then((questionnaire: Questionnaire) => {
        AppUserApi.findById(appUserId).then((appUser) => {
          setAppUser(appUser);
          // const profileData = appUser.profileData;
          questionnaire.values = {...appUser};
          setAppUserForm(questionnaire);
        });
      });


  }, [props.authInfo.currentUser.profileData, appUserId]);

  const saveData = ({detail: data}: any) => {
    console.log('handleFormFinished', data);
    const appUserToSave = {...appUser};
    let tempRoles = appUser.roles;
    // appUserToSave.profileData = data.values;
    // appUserToSave.profileData.isComplete = true;
    // appUserToSave.friendlyProfileData = data.friendlyValues;

    delete data.values.profileData;
    delete data.values.friendlyProfileData;
    delete data.values.createdAt;
    delete data.values.id;

    if(!data.values.password) {
      delete appUserToSave.password;
      delete data.values.password;
    }

    delete appUserToSave.roles;


    Object.assign(appUserToSave, data.values);

    delete appUserToSave.profileData;
    delete appUserToSave.friendlyProfileData;
    delete appUserToSave.createdAt;

    console.log('appUserToSave', appUserToSave);

    // return;




    AppUserApi.patch(appUserToSave)
      .then((appUser) => {

        ToastService({message: `Los datos han sido actualizados`});

        if(currentUserId === appUser.id) {
          appUser.roles = tempRoles;
          UserInfo.setCurrentUser(appUser);
          props.history.push('/private/dashboard');
        } else {
          props.history.push(`/private/profile/view/${appUser.id}`);
        }


      })
      .catch(err => {
        if (err.statusCode === 401) ToastService({
          color: 'error',
          message: `No cuentas con permisos para la operación`
        });
      })
  };

  const promptSaveInfo = (formData:any) => {
    AlertService({
      header: 'Actualizar información',
      message: '¿Desea guardar los cambios?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Guardar',
          handler: () => {
            saveData(formData)
          }
        }
      ]
    })
  };

  return (
    <>
      {appUserForm && <>
        <OnboardingForm data={appUserForm} onFormHasFinished={promptSaveInfo} />
      </>}
    </>
  );
});
export default Edit;
