import axios, {AxiosPromise} from 'axios';
import {LoopBackFilter, FavoriteProject} from "../interfaces/loopback";
import UserInfo from "./UserInfo";

export class FavoriteProjectApi {
  static RESOURCE_URL = '/FavoriteProjects/';

  static upsert(data: FavoriteProject): Promise<FavoriteProject> {
    return axios.put(`${FavoriteProjectApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static post(data: FavoriteProject): Promise<FavoriteProject> {
    return axios.post(`${FavoriteProjectApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static deleteById(id: string): Promise<FavoriteProject> {
    return axios.delete(`${FavoriteProjectApi.RESOURCE_URL}${id}`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static find(filter: LoopBackFilter = {}): Promise<FavoriteProject[]> {
    return axios.get(`${FavoriteProjectApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static findById(id: string, filter: LoopBackFilter = {}): Promise<FavoriteProject> {
    return axios.get(`${FavoriteProjectApi.RESOURCE_URL}${id}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static findOne(filter: LoopBackFilter = {}): Promise<FavoriteProject> {
    return axios.get(`${FavoriteProjectApi.RESOURCE_URL}findOne`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

}
