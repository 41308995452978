import React, {useCallback, useMemo, useState} from 'react';
import {DropzoneRootProps, useDropzone} from 'react-dropzone';
import {ContainerApi} from "../../services/ContainerService";
import {IonButton, IonFabButton, IonIcon, IonModal, IonProgressBar} from "@ionic/react";
import styles from './FileUploader.module.css'
// import {file} from "@babel/types";
import {UploadedFile} from "../../interfaces/loopback";
import ToastService from "../../services/ToastService";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#7f7f7f',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

interface FileUploading {
  file: File;
  progress: number;
  status: string;
}



const FileUploader = (props: any) => {
  const [filesUploaded, setFilesUploaded] = useState<FileUploading[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const onDrop = (acceptedFiles: any) => {
    if(!dropZoneConfig.multiple) {
      setFilesUploaded([]);
      filesUploaded.splice(0,filesUploaded.length)
    }
    acceptedFiles.forEach((file:any, index: number) => {
      const formData = new FormData();
      const fileUploadedIndex = index + filesUploaded.length;
      formData.append('file', file);
      initializeProgress(file, 'initialize');
      ContainerApi.upload(formData, {
        onUploadProgress: (progressEvent: any) => {
          const progress =  Math.round(progressEvent.loaded/progressEvent.total);
          updateProgress(fileUploadedIndex, progress)
        }
      })
      .then(response => {
        const containerFileName = getNewFileNameFromResp(response);
        const uploadedFile: UploadedFile ={fileName: file.name, containerFileName};
        const {modelApi, modelId, relationName} = props;
        const promise = assignFileUploaded(uploadedFile, modelApi, modelId, relationName);
        if(props.onFileAssigned) {
          props.onFileAssigned(promise);
        }
        return promise;
      })
      .then(resp => true)
      .catch(err => console.log(err))
    })
  };
  const onDropRejected = useCallback((rejectedFiles: any) => {
    rejectedFiles.forEach((file: File) => {
        initializeProgress(file, 'rejected')
    })
  }, []);
  const initializeProgress = (file: File, status: string) => {
    setFilesUploaded(prevFiles => {
      let files = [...prevFiles];
      files.push({file, progress: 0, status});
      return files
    });
  }
  const updateProgress = (index: number, progress: number) => {
    setFilesUploaded(prevFiles => {
      const files = [...prevFiles];
      if(progress === 1) files[index].status = 'completed';
      files[index].progress = progress;
      return files
    });
  }
  const assignFileUploaded = (uploadedFile: UploadedFile, modelApi: any, modelId: string, relationName: string): Promise<any> => {
    if(!props.modelId) return Promise.reject(null);
    const objToPatch = {['_' + relationName]: uploadedFile};
    // const objToPatch = {[relationName]: uploadedFile};

    ToastService({message: 'Archivo subido con éxito'});
    return modelApi.patchById(objToPatch, modelId);
  }

  const getNewFileNameFromResp = (resp: any) => {
    const name = resp.result.files.file[0].name;
    return name;
  }
  let dropZoneConfig = {
    maxSize: 10485760,
    onDropRejected,
    onDrop,
    multiple: false
  };
  if(props.config) dropZoneConfig = {...dropZoneConfig, ...props.config};
  const handleAccept = (index: number) => {
    const files = [...filesUploaded];
    files.splice(index, 1);
    setFilesUploaded(files);
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone(dropZoneConfig);

  const style: DropzoneRootProps = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  return (
    <>
      <IonButton onClick={()=>setShowModal(true)}>
        <IonIcon slot="start" name={'cloud-upload'}/>
        {props.buttonText || 'Subir Archivo'}
      </IonButton>
      <IonModal
        children={null}
        isOpen={showModal}
        onDidDismiss={() => console.log('closed')}>
        <div className={styles['zone-container']}>
          <div className={styles['zone-wrapper']} {...getRootProps({style})}>
            <input {...getInputProps()} />
            <p>{props.instructions || 'Arrastre un archivo al recuadro o haga click para seleccionarlo'}</p>
          </div>
          <div className={styles['file-progress-container']}>
            {filesUploaded.map((uploadingFile, index) => {
              return (
                <div key={index} className={styles['file-progress-wrapper']}>
                  {uploadingFile.status === 'completed' &&
                  <IonFabButton
                    onClick={() => handleAccept(index)}
                    size="small">
                    <IonIcon name="checkmark-circle" />
                  </IonFabButton>
                  }
                  {uploadingFile.status === 'rejected' &&
                  <>
                    <IonFabButton
                      color="danger"
                      onClick={() => handleAccept(index)}
                      size="small">
                      <IonIcon name="close-circle" />
                    </IonFabButton>
                  </>

                  }
                  <div className="full-width">
                    <label>{uploadingFile.file.name}</label>
                    {uploadingFile.status === 'rejected' && <label className={styles['error']}>  (File not accepted)</label>}
                    <IonProgressBar class={styles['file-progress-wrapper']} key={index} value={uploadingFile.progress} buffer={1}></IonProgressBar>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <IonButton onClick={() => setShowModal(false)}>
          Cerrar
        </IonButton>
      </IonModal>
    </>

  );
};
export default FileUploader;

FileUploader.propTypes = {
  // modelApi:PropTypes.object.isRequired,
  // modelId:PropTypes.string.isRequired,
  // relationName:PropTypes.string.isRequired,

};
