import React from "react";
import {Route, Switch} from "react-router";
import Edit from "./edit/Edit";
import List from "./list/List";

const Admin = (props: any) => {
  return (
    <>
      <Switch>
        <Route path={props.match.path + "/new"} component={Edit}/>
        <Route path={props.match.path + "/edit/:id"} component={Edit}/>
        <Route path={props.match.path + "/list"} component={List}/>
      </Switch>
    </>

  )
}
export default Admin;
