import axios from 'axios';
import {AppUser, LoopBackFilter, MarketReport} from "../interfaces/loopback";


export class MarketReportApi {
  static RESOURCE_URL = '/MarketReports/';

  static upsert(data: MarketReport): Promise<MarketReport> {
    return axios.put(`${MarketReportApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static find(filter: LoopBackFilter = {}): Promise<MarketReport[]> {
    return axios.get(`${MarketReportApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static findById(id: string, filter: LoopBackFilter = {}): Promise<MarketReport> {
    return axios.get(`${MarketReportApi.RESOURCE_URL}${id}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static deleteById(id: string): Promise<MarketReport> {
    return axios.delete(`${MarketReportApi.RESOURCE_URL}${id}`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static patch(data: MarketReport): Promise<MarketReport> {
    return axios.patch(`${MarketReportApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static patchById(data: MarketReport, id: string): Promise<MarketReport[]> {
    return axios.patch(`${MarketReportApi.RESOURCE_URL}${id}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

}
