import axios from 'axios';
import {LoopBackFilter, Questionnaire} from "../interfaces/loopback";

export class QuestionnaireApi {
  static RESOURCE_URL = '/Questionnaires/';

  static find(filter: LoopBackFilter = {}): Promise<Questionnaire[]> {
    return axios.get(`${QuestionnaireApi.RESOURCE_URL}`, {params: {filter}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static findById(id: string, filter: LoopBackFilter = {}): Promise<Questionnaire> {
    return axios.get(`${QuestionnaireApi.RESOURCE_URL}${id}`, {params: {filter}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static findOne(filter: LoopBackFilter = {}): Promise<Questionnaire> {
    return axios.get(`${QuestionnaireApi.RESOURCE_URL}findOne`, {params: {filter}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

}
