import axios, {AxiosPromise} from 'axios';
import {FriendRequest, Friendship, LoopBackFilter, Project} from "../interfaces/loopback";
import UserInfo from "./UserInfo"

export class FriendRequestApi {
  static RESOURCE_URL = '/FriendRequests/';

  // static find(filter: LoopBackFilter): AxiosPromise {
  //   return axios.get(`${FriendRequestApi.RESOURCE_URL}`, {
  //     params: {filter},
  //     headers: {...UserInfo.baseHeaders()}
  //   })
  // }

  static find(filter: LoopBackFilter = {}): Promise<Project[]> {
    return axios.get(`${FriendRequestApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static create(appUserId: string): Promise<FriendRequest> {
    return axios.post(`${FriendRequestApi.RESOURCE_URL}`,
      {appUserId, requesterId: UserInfo.getAuthInfo().currentUserId},
      {
        headers: {...UserInfo.baseHeaders()}
      }
    ).then(resp => resp.data)
      .catch(err => Promise.reject(err.response.data.error));
  }

  static answerRequest(friendRequestId: string, accept: boolean): Promise<Friendship>|any {
    return axios.post(`${FriendRequestApi.RESOURCE_URL}${friendRequestId}/answerRequest`,
      {accept},
      {
        headers: {...UserInfo.baseHeaders()}
      }
    ).then(resp => resp.data)
      .catch(err => Promise.reject(err.response.data.error));
  }

  // static answerRequestHandler(friendRequestId: string, accept: boolean, onSuccess?: Function) {
  //   FriendRequestApi.answerRequest(friendRequestId, accept).then((result:any) => {
  //     onSuccess && onSuccess();
  //     console.log(result);
  //   }).catch((error:any) => {
  //     console.error(error);
  //   })
  // }
}


