import {IonCard, IonCardHeader, IonCardTitle} from "@ionic/react";
import styles from "./MxStateCodeChart.module.css";
import React from "react";
import {Bar} from "react-chartjs-2";
import _ from "lodash";

const MxStateCodeChart = (props: any) => {

  if (!props.analyticsEvents) {
    return null;
  }
  const categoriesData = [
    {label: "Aguascalientes", value: "AS"},
    {label: "Baja California", value: "BC"},
    {label: "Baja California Sur", value: "BS"},
    {label: "Campeche", value: "CC"},
    {label: "Chiapas", value: "CS"},
    {label: "Chihuahua", value: "CH"},
    {label: "Ciudad de México", value: "DF"},
    {label: "Coahuila", value: "CL"},
    {label: "Colima", value: "CM"},
    {label: "Durango", value: "DG"},
    {label: "Guanajuato", value: "GT"},
    {label: "Guerrero", value: "GR"},
    {label: "Hidalgo", value: "HG"},
    {label: "Jalisco", value: "JC"},
    {label: "México", value: "MC"},
    {label: "Michoacán", value: "MN"},
    {label: "Morelos", value: "MS"},
    {label: "Nayarit", value: "NT"},
    {label: "Nuevo León", value: "NL"},
    {label: "Oaxaca", value: "OC"},
    {label: "Puebla", value: "PL"},
    {label: "Querétaro", value: "QO"},
    {label: "Quintana Roo", value: "QR"},
    {label: "San Luis Potosí", value: "SP"},
    {label: "Sinaloa", value: "SL"},
    {label: "Sonora", value: "SR"},
    {label: "Tabasco", value: "TC"},
    {label: "Tamaulipas", value: "TS"},
    {label: "Tlaxcala", value: "TL"},
    {label: "Veracruz", value: "VZ"},
    {label: "Yucatán", value: "YN"},
    {label: "Zacatecas", value: "ZS"},
  ];

  const countByNested = (data: any, property: string) => {
    const group = _.groupBy(data, property);
    return _.mapValues(group, (datum) => datum.length);
  };

  const countCategories = (categories: any) => {
    const countByValue = countByNested(props.analyticsEvents, 'mxStateCode');

    return _.map(categories, (category: any) => countByValue[category.value]);

  };

  const data = {
    labels: _.map(categoriesData, 'label'),
    datasets: [
      {
        label: props.title || 'Estado',
        backgroundColor: _.map(categoriesData, 'color'),
        data: countCategories(categoriesData)
      }
    ]
  };

  const options = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem:any) => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
        title: () => null,
      }
    },
  };


  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{props.title || 'Estado'}</IonCardTitle>
      </IonCardHeader>
      <Bar options={options} data={data}/>
    </IonCard>
  )
};
export default MxStateCodeChart;
