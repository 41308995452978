import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import UserInfo from './shared/services/UserInfo';
import 'moment/locale/es';
// import { defineCustomElements } from 'onboarding-form-stencil/dist/loader';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL + '/' + process.env.REACT_APP_API +'/';

UserInfo.init().finally(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// defineCustomElements(window);
