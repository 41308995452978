import React, {useState, useContext, useEffect} from 'react';
import {IonContent,} from "@ionic/react";
import styles from './Register.module.css';
import classes from '../../shared/utils/classes';
import {AppUserApi} from "../../shared/services/AppUserService";
import {OnboardingForm} from "../../shared/components/onboarding-form";
import SharedContext from "../../shared/context/SharedContext";
import {Questionnaire} from "../../shared/interfaces/loopback";
import {QuestionnaireApi} from "../../shared/services/QuestionnaireService";
import ToastService from "../../shared/services/ToastService";

const Register = (props: any) => {
  const sharedContext = useContext(SharedContext);
  const [registrationForm, setRegistrationForm] = useState<Questionnaire | null>(null);

  useEffect(() => {
    QuestionnaireApi.findOne({where:{name:'registration'}})
    .then((questionnaire: Questionnaire) => {
      setRegistrationForm(questionnaire);
    })
  }, []);

  useEffect(() => {
    sharedContext.updateHeaderTitle(`Registro`);
  }, [sharedContext]);

  const handleFormFinished = ({detail: {values: data}}:any) => {
    console.log('form finished', data);
    AppUserApi.post(data)
      .then(appUser => {
        console.log('new appUser:', appUser);
        ToastService({message: `Tu usuario ha sido creado`});
        AppUserApi.login(data.email, data.password)
          .then(authInfo => {
            props.history.push('/private/profile/edit');
            ToastService({message: `Bienvenido`});
          })
      })
      .catch(err => {
        if (err.statusCode === 401) ToastService({
          color: 'error',
          message: `Email o contraseña no válidos`,
        });
        if (err.statusCode === 404) ToastService({color: 'error', message: `Usuario no registrado`});
      })
  };

  return (
    <IonContent style={{height: '100%'}} class={classes('full-height', styles['register-content'])}>
      {registrationForm && <OnboardingForm data={registrationForm} onFormHasFinished={handleFormFinished}/>}
    </IonContent>
  );
};
export default Register;
