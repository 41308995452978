// imports
import React, {useContext, useEffect, useRef} from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid, IonIcon,
  IonRow
} from "@ionic/react";
import styles from './Info.module.css';
import classes from '../../shared/utils/classes';
import {withAuthInfo} from '../../shared/services/UserInfo';
import SharedContext from "../../shared/context/SharedContext";
import Slider from 'react-slick';




const Login = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const refIsMounted = useRef(true);

  useEffect(() => {
    sharedContext.updateHeaderTitle(`Home`);
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, [sharedContext]);




  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  // const authInfo = UserInfo.getAuthInfo();

  // console.log('authInfo', authInfo);

  return (
    <IonContent style={{height: '100%'}} class={classes('full-height', styles['login-content'])}>

      <div className={styles['home-wrapper']}>
        <Slider {...sliderSettings} className={styles['slider']}>
          <div>
            <div className={styles['slide-1']}>
            </div>
          </div>
          <div>
            <div className={styles['slide-2']}>
            </div>
          </div>
          <div>
            <div className={styles['slide-3']}>
            </div>
          </div>
          <div>
            <div className={styles['slide-4']}>
            </div>
          </div>
          <div>
            <div className={styles['slide-5']}>
            </div>
          </div>
          <div>
            <div className={styles['slide-6']}>
            </div>
          </div>
        </Slider>
        <div className={styles['main-content-wrapper']}>
          <div className={styles['blue-gradient']} />
          <IonGrid>
            {/*<IonRow class="ion-justify-content-center">*/}
            {/*  <IonCol size="3" ></IonCol>*/}
            {/*  <IonCol size="6" >*/}
            {/*    <a href={'http://semtech.mty.itesm.mx/covid19mx'} target={'_blank'}>*/}
            {/*      <img src={'/assets/imgs/covid-white.png'} />*/}
            {/*    </a>*/}
            {/*  </IonCol>*/}
            {/*  <IonCol size="3" ></IonCol>*/}
            {/*</IonRow>*/}
            <IonRow class="ion-justify-content-center">

              <IonCol size="6" size-xs class={styles['column-1'] + ' ' + styles['column']}>
                <div className={styles['column-content']}>
                  <h3>¿Qué es LinkMe?</h3>
                  <p>
                    ¿Necesitas tecnologías o servicios tecnológicos 4.0 para tu organización? ¿Cuentas con tecnologías u ofreces servicios tecnológicos 4.0 para ofrecer a la sociedad? ¿Tienes una startup en este sector y necesitas financiamiento o alianzas estratégicas) ¿Apoyas el ecosistema de innovación 4.0 mediante servicios especializados de consultoría, vinculación, financiamiento, incubación o aceleración? Entonces LINKME es el lugar donde debes estar.
                  </p>
                  <p>
                    <b>LINKME es la plataforma donde encontrarás a los principales actores en México relacionados con industria 4.0 para sectores específicos.
                    </b>
                  </p>
                  <p>
                    Si quieres conocer la información en la plataforma y vincularte con las organizaciones presentes regístrate y conéctate a esta comunidad tecnológica especializada.
                  </p>
                  <IonButton class={styles['link-button']}
                    onClick={() => {if (props.authInfo.currentUserId) {props.history.push('/public/discover')} else {props.history.push('/public/login')}}}
                    slot="end" color="secondary">Quiero hacer Link</IonButton>
                </div>
              </IonCol>

              <IonCol size="6" size-xs class={styles['column-2'] + ' ' + styles['column']}>
                <div className={styles['column-content']}>
                  <h3>¿Qué puedo hacer?</h3>
                  <div className={styles['icon-row']}>
                    <div className={styles['home-icon'] + ' ' + styles['home-icon-1']} />
                    <div className={styles['icon-content']}>
                      <h4>Ofertar</h4>
                      <p>
                        Da a conocer tus desarrollos tecnológicos o servicios especializados que cubran las necesidades del mercado en la industria 4.0.
                        Promueve tu startup en la comunidad para buscar financiamiento o para generar alianzas estratégicas.
                      </p>
                    </div>
                  </div>
                  <div className={styles['icon-row']}>
                    <div className={styles['home-icon'] + ' ' + styles['home-icon-2']}/>
                    <div className={styles['icon-content']}>
                      <h4>Demandar</h4>
                      <p>
                        Da a conocer tus necesidades tecnológicas que puedan ser atendidas con servicios y desarrollos tecnológicos de la Industria 4.0
                      </p>
                    </div>
                  </div>
                  <div className={styles['icon-row']}>
                    <div className={styles['home-icon'] + ' ' + styles['home-icon-3']}/>
                    <div className={styles['icon-content']}>
                      <h4>Apoyar</h4>
                      <p>
                        Facilita a los actores de la comunidad (Demandantes y Solicitantes) la transición de tecnologías o servicios en la industria 4.0, dando apoyo en transferencia tecnológica, consultoría, inversión, incubación, aceleración, entre otros.
                      </p>
                    </div>
                  </div>
                </div>
              </IonCol>

            </IonRow>
          </IonGrid>
        </div>
        <div  className={styles['footer']}>
          <IonGrid>
            <IonRow class="ion-justify-content-center">
              <IonCol class={styles['column-content']}>
                <img src={'/assets/imgs/logo-white.png'} alt={'redOTT'} />
              </IonCol>
              <IonCol class={styles['column-content']}>
                <p className={styles['footer-line']}>
                  <a target={'_blank'} href={'mailto:linkme@redott.mx'}><IonIcon name={'mail'} /></a>&nbsp;
                  <a target={'_blank'} href={'https://redott.mx/'}><IonIcon name={'globe'} /></a>&nbsp;
                  <a target={'_blank'} href={'https://linkedin.com/company/linkme-mx'}><IonIcon name={'logo-linkedin'} /></a>&nbsp;
                  <a target={'_blank'} href={'https://instagram.com/linkme.mx'}><IonIcon name={'logo-instagram'} /></a>&nbsp;
                  <a target={'_blank'} href={'https://twitter.com/Linkme_mx'}><IonIcon name={'logo-twitter'} /></a>&nbsp;
                  <a target={'_blank'} href={'https://facebook.com/linkme.mx'}><IonIcon name={'logo-facebook'} /></a>&nbsp;
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>




    </IonContent>
  );
});
export default Login;
