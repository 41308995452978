import moment from "moment";

const dateComparator = (filterLocalDateAtMidnight:Date, cellValue:string) => {
  let filterDate = moment(filterLocalDateAtMidnight);
  let cellDate = moment(cellValue).startOf('day');
  if (cellDate.isBefore(filterDate)) {
    return -1;
  } else if (cellDate.isAfter(filterDate)) {
    return 1;
  } else {
    return 0;
  }
};

const projectNamesByType:any = {
  'offer': 'Oferta',
  'need': 'Necesidad',
  'support': 'Apoyo'
};

const columnTypes = {
  shortDate: {
    filter: "agDateColumnFilter",
    sortable: true,
    cellRenderer: (params:any) => params.value? moment(params.value).format('L'): '',
    filterParams: {
      comparator: dateComparator
    }
  },
  projectType: {
    sortable: true,
    filter: 'agTextColumnFilter',
    cellRenderer: (params:any) => {
      return projectNamesByType[params.value]
    }
  },
  appUser: {
    sortable: true, filter: 'agTextColumnFilter', cellRenderer: (params:any) => params.value.firstName + ' ' + params.value.lastName
  }
};

export default columnTypes;
