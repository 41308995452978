import React from "react";
import {Route, Switch} from "react-router";
import New from "./new/New";
// import List from "./list/List";
import Table from "./Table/Table";
import View from "./view/View";

const Admin = (props: any) => {
  return (
    <>
      <Switch>
        <Route path={props.match.path + "/new"} component={New}/>
        <Route path={props.match.path + "/edit/:id"} component={New}/>
        <Route path={props.match.path + "/list"} component={Table}/>
        <Route path={props.match.path + "/view/:id"} component={View}/>
      </Switch>
    </>

  )
}
export default Admin;
