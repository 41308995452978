import React, {useEffect, useState} from "react";
import {IonCol, IonGrid, IonRow} from "@ionic/react";
import AreasOfInterestChart from "../../../shared/components/analytics/AreasOfInterestChart/AreasOfInterestChart";
import {AnalyticsEventApi} from "../../../shared/services/AnalyticsEventService";
import _ from "lodash";
import {AnalyticsEvent} from "../../../shared/interfaces/loopback";
import SectorsOfInterestChart from "../../../shared/components/analytics/SectorsOfInterestChart/SectorsOfInterestChart";
import ProjectTypesChart from "../../../shared/components/analytics/ProjectTypesChart/ProjectTypesChart";
import AccountTypeChart from "../../../shared/components/analytics/AccountTypeChart/AccountTypeChart";
import TotalsChart from "../../../shared/components/analytics/TotalsChart/TotalsChart";
import MxStateCodeChart from "../../../shared/components/analytics/MxStateCodeChart/MxStateCodeChart";
import CommonFilters from "../../../shared/components/analytics/CommonFilters/CommonFilters";
import {Excel} from "../../../shared/services/Excel";

const Friendships = (props: any) => {
  const [analyticsEvents, setAnalyticsEvents] = useState<AnalyticsEvent[]>([]);
  useEffect(() => {
    search();
  }, []);

  const search = (where?:any, download?:boolean) => {
    const filter = {
      where: {
        mock: false,
        eventType: {
          inq: [
            'friendshipCreated',
            'friendshipRequestCreated',
          ]
        }
      }
    };
    if(where) {
      Object.assign(filter.where, where);
    }
    AnalyticsEventApi.find(filter).then((events) => {
      setAnalyticsEvents(events);
      if(download) {
        Excel.fromArray(AnalyticsEventApi.flatten(events), {title: 'Vinculaciones'});
      }
    });
  };

  const totalsColSize = '6';
  const chartColSize = '6';

  return (

    <IonGrid>
      <IonRow>
        <IonCol>
          <CommonFilters onSubmit={search}/>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd={totalsColSize}>
          <TotalsChart title={'Solicitudes totales'} color={'#00C7E6'}
                       analyticsEvents={_.filter(analyticsEvents, {eventType: 'friendshipRequestCreated'})}/>
        </IonCol>
        <IonCol sizeMd={totalsColSize}>
          <TotalsChart title={'Vinculaciones totales'} color={'#FFC000'}
                       analyticsEvents={_.filter(analyticsEvents, {eventType: 'friendshipCreated'})}/>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd={chartColSize}>
          <AreasOfInterestChart analyticsEvents={analyticsEvents}/>
        </IonCol>
        <IonCol sizeMd={chartColSize}>
          <SectorsOfInterestChart analyticsEvents={analyticsEvents}/>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd={chartColSize}>
          <ProjectTypesChart analyticsEvents={analyticsEvents}/>
        </IonCol>
        <IonCol sizeMd={chartColSize}>
          <AccountTypeChart analyticsEvents={analyticsEvents}/>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <MxStateCodeChart analyticsEvents={analyticsEvents}/>
        </IonCol>
      </IonRow>
    </IonGrid>


  );
};
export default Friendships;



