import React from "react";
import {
IonIcon,

} from "@ionic/react";
import styles from "./GridList.module.css";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';


const GridList = (props: any) =>{
  /*
  * Required:
  * list: <any[]>: objects to display
  * renderListElement(element, index)<tsx>: Function to render list Element
  *
  * Optional:
  * listTitle:<string>
  * hideAdd:<boolean>
  * linkAdd:<string>  link to navigate once clicked on add icon
  * */
  console.log('list', props.list);
  return (
    <>
      <section className={styles['list']}>
        <h4>
          <span className={styles['list-title']}>{props.listTitle}</span>
          {!props.hideAdd && props.linkAdd && <Link to={props.linkAdd} className={styles['add-button']}><IonIcon name="add-circle"/> Agregar nueva</Link>}
        </h4>
        {props.list &&
          <div className={styles['cards-container']}>
            {
              props.list.map(function (element: any, index: number){
                return props.renderListElement(element, index)
              })
            }
          </div>
        }
        {(!props.list || !props.list.length) && <span className='section-subtitle'>{props.noContentMessage}</span>}
      </section>
    </>
  );
};
export default GridList;

GridList.propTypes = {
  listTitle:PropTypes.string,
  list:PropTypes.array.isRequired,
  renderListElement:PropTypes.func.isRequired,
  hideAdd:PropTypes.bool,
  linkAdd:PropTypes.string
};
