import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import {AppUserApi} from "../../../shared/services/AppUserService";
import SharedContext from "../../../shared/context/SharedContext";
import {AgGridReact} from "ag-grid-react";
import {AppUser, FriendRequest, Role} from "../../../shared/interfaces/loopback";
import AlertService from "../../../shared/services/AlertService";
import ToastService from "../../../shared/services/ToastService";
import columnTypes from '../../../shared/utils/columnTypes';
import {FriendRequestApi} from "../../../shared/services/FriendRequestService";

const ActionsCell = (props:any) => {

  return <>
    {/*<IonButton size="small" href={'/private/profile/view/' + props.value}>*/}
    {/*  <IonIcon name={'eye'}/>*/}
    {/*</IonButton>*/}
    {/*<IonButton size="small" href={'/private/profile/edit/' + props.value}>*/}
    {/*  <IonIcon name={'create'}/>*/}
    {/*</IonButton>*/}
    {/*<IonButton color={'danger'} size="small" onClick={() => confirmDeleteAppUser(props.data)}>*/}
    {/*  <IonIcon name={'trash'}/>*/}
    {/*</IonButton>*/}
  </>;
};


const Table = (props: any) => {
  const sharedContext = useContext(SharedContext);
  // const [appUsers, setAppUsers] = useState<AppUser[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  const [friendRequests, setFriendRequests] = useState<FriendRequest[]>([]);

  useEffect(() => {
    sharedContext.updateHeaderTitle('Usuarios');
  }, [sharedContext]);

  useEffect(() => {
    FriendRequestApi.find({include: ['requester', 'appUser']})
      .then((friendRequests:any) => {
        // setAppUsers([])
        setFriendRequests(friendRequests);
        console.log('friendRequests', friendRequests);
      });
  }, []);

  const columnDefs:any = [
    {
      headerName: 'Nombre creador', field: 'requester.firstName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Apellidos creador', field: 'requester.lastName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Email creador', field: 'requester.email', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Nombre invitado', field: 'appUser.firstName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Apellidos invitado', field: 'appUser.lastName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Email invitado', field: 'appUser.email', sortable: true, filter: 'agTextColumnFilter'
    },
    // {
    //   headerName: 'Fecha de creación', field: 'createdAt', sortable: true, filter: 'agDateColumnFilter',
    //   cellRenderer: (params:any) => moment(params.value).format('L')
    // },
    {
      headerName: 'Fecha de creación', field: 'createdAt', type: 'shortDate'
    },
    {
      headerName: '',
      field: 'id',
      cellRendererFramework: ActionsCell
    }
  ];

  const gridReady = (data:any) => {
    console.log(data);
    setGridApi(data.api);
  }

  const exportCsv = () => {
    gridApi.exportDataAsCsv({
      processCellCallback: (params:any) => {
        if(params.column.colId === 'roles') {
          console.log(params);
          return params.value
            .filter((role:Role) => role.name !== 'generalUser')
            .map((role:Role) => role.friendlyName)
            .join(', ') || 'Usuario general'
        } else {
          return params.value
        }

      }
    })
  }

  return (
    <div className="full-height ag-theme-material" style={{width: '100%', height: '95%'}}>
      <h1 className={'section-title'}>
        Solicitudes de vinculación
        <IonButton style={{float: 'right'}} onClick={() => exportCsv()} size="small">Descargar CSV</IonButton>
      </h1>
      <AgGridReact
        columnTypes={columnTypes}
        floatingFilter={true}
        suppressCellSelection={true}
        rowSelection="true"
        onGridReady={gridReady}
        columnDefs={columnDefs}
        rowData={friendRequests}>
      </AgGridReact>
    </div>
  );
};
export default Table;



