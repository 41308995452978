import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid, IonInput,
  IonRow
} from "@ionic/react";
import styles from './CommonFilters.module.css';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import React, {useEffect, useState} from "react";

registerLocale('es', es);

const CommonFilters = (props: any) => {

  const [startDate, setStartDate] = useState<Date|null>();
  const [endDate, setEndDate] = useState<Date|null>();

  const buildWhere = () => {
    const where:any = {};
    if((!startDate && endDate) || (startDate && !endDate)) {
      where.createdAt = {};

      if(startDate) {
        where.createdAt.gt = startDate;
      }

      if(endDate) {
        where.createdAt.lt = endDate;
      }
    } else if(startDate && endDate) {
      where.and = [
        {createdAt: {gt: startDate}},
        {createdAt: {lt: endDate}},
      ]
    } else {
      return;
    }

    return where;
  };

  useEffect(() => {
    if(props.onChange) {
      props.onChange(buildWhere());
    }
  }, [startDate, endDate]);

  const submit = (download?:boolean) => {
    if(props.onSubmit) {
      props.onSubmit(buildWhere(), download);
    }
  };

  // const download = () => {
  //   if(props.onDownload) {
  //     props.onDownload(buildWhere());
  //   }
  // };


  return (
    <IonCard style={{overflow: 'visible'}}>
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol class={'date-picker-col'}>
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                isClearable
                todayButton="Hoy"
                locale="es"
                placeholderText={'Fecha inicial'}
              />
            </IonCol>
            <IonCol class={'date-picker-col'}>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                isClearable
                todayButton="Hoy"
                locale="es"
                placeholderText={'Fecha final'}
              />
            </IonCol>
            {props.onSubmit && <IonCol size={'auto'}>
              <IonButton onClick={() => submit()} color="primary" size="small">Buscar</IonButton>
              <IonButton onClick={() => submit(true)} color="primary" size="small">Exportar</IonButton>
            </IonCol>}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  )
};
export default CommonFilters;
