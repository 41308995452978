import React, {useEffect, useState} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import {
  IonApp, IonContent,
  IonPage
} from "@ionic/react";
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';
import './theme.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import Private from './private/Private'
import Menu from "./shared/components/menu/Menu";
import Public from "./public/Public";
import {isMobile} from 'react-device-detect';

import {withAuthInfo} from './shared/services/UserInfo';

//context
import SharedContext from './shared/context/SharedContext';
import Header from "./shared/components/header/Header";
// import AuthService from "./shared/services/AuthService";
import ScrollingProjectsBar from "./shared/components/ScrollingProjectsBar/ScrollingProjectsBar";
import {AppUserApi} from "./shared/services/AppUserService";
// import FileUploader from "./shared/components/FileUploader/FileUploader";

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      [elemName: string]: any;
    }
  }
}

const App: React.FC = (props:any) => {
  const [headerTitle, setHeaderTitle] = useState('Home');
  const [activeBreakpoint, setActiveBreakpoint] = useState('xs');
  const [isMobilePlatform, setIsMobilePlatform] = useState(false);

  // const [showBar, setShowBar] = useState(false);
  // const initialState: AuthInfo = {currentUser: null, accessToken: null};
  // const [authInfo, setAuthInfo] = useState(initialState);

  // AuthService.validate();

  // const isAuth = AuthService.isAuthenticated();

  useEffect(() => {
    AppUserApi.trackPageView(props.authInfo.isAuth? props.authInfo.currentUserId: undefined);
  }, [props.authInfo.isAuth]);

  const updateDimensions = () => {
    let latestMatch = '';
    if (window.matchMedia("(min-width: 0px)").matches) {
      latestMatch = "xs";
    }
    if (window.matchMedia("(min-width: 576px)").matches) {
      latestMatch = "sm";
    }
    if (window.matchMedia("(min-width: 768px)").matches) {
      latestMatch = "md";
    }
    if (window.matchMedia("(min-width: 992px)").matches) {
      latestMatch = "lg";
    }
    if (window.matchMedia("(min-width: 1200px)").matches) {
      latestMatch = "xl";
    }
    setActiveBreakpoint(latestMatch);
  };
  useEffect(() => {
   setIsMobilePlatform(isMobile);
  }, []);

  // useEffect(() => {
  //
  //   console.log('check bar');
  //   setShowBar(props.authInfo.isAuth && /*!props.authInfo.isDataConsultant &&*/ window.location.pathname.indexOf('edit') < 0 && window.location.pathname.indexOf('analytics') < 0 && window.location.pathname.indexOf('private/admin') < 0)
  // }, [props]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    }
  });
  return (<>
      <BrowserRouter>
        <SharedContext.Provider
          value={{
            activeBreakpoint, updateBreakpoint: (breakpoint: string) => setActiveBreakpoint(breakpoint),
            headerTitle, updateHeaderTitle: (newTitle: string) => setHeaderTitle(newTitle),
            isMobile: isMobilePlatform, updateIsMobile: (isMobile: boolean) => setIsMobilePlatform(isMobile),
          }}>
          <div id="app">
            <IonApp>
              <Menu/>
              <IonPage id="main">
                <Header/>
                <IonContent>
                  <Switch>
                    {props.authInfo.isAuth && <Route path="/private" component={Private}/>}
                    <Route path="/public" component={Public}/>
                    <Redirect from="/" to="/public/info"/>
                  </Switch>
                  <ScrollingProjectsBar />
                </IonContent>
              </IonPage>
            </IonApp>
          </div>
        </SharedContext.Provider>
      </BrowserRouter>
      <ion-alert-controller />
      <ion-toast-controller />
      <ion-popover-controller />
    </>
  );
}

export default withAuthInfo(App);
