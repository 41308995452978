import axios from 'axios';


export class ContainerApi {
  static RESOURCE_URL = '/Containers/';

  static upload(data: any, config = {}): Promise<any> {
      return axios.post(`${ContainerApi.RESOURCE_URL}${process.env.REACT_APP_CONTAINER}/upload`, data, config)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

}


