import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonCard, IonCardContent, IonFab, IonFabButton, IonIcon} from "@ionic/react";

import styles from "./View.module.css";
import {FriendRequest, Friendship, Project} from "../../../shared/interfaces/loopback";
import {withAuthInfo} from "../../../shared/services/UserInfo";
import {ProjectApi} from "../../../shared/services/ProjectService";
import {FavoriteProjectApi} from "../../../shared/services/FavoriteProject";
import SharedContext from "../../../shared/context/SharedContext";
import _ from 'lodash';
import ToastService from "../../../shared/services/ToastService";
import {Link} from "react-router-dom";
import {UploadedFileApi} from "../../../shared/services/UploadedFileService";
import {HelpRequestApi} from "../../../shared/services/HelpRequestService"
import AlertService from "../../../shared/services/AlertService";
import {FriendshipApi} from "../../../shared/services/FriendshipService";
import {FriendRequestApi} from "../../../shared/services/FriendRequestService";
// import {FriendRequestApi} from "../../../shared/services/FriendRequestService";


const View =  withAuthInfo((props: any) => {

  const sharedContext = useContext(SharedContext);
  const [project, setProject] = useState<Project | any>({data: {}, appUser:{profileData:{}}});
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [projectTypeData, setProjectTypeData] = useState<any>({});
  const [projectIconStyle, setProjectIconStyle] = useState<any>({});
  const [projectTypeStyle, setProjectTypeStyle] = useState<any>({});
  const [friendship, setFriendship] = useState<Friendship | any>();
  const [isFriend, setIsFriend] = useState<boolean>(false);
  const [friendRequest, setFriendRequest] = useState<FriendRequest | any>();

  const [isFavorite, setIsFavorite] = useState<boolean>(false);



  const renderAreasOfInterest = (project:any) => {
    if(project.data && project.friendlyData && project.friendlyData.areasOfInterest && project.friendlyData.areasOfInterest.es.value) {
      return project.friendlyData.areasOfInterest.es.value.map((areaOfInterest:string, index:number) =>
        <div key={index} className={styles['project-area']} style={{backgroundColor: projectTypeData.color}}>{areaOfInterest}</div>
      )
    }
  };

  const formatDatumValue = (datum:any) => {
    const lang = 'es';
    if(datum.type === 'number') {
      return '$ ' + (datum[lang].value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else if(datum[lang].value.join) {
      return datum[lang].value.join(', ');
    } else {
      return datum[lang].value
    }
  };

  const filterFields:any = {
    offer: {
      hidden: [
        'startupGoal',
        'otherStartupGoal',
      ],
      friends: [
        'videoUrl',
        'investmentType',
        'otherInvestmentType',
        'technologyReadinessLevel3',
        'targetInvestment',
        'generalComments',
      ]
    },
    need: {
      hidden: [],
      friends: [
        'expectedDate',
        'selectionCriteria',
        'sector',
        'areasOfInterest',
        'otherAreasOfInterest',
      ]
    },
    support: {
      hidden: [],
      friends: [
        'minInvestment',
        'maxInvestment',
        'marketInfo',
        'technicalInfo',
        'financialInfo',
        'legalInfo',
        'personnelSkills',
        'generalInfo',
        'otherInfo',
        'generalComments',
      ]
    }
  };

  const renderProjectData = (data:any, type:any) => {

    if(!data || !type) {
      return '';
    }

    const alwaysOmit = [
      'areasOfInterest',
      'technologyReadinessLevel',
      'technologyReadinessLevel1',
      'technologyReadinessLevel2',
      'technologyReadinessLevel3',
      'technologyReadinessLevel4',
    ];

    let filteredData = _.omit(data, alwaysOmit);

    // console.log('type', type, data);


    if(!props.authInfo.isAdmin) {
      filteredData = _.omit(filteredData, filterFields[type].hidden);
    }

    if(!props.authInfo.isAdmin && !isFriend) {
      filteredData = _.omit(filteredData, filterFields[type].friends);
    }


    return _.map(filteredData, (datum: any, index: number) =>
      datum.es.value && <div key={index} className={styles['project-datum']}>
        <div className={styles['project-datum-label']}>{datum.es.label}</div>
        <div className={styles['project-datum-value']}>{formatDatumValue(datum)}</div>
      </div>
    );
  };

  const toggleFavorite = (project:any) => {
    if(isFavorite && project.favoriteProjects && project.favoriteProjects.length) {
      FavoriteProjectApi.deleteById(project.favoriteProjects[0].id)
        .then(() => {
          project.favoriteProjects = [];
          setProject({...project});
          setIsFavorite(false);
          ToastService({message: `La ficha ha sido removida de favoritos`});
        });
    } else {
      FavoriteProjectApi.post({projectId: project.id, appUserId: props.authInfo.currentUser.id})
        .then((favoriteProject) => {
          project.favoriteProjects = [favoriteProject];
          setProject({...project});
          setIsFavorite(true);
          ToastService({message: `La ficha ha sido agragada a favoritos`});
        });
    }
  };

  const togglePromoted = (project:any) => {
    console.log('clicked togglePromoted');




    ProjectApi.patchById({isPromoted: !project.isPromoted}, project.id)
      .then(() => {
        project.isPromoted = !project.isPromoted;
        setProject({...project});
        ToastService({message: project.isPromoted?`La ficha ha sido marcada como destacada`: `La ficha ha sido removida de la lista destacada`});
      });
  };

  useEffect(() => {
    // setProjectTypeData(ProjectApi.getProjectTypeData(project.type));
    setProjectIconStyle({
      backgroundColor: projectTypeData.color,
      backgroundImage: `url(${projectTypeData.iconPath})`
    });
    setProjectTypeStyle({
      backgroundColor: projectTypeData.backgroundColor,
      borderColor: projectTypeData.color,
      color: projectTypeData.color
    });
  }, [projectTypeData]);

  useEffect(() => {
    const id = props.match.params.id;
    ProjectApi.findById(id, {include:['image', 'appUser', {relation: 'favoriteProjects', scope: {where: {appUserId: props.authInfo.currentUser.id}}}]}).then((project: Project) => {

      console.log('project', project);
      setProject(project);
      setProjectTypeData(ProjectApi.getProjectTypeData(project.type));

      setIsFavorite(!!(project.favoriteProjects && project.favoriteProjects.length));
      // setProjectIconStyle({
      //   backgroundColor: projectTypeData.color,
      //   backgroundImage: `url(${projectTypeData.iconPath})`
      // });
      // setProjectTypeStyle({
      //   backgroundColor: projectTypeData.backgroundColor,
      //   borderColor: projectTypeData.color,
      //   color: projectTypeData.color
      // });

      setIsOwner(props.authInfo.currentUser.id === project.appUserId);
      // console.log('project', project);
      sharedContext.updateHeaderTitle(`Proyecto: ${project.data && project.data.name}`);
      if (props.authInfo.currentUserId !== project.appUserId) { // is not current user
        FriendshipApi.find({
          where: {
            or: [
              {appUser1Id: props.authInfo.currentUserId, appUser2Id: project.appUserId},
              {appUser2Id: props.authInfo.currentUserId, appUser1Id: project.appUserId}
            ]
          }
        }).then((friendships:any) => {
          console.log(friendships);
          if (friendships.length) {
            setFriendship(friendships[0]);
            setIsFriend(true);
          }

        });

        FriendRequestApi.find({
          where: {or: [
              {requesterId: project.appUserId, appUserId: props.authInfo.currentUser.id},
              {requesterId: props.authInfo.currentUser.id, appUserId: project.appUserId}
            ]}
        }).then((friendRequests) => {
          if (friendRequests.length) setFriendRequest(friendRequests[0]);
        });

      }


    });



  }, [props.match.params.id, props.authInfo.currentUser.id, sharedContext]);


  useEffect(() => {
    if(props.match.params.id) {
      ProjectApi.projectViewed(props.match.params.id);
    }
  }, [props.match.params.id]);

  const getMax = (value:any) => {
    console.log('getMax', value);
    if(typeof value !== 'string') {
      return Math.max(...value);
    } else {
      return value;
    }
  };

  const promptCreateHelpRequest = () => {
    AlertService({
      header: 'Recibir asistencia',
      message: '¿Tienes alguna duda respecto a la vinculación?<br /><br /> Crea una solicitud y un asesor te contactará pronto',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'Crear',
          handler: () => {
            createHelpRequest();
          }
        }
      ]
    })
  };

  const createHelpRequest = () => {
    HelpRequestApi.upsert({
      requesterId: props.authInfo.currentUserId,
      appUserId: project.appUserId,
      projectId: project.id
    }).then((helpRequest) => {
      ToastService({message: 'La solicitud ha sido creada'});
    }).catch((error) => {
      console.log(error);
      if(error.friendlyMessage) {
        ToastService({message: error.friendlyMessage, color: 'warning'})
      }
    })
  };

  const renderTechnologyReadinessLevel = (data:any) => {
    if(!project || project.data || !project.data.tectechnologyReadinessLevel) {
      return <></>;
    }
    return <>
      <div className={styles['project-subtitle']}>Nivel de maduración de tecnología</div><br />

        {project.data.technologyReadinessLevel.map((level:any, i:any) =>
          <div key={i} className={styles['round-card-data']}>
            <img className={styles['trl-image']} src={`/assets/imgs/${level}.png`} />
            <span>{project.friendlyData.technologyReadinessLevel.es.value[i]}<br /></span>
          </div>

        )}
    </>
  };

  return (
    <div className="full-height" style={{padding: '15px'}}>

      <h2 className="section-title">{projectTypeData.name} <span className={styles['project-icon']} style={projectIconStyle} /></h2>

      <div className={styles['round-card']}>
        <img className={styles['profile-header-image']} src={UploadedFileApi.getImageUrl(project._image, project.type)} alt ="img"/>
        <h1 className={styles['project-name']} style={{color: projectTypeData.color}}>{project.data && project.data.name}</h1>
        <span className={styles['project-type']} style={projectTypeStyle}>{projectTypeData.name}</span>
        <div className={styles['project-subtitle']}>Descripción</div>
        <p className={styles['project-description']}>{project.data && project.data.description}</p>
        {renderAreasOfInterest(project)}
        <div className={styles['action-buttons']}>
          {(!isOwner ||  props.authInfo.isAdmin) && <div onClick={() => toggleFavorite(project)} className={styles['action-button'] + ' ' + styles['favorite'] + ' ' + (isFavorite? '': styles['inactive'])}>
            <IonIcon name={isFavorite? 'heart': 'heart-empty'}/> Favoritos
          </div>}
          {props.authInfo.isAdmin && <div onClick={() => togglePromoted(project)} className={styles['action-button'] + ' ' + styles['promoted'] + ' ' + (project.isPromoted? '': styles['inactive'])}>
            <IonIcon name={project.isPromoted? 'star': 'star-outline'}/> Destacado
          </div>}
          {(isOwner ||  props.authInfo.isAdmin) && <Link to={'/private/project/edit/' + project.id} className={styles['action-button']}>
            <IonIcon name={'create'}/> Editar
          </Link>}
        </div>
      </div>
      <br />
      <br />

      <div className={styles['project-subtitle']}>Información general</div><br />
      <div className={styles['round-card-data']}>
        {project && project.data && renderProjectData(project.friendlyData, project.data.type)}
      </div>
      <br />
      <br />

      {/*{project.data.technologyReadinessLevel && <>*/}
      {/*  <div className={styles['project-subtitle']}>Nivel de maduración de tecnología</div><br />*/}
      {/*  <div className={styles['round-card-data']}>*/}
      {/*    <img className={styles['trl-image']} src={`/assets/imgs/${getMax(project.data.technologyReadinessLevel)}.png`} />*/}
      {/*    <span>{project.friendlyData.technologyReadinessLevel.es.value.map((val:any, index:any) =><>{val}<br /></>)}</span>*/}
      {/*  </div>*/}
      {/*</>}*/}

      {project && project.data && <>
        {project.data.technologyReadinessLevel && renderTechnologyReadinessLevel(project.data.technologyReadinessLevel)}

        {project.data.technologyReadinessLevel1 && renderTechnologyReadinessLevel(project.data.technologyReadinessLevel1)}

        {project.data.technologyReadinessLevel2 && renderTechnologyReadinessLevel(project.data.technologyReadinessLevel2)}

        {project.data.technologyReadinessLevel3 && (isFriend || props.authInfo.isAdmin) && renderTechnologyReadinessLevel(project.data.technologyReadinessLevel3)}

        {project.data.technologyReadinessLevel4 && renderTechnologyReadinessLevel(project.data.technologyReadinessLevel4)}

        {_.get(project, 'appUser') && (isFriend  || (project.data.anonymous !== 'A')) && <section className={styles['details-section']}>
          <div className={styles['project-subtitle']}>Datos de contacto</div><br />
          <IonCard>
            <IonCardContent>
              <div className={styles['detail-item']}>
                <div>{ _.get(project, 'appUser.profileData.companyName') ? 'Empresa': 'Nombre'}</div>
                <div style={{flex:1}}>
                  {_.get(project, 'appUser.profileData.companyName') || (_.get(project, 'appUser.firstName') + ' ' + _.get(project, 'appUser.lastName'))}
                </div>
                <IonButton
                    slot="end"
                    color="primary"
                    onClick={() => props.history.push(`/private/profile/view/${project.appUserId}`)}> Ver perfil</IonButton>
              </div>
            </IonCardContent>
          </IonCard>
        </section>}
      </>}

      {!isFriend && !friendRequest && <>
        <div className={styles['friendship-request-suggestion']}>
          Para poder visualizar la información de contacto completa, envía una solicitud de vinculación
          <br />
          <br />
          <IonButton
            color="primary"
            onClick={() => props.history.push(`/private/profile/view/${project.appUserId}`)}>ENVIAR SOLICITUD DE VINCULACIÓN</IonButton>
        </div>


        <IonFabButton class={styles['help-button']} onClick={() => promptCreateHelpRequest()}>
          <IonIcon name="help-circle" />
        </IonFabButton>

      </>}

      {!isFriend && friendRequest && <>
        <div className={styles['friendship-request-suggestion']}>
          Tu solicitud de vinculación ha sido enviada
          <br />
          <br />
          <IonButton
            color="primary" disabled
            onClick={() => props.history.push(`/private/profile/view/${project.appUserId}`)}>ENVIAR SOLICITUD DE VINCULACIÓN</IonButton>
        </div>


        <IonFabButton class={styles['help-button']} onClick={() => promptCreateHelpRequest()}>
          <IonIcon name="help-circle" />
        </IonFabButton>

      </>}


    </div>
  )
});
export default View;
