import styles from "./styles.module.css";
import React, {useEffect, useRef, useState} from "react";
import {ProjectApi} from '../../services/ProjectService';
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {withAuthInfo} from "../../services/UserInfo";


const ScrollingProject = withRouter((props: any) => {

  if(!props.project || !props.project.type) {
    return null;
  }

  let projectTypeData = ProjectApi.getProjectTypeData(props.project.type);

  let projectIconStyle = {

    backgroundImage: `url(${projectTypeData.iconPath})`
  };

  let projectStyle = {
    backgroundColor: projectTypeData.color
  };

  return (
    <Link to={'/private/project/view/' + props.project.id}>
      <div className={styles['project-wrapper']} style={projectStyle}>
        <div className={styles['project-icon']} style={projectIconStyle} />
        <div className={styles['project-pill']} title={props.project.name || 'Sin título'}>
          {props.project.data.name}
        </div>
      </div>
    </Link>
  );
});

const ScrollingProjectsBar = (props: any) => {

  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    let showBar = props.authInfo.isAuth && !props.authInfo.isDataConsultant && window.location.pathname.indexOf('edit') < 0 && window.location.pathname.indexOf('analytics') < 0 && window.location.pathname.indexOf('private/admin') < 0;

    console.log('showBar', showBar);
    setShowBar(showBar)
  }, [props.location, props.authInfo.isAuth]);

  useEffect(() => {
    if(!showBar) return;
    ProjectApi
      .suggestedUserProjects(props.authInfo.currentUserId, undefined, 10, 10)
      .then((projects:any) => {
        console.log('suggestedProjects scroll', projects);
        setProjects(projects);
      });
  }, [props.authInfo.currentUserId, showBar]);

  const [projects, setProjects] = useState<any>([]);

  const scrollingContainer = useRef<any>(null);
  useEffect(() => {

    if(!showBar) return;

      let currentLeft = 0;
      const interval = setInterval(() => {
          if (scrollingContainer.current) {
            if (currentLeft <= -420) {
              setProjects((projects: any) => {
                currentLeft = 0;
                const tempProjects = [...projects];
                const firstProject = tempProjects.shift();
                setProjects([...tempProjects, firstProject]);
              });
            }
            scrollingContainer.current.style.left = (currentLeft--) + "px"
          }
      }, 50);
    //each project is 390px width
    return () => {
      clearInterval(interval);
    }
  }, [showBar]);



  return (
    <>{showBar}
      {showBar &&
      <section id="scrolling-projects-bar" className={styles['scrolling-projects-bar']} ref={scrollingContainer}>
        {projects && projects.length && projects.map((project: any, index:number) => <ScrollingProject key={index} project={project}/>)}
      </section>}
    </>
  )
};
export default withRouter(withAuthInfo(ScrollingProjectsBar));
