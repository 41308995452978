// imports
import React, {useState, useEffect, useContext} from 'react';
import {
  IonCheckbox,
  IonContent,
} from "@ionic/react";
// @ts-ignore
import qs from "qs";

// styles

import {FriendRequestApi} from "../../shared/services/FriendRequestService";
import {AppUser, FriendRequest, Project} from "../../shared/interfaces/loopback";
import UserInfo from "../../shared/services/UserInfo";
import {ProjectApi} from "../../shared/services/ProjectService";
import GridList from "../../shared/components/GridList/GridList";
import ProjectCard from "../../shared/components/ProjectCard/ProjectCard";
import SharedContext from "../../shared/context/SharedContext";

import styles from "./Trends.module.css";

const Discover = (props: any) => {
  const sharedContext = useContext(SharedContext);
  const [requesterProfiles, setRequesterProfiles] = useState([]);
  const [recomendedCards, setRecomendedCards] = useState<Project[] | any>([]);
  const discoveryQuery = qs.parse(window.location.search, {ignoreQueryPrefix: true}).q;

  const [needChecked, setNeedChecked] = useState<boolean>(true);
  const [offerChecked, setOfferChecked] = useState<boolean>(true);
  const [supportChecked, setSupportChecked] = useState<boolean>(true);


  const [checkedAreas, setCheckedAreas] = useState<any>({});

  //Get FriendRequests
  useEffect(() => {
    sharedContext.updateHeaderTitle(`Explorar`);

  }, [sharedContext]);


  const renderProjects = (element: any, index: number) => {
    return (
      <ProjectCard
        project={element}
        key={element.id}/>
    );
  };

  const areas = [
    {"label": "Big Data and Analytics", "value": "A"},
    {"label": "Internet de las cosas", "value": "B"},
    {"label": "Impresión 3D (Manufactura aditiva)", "value": "C"},
    {"label": "La nube (cloud computing)", "value": "D"},
    {"label": "Ciberseguridad", "value": "E"},
    {"label": "Robots autónomos / Automatización", "value": "F"},
    {"label": "Simulación", "value": "G"},
    {"label": "Sistemas integrados a nivel horizontal y/o vertical", "value": "H"},
    {"label": "Realidad aumentada", "value": "I"},
    {"label": "Inteligencia artificial", "value": "J"},
    {"label": "Blockchain", "value": "K"},
  ];

  areas.forEach((area) => {
    checkedAreas[area.value] = true;
  });

  // setCheckedAreas(checkedAreas);

  //Get Featured Profiles
  useEffect(() => {
    let orFilters: any[] = [];
    if (needChecked) orFilters.push({type: 'need'});
    if (offerChecked) orFilters.push({type: 'offer'});
    if (supportChecked) orFilters.push({type: 'support'});
    let filter: any = {};
    if (orFilters.length && orFilters.length < 3) {
      filter.where = {or: orFilters}
    }
    ProjectApi.find(filter).then(setRecomendedCards);
  }, [needChecked, offerChecked, supportChecked]);


  const setChecked = (value:string, state:boolean) => {
    checkedAreas[value] = state;
    setCheckedAreas(checkedAreas);
  };

  const renderAreaFilters = () => {
    return areas.map((area) =>
      <span>
        <IonCheckbox color="primary" checked={offerChecked} onIonChange={(e) => {
          setChecked(area.value, e.detail.checked)
        }}/>
        <label>{area.label}</label>
      </span>
    );
  };

  return (
    <IonContent padding class="full-height">
      <h1 class="section-title">Tendencias</h1>
      <p>
        ¡Bienvenido! En esta sección encontrarás documentos con información relevante sobre tendencias de interés en I4.0. Agradecemos a los distintos aliados estratégicos de LINKME sus aportaciones.
      </p>
      <p class="section-subtitle">Haz click sobre cada reporte para visualizar y descargar</p>
      <div className={styles['area-filters-container']}>
        <div className={styles['area-filters-title']}>
          <span>Industria 4.0</span>
        </div>
        {renderAreaFilters()}
      </div>





    </IonContent>
  );
};
export default Discover;
