import React from "react";
import {Route, Switch} from "react-router";
import Edit from "./Edit/Edit";
import View from "./View/View";

const Project = (props: any) => {
  return (
    <>
      <Switch>
        <Route path={props.match.path + "/new/:projectType"} component={Edit}/>
        <Route path={props.match.path + "/edit/:id"} component={Edit}/>
        <Route path={props.match.path + "/view/:id"} component={View}/>
      </Switch>
    </>
  )
};
export default Project;
