import React from "react";
import {IonCol, IonGrid, IonRow} from "@ionic/react";

const styles = {
  width: '100%',
  height: 'auto',
  maxHeight: '500px'
};

const ViewVideo = (props: any) => {

  console.log(props.match.params.path);
  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <video autoPlay style={styles} key={props.match.params.path} controls>
            <source src={`/assets/videos/${props.match.params.path}.mp4`} type="video/mp4"/>
            <source src={`/assets/videos/${props.match.params.path}.webm`} type="video/webm"/>
            Tu explorador no soporta videos
          </video>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
export default ViewVideo;



