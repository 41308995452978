import axios from 'axios';
import {LoopBackFilter, HelpRequest} from "../interfaces/loopback";


export class HelpRequestApi {
  static RESOURCE_URL = '/HelpRequests/';

  static upsert(data: HelpRequest): Promise<HelpRequest> {
    return axios.put(`${HelpRequestApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static find(filter: LoopBackFilter = {}): Promise<HelpRequest[]> {
    return axios.get(`${HelpRequestApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static findById(id: string, filter: LoopBackFilter = {}): Promise<HelpRequest> {
    return axios.get(`${HelpRequestApi.RESOURCE_URL}${id}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

}
