import {IonCard, IonIcon} from "@ionic/react";
import styles from "./ProjectCard.module.css";
import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {ProjectApi} from "../../services/ProjectService"
import {withAuthInfo} from "../../services/UserInfo";
import {UploadedFileApi} from "../../services/UploadedFileService";

const ProfileCard = (props: any) => {

  if(!props.project || !props.project.type) {
    return null;
  }

  let projectTypeData = ProjectApi.getProjectTypeData(props.project.type);

  let projectTypeStyle = {
    backgroundColor: projectTypeData.backgroundColor,
    borderColor: projectTypeData.color,
    color: projectTypeData.color
  };

  let projectIconStyle = {
    backgroundColor: projectTypeData.color,
    backgroundImage: `url(${projectTypeData.iconPath})`
  };

  let linkTo = '';

  if(props.authInfo.currentUserId) {
    linkTo = '/private/project/view/'+props.project.id;
  } else {
    linkTo = '/public/login';
  }



  return (
    <Link to={linkTo}>
      <IonCard class={styles['project-card']}>
        <img className={styles['project-image']} src={UploadedFileApi.getImageUrl(props.project._image, props.project.type)} alt={props.project.data.name}/>
        <div className={styles['overlay']}>
          <h2 lang="es" className={styles['project-title']}>{props.project.data.name || <i>Sin título</i>}</h2>

          {props.project.isPromoted && <div className={styles['promoted']}><IonIcon name={'star'} /></div>}
          <div className={styles['project-icon']} style={projectIconStyle} />
          <div className={styles['project-type']} style={projectTypeStyle}>
            {projectTypeData.name}
          </div>
          <p lang="es" className={styles['description']}>
            {props.project.data.description}
          </p>
        </div>
      </IonCard>
    </Link>
  )
};
export default withAuthInfo(withRouter(ProfileCard));
