import axios from 'axios';
import {Role} from "../interfaces/loopback";
import UserInfo from "./UserInfo";


export class RoleApi {
  static RESOURCE_URL = '/Roles/';

  static get(filter = {}): Promise<Role[]> {
    return axios.get(`${RoleApi.RESOURCE_URL}`,
      {
        params: {filter},
        headers: {...UserInfo.baseHeaders()}
      })
        .then(resp => {
          return resp.data;
        })
        .catch(err => Promise.reject(err.response.data.error))
  }

}


