import React, {useContext, useEffect, useRef, useState} from "react";
import {
  IonButton,
  IonCard, IonCardContent, IonCardHeader, IonCheckbox,
  IonCol, IonGrid, IonIcon, IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";

import styles from './New.module.css';

import isEmail from 'validator/lib/isEmail';
import {AppUserApi} from "../../../../shared/services/AppUserService";
import Toast from "../../../../shared/components/toast/Toast";
import {AppUser, Role} from "../../../../shared/interfaces/loopback";
import SharedContext from "../../../../shared/context/SharedContext";
import {RoleMappingApi} from "../../../../shared/services/RoleMappingService";
import {RoleApi} from "../../../../shared/services/RoleService";
import FileUploader from "../../../../shared/components/FileUploader/FileUploader";

const New = (props: any) => {
  const sharedContext = useContext(SharedContext);
  // variables
  const [emailError, setEmailError] = useState<boolean>(false);
  const [appUser, setAppUser] = useState<AppUser>({email: '', name: '', roles: [], profileData: {}});
  const [toastConfig, setToastConfig] = useState<any>({type: '', message: '', isOpen: false});
  const refIsMounted = useRef(true);
  const [roles, setRoles] = useState<Role[]>([]);

  // functions
  useEffect(() => {
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, []);
  useEffect(() => {
    sharedContext.updateHeaderTitle('Editar Usuario');
    const id = props.match.params.id;
    if (id) {
      AppUserApi.findById(id, {include: 'roles'})
      .then((user: AppUser) => {
        console.log(user);
        setAppUser(user);
      });
    }
    RoleApi.get({where: {name: {neq: 'generalUser'}}})
    .then((roles: Role[]) => {
      setRoles(roles);
    })
  }, [props.match.params.id, sharedContext]);
  const handleEmail = (e: any) => {
    const email = e.target.value;
    handleElementSetter('email', email);
    setEmailError(!isEmail(email));
  };
  const handleOnDidDismiss = () => {
    if (refIsMounted.current) {
      setToastConfig({type: '', message: '', isOpen: false})
    }
  };
  const handleElementSetter = (property: string, value: any, isProfileData?: boolean) => {
    const newAppUser = {...appUser};
    if(isProfileData){
      newAppUser['profileData'][property] = value;
    } else {
      newAppUser[property] = value;
    }

    setAppUser(newAppUser);
  }
  const handleSave = () => {
    if(appUser.password === '' || !appUser.password) delete appUser.password;
    const promise = appUser.id ? AppUserApi.patch(appUser): AppUserApi.upsert(appUser);
    promise
    .then(_ => {
      props.history.push({pathname: '/private/admin/user-manager/list'})
    })
  }
  const isFormInvalid = (): boolean => {
    const isPasswordInvalid = (!!appUser.id) ? false : (appUser.password === '' || !appUser.password);
    const isEmailInvalid = !!emailError;
    return isEmailInvalid || isPasswordInvalid;
  }
  const toggleRole = (event: any, role: any, index: number) => {
    const isChecked = event.detail.checked;
    const data = {
      principalType: "USER",
      principalId: appUser.id,
      roleId: role.id
    }
    let promise = (isChecked) ? RoleMappingApi.create(data) :
      AppUserApi.revokeRole(role.name, appUser.email);
    promise.then(resp => {
      if (!appUser.roles) appUser.roles = [];
      const newRoles = appUser.roles.slice(0);
      if (isChecked) newRoles.push(role);
      if (!isChecked) newRoles.splice(index, 1);
      appUser['roles'] = newRoles;
      setAppUser(appUser);
      setToastConfig({type: 'success', message: 'Rol actualizado', isOpen: true});

    });

  }


  const hasRole = (roleName: string) => {
    if (!appUser.roles || appUser.roles.length === 0) return false;
    return appUser.roles.findIndex(role => role.name === roleName) !== -1;
  }

  return (
    <>
      <IonGrid class="full-height">
        <IonRow class="full-height ion-justify-content-center">
          <IonCol
            size-xs="12"
            size-sm="12"
            size-md="12"
            size-lg="12"
            size-xl="12"
            class="ion-align-self-start">
            <form onSubmit={(e: any) => e.preventDefault()}>
              {appUser.id &&
              <IonCard>
                <IonCardHeader>
                  <h3>Avatar</h3>
                </IonCardHeader>
                <IonCardContent>
                  <FileUploader
                    modelApi={AppUserApi}
                    modelId = {appUser.id}
                    relationName = "avatar"
                  ></FileUploader>
                </IonCardContent>
              </IonCard>
              }
              <IonCard>
                <IonCardHeader>
                  <h3>Usuario</h3>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel
                      class="flex-1"
                      position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Nombre:</IonLabel>
                    <IonInput
                      color="primary"
                      value={appUser.firstName}
                      onIonChange={(e: any) => {
                        handleElementSetter('firstName', e.target.value)
                      }}
                      placeholder="Nombre"
                      name="name"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel
                      class="flex-1"
                      position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Apellidos:</IonLabel>
                    <IonInput
                      color="primary"
                      value={appUser.lastName}
                      onIonChange={(e: any) => {
                        handleElementSetter('lastName', e.target.value)
                      }}
                      placeholder="Nombre"
                      name="name"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel
                      class="flex-1"
                      position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Correo Electrónico:</IonLabel>
                    <IonInput
                      color={emailError ? "danger" : "primary"}
                      value={appUser.email}
                      onIonChange={(e: any) => {
                        handleEmail(e)
                      }}
                      placeholder="Email"
                      type="email"
                      name="email"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel
                      class="flex-1"
                      position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Contraseña:</IonLabel>
                    <IonInput
                      color="primary"
                      value={appUser.password}
                      onIonChange={(e: any) => {
                        handleElementSetter('password', e.target.value)
                      }}
                      placeholder="contraseña"
                      type="password"
                      name="name"
                    />
                  </IonItem>
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="podium"></IonIcon>*/}
                  {/*    Compañía:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['companyName'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('companyName', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Compañía"*/}
                  {/*    name="compañia"/>*/}
                  {/*</IonItem>*/}
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="compass"></IonIcon>*/}
                  {/*    Código de Estado:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['mxStateCode'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('mxStateCode', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Código de Estado"*/}
                  {/*    name="state"*/}
                  {/*  />*/}
                  {/*</IonItem>*/}
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="compass"></IonIcon>*/}
                  {/*    Código de Ciudad:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['mxCity'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('mxCity', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Código de Ciudad"*/}
                  {/*    name="state"*/}
                  {/*  />*/}
                  {/*</IonItem>*/}
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="briefcase"></IonIcon>*/}
                  {/*    Posición:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['companyPosition'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('companyPosition', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Posición en compañía"*/}
                  {/*    name="companyPosition"*/}
                  {/*  />*/}
                  {/*</IonItem>*/}
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="logo-buffer"></IonIcon>*/}
                  {/*    Área de empresa:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['companyArea'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('companyArea', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Área de empresa"*/}
                  {/*    name="companyArea"*/}
                  {/*  />*/}
                  {/*</IonItem>*/}
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="call"></IonIcon>*/}
                  {/*    Teléfono personal:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['personalPhone'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('personalPhone', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Teléfono personal"*/}
                  {/*    name="personalPhone"*/}
                  {/*  />*/}
                  {/*</IonItem>*/}
                  {/*<IonItem>*/}
                  {/*  <IonLabel*/}
                  {/*    class={"flex-1 " + styles['icon-container']}*/}
                  {/*    position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>*/}
                  {/*    <IonIcon class={styles['icon']} name="call"></IonIcon>*/}
                  {/*    Teléfono de compañía:</IonLabel>*/}
                  {/*  <IonInput*/}
                  {/*    color="primary"*/}
                  {/*    value={appUser.profileData['companyPhone'] || ''}*/}
                  {/*    onIonChange={(e: any) => {*/}
                  {/*      handleElementSetter('companyPhone', e.target.value, true)*/}
                  {/*    }}*/}
                  {/*    placeholder="Teléfono de compañía"*/}
                  {/*    name="companyPhone"*/}
                  {/*  />*/}
                  {/*</IonItem>*/}
                </IonCardContent>
                <IonItem>
                  <IonButton
                    slot="end"
                    onClick={handleSave}
                    disabled={isFormInvalid()}
                  >Guardar</IonButton>
                </IonItem>
              </IonCard>
              {/*<IonGrid>*/}
              {/*  <IonRow>*/}
              {/*    <IonCol>*/}
              {/*      <IonCard>*/}
              {/*        <IonCardContent>*/}
              {/*          FileUploader*/}
              {/*        </IonCardContent>*/}
              {/*      </IonCard>*/}
              {/*    </IonCol>*/}
              {/*  </IonRow>*/}
              {/*</IonGrid>*/}
              {appUser.id &&
                <>
                  <IonCard>
                    <IonCardHeader>
                      <h3>Roles</h3>
                    </IonCardHeader>
                    <IonCardContent>
                      {
                        roles.map((role, index) => {
                          return (
                            <IonItem key={role.id}>
                              <IonLabel>{role.name}</IonLabel>
                              <IonCheckbox slot="end"
                                           checked={hasRole(role.name)}
                                           onIonChange={($event) => {
                                             toggleRole($event, role, index)
                                           }}/>
                            </IonItem>)
                        })
                      }
                    </IonCardContent>
                  </IonCard>
                </>
              }
            </form>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Toast
        onDidDismiss={handleOnDidDismiss}
        isOpen={toastConfig.isOpen}
        message={toastConfig.message}
        type={toastConfig.type}/>
    </>
  );
};
export default New;
