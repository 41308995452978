import axios from 'axios';
import {AppUser, LoopBackFilter, Project} from "../interfaces/loopback";

const projectTypeData:any = {
  need: {
    name: 'Necesidad',
    color: '#91DDF2',
    backgroundColor: 'rgba(145,221,242,0.4)',
    iconPath: '/assets/imgs/ficha-demanda.svg',
    subtypes: []
  },
  offer: {
    name: 'Oferta',
    color: '#19CECB',
    backgroundColor: 'rgba(25,206,203,0.4)',
    iconPath: '/assets/imgs/ficha-oferta.svg',
    subtypes: []
  },
  support: {
    name: 'Apoyo',
    color: '#1AD9B2',
    backgroundColor: 'rgba(26,217,178,0.4)',
    iconPath: '/assets/imgs/ficha-apoyo.svg',
    subtypes: []
  }
};

export class ProjectApi {
  static RESOURCE_URL = '/Projects/';

  static getProjectTypeData(type: string, subtype?: string): any {
    if(!subtype) {
      return projectTypeData[type];
    }
  }

  static upsert(data: Project): Promise<Project[]> {
    return axios.put(`${ProjectApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static getRandom(count: number, loopbackFilter?: LoopBackFilter): Promise<Project[]> {
    return axios.get(`${ProjectApi.RESOURCE_URL}getRandom`, {params: {count, loopbackFilter}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static find(filter: LoopBackFilter = {}): Promise<Project[]> {
    return axios.get(`${ProjectApi.RESOURCE_URL}`, {params: {filter}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static findById(id: string, filter: LoopBackFilter = {}): Promise<Project> {
    return axios.get(`${ProjectApi.RESOURCE_URL}${id}`, {params: {filter}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static deleteById(id: string): Promise<Project> {
    return axios.delete(`${ProjectApi.RESOURCE_URL}${id}`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static patchById(data: any, id: string): Promise<Project> {
    return axios.patch(`${ProjectApi.RESOURCE_URL}${id}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static projectViewed(id: string): any {
    //http://localhost:3000/api/FavoriteProjects/5d6fe9175a332c001227f72c/createAnalyticsEvent

    return axios.post(`${ProjectApi.RESOURCE_URL}${id}/viewed`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static suggestedUserProjects(userId?: string, keyword?: string|any, limit?: number, min?: number): Promise<Project[]> {

    if(!userId) {
      userId = undefined;
    }

    return axios.post(`${ProjectApi.RESOURCE_URL}suggestedUserProjects`, {userId, keyword, limit, min})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

}
