import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";
import styles from "./SectorsOfInterestChart.module.css";
import React from "react";
import {Pie} from "react-chartjs-2";
import _ from "lodash";

const SectorsOfInterestChart = (props: any) => {

  if(!props.analyticsEvents) {
    return null;
  }

  const categoriesData = [
    {value: 'A', label: 'Textil', color: '#41bbc5'},
    {value: 'B', label: 'TIC´s', color: '#3dffc4'},
    {value: 'C', label: 'Moldes y Troqueles', color: '#68c030'}
  ];



  const groupByNested = (data:any, property:string) => {
    return _.groupBy(_.flatMap(data, (datum) => {return _.flatMap(datum[property], (propertyValue) => {return {...datum, [property]: propertyValue}})}), property);
  };

  const countByNested = (data:any, property:string) => {
    const group = groupByNested(data, property);
    return _.mapValues(group, (datum) => datum.length);
  };

  const countCategories = (categories:any) => {
    const countByValue = countByNested(props.analyticsEvents, 'sectorsOfInterest');

    return _.map(categories, (category:any) => countByValue[category.value]);

  };

  const data = {
    labels: _.map(categoriesData, 'label'),
    datasets: [
      {
        label: props.title || 'Sectores',
        backgroundColor: _.map(categoriesData, 'color'),
        data: countCategories(categoriesData)
      }
    ]
  };

  const options = {
    // legend: {
    //   display: false
    // },
    // tooltips: {
    //   callbacks: {
    //     label: (tooltipItem:any) => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
    //     title: () => null,
    //   }
    // },
  };


  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{props.title || 'Sectores'}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
       <Pie options={options} data={data}/>
      </IonCardContent>
    </IonCard>
  )
};
export default SectorsOfInterestChart;
