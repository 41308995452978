import React from "react";
import {Redirect, Route, Switch} from "react-router";
import Edit from "./edit/Edit";
import EditAppUser from "./EditAppUser/EditAppUser";
import View from "./view/View";
import UserInfo, {withAuthInfo} from "../../shared/services/UserInfo";

const Profile = withAuthInfo((props: any) => {
  const isAdmin = UserInfo.isAdmin();
  const profileCompleted = props.authInfo.currentUser.profileData.isComplete;
  return (
    <>
      <Switch>
        <Route path={props.match.path + "/edit/:id?"} component={Edit}/>
        <Route path={props.match.path + "/editAppUser/:id?"} component={EditAppUser}/>

        {(profileCompleted || isAdmin) && <>
          <Route path={props.match.path + "/view/:id?"} component={View}/>

        </>}
        {!profileCompleted &&
        <Redirect from="/" to="/private/profile/edit"/>
        }
      </Switch>
    </>

  )
});
export default Profile;
