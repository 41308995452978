// imports
import React, {useState, useEffect, useContext} from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
} from "@ionic/react";
// @ts-ignore
import qs from "qs";

// styles

import Profiles from "./Profiles/Profiles";
import {FriendRequestApi} from "../../shared/services/FriendRequestService";
import {AppUser, FriendRequest, Project} from "../../shared/interfaces/loopback";
import UserInfo, {withAuthInfo} from "../../shared/services/UserInfo";
import {ProjectApi} from "../../shared/services/ProjectService";
import GridList from "../../shared/components/GridList/GridList";
import ProjectCard from "../../shared/components/ProjectCard/ProjectCard";
import SharedContext from "../../shared/context/SharedContext";

import styles from "./Discover.module.css";
import _ from 'lodash';

const Discover = (props: any) => {

  let {isAdmin} = props.authInfo;

  let initialCheckedAreas:any = {};
  let initialCheckedRegions:any = {};

  const areas = [
    {"label": "Big Data and Analytics", "value": "A"},
    {"label": "Internet de las cosas", "value": "B"},
    {"label": "Impresión 3D (Manufactura aditiva)", "value": "C"},
    {"label": "La nube (cloud computing)", "value": "D"},
    {"label": "Ciberseguridad", "value": "E"},
    {"label": "Robots autónomos / Automatización", "value": "F"},
    {"label": "Simulación", "value": "G"},
    {"label": "Sistemas integrados a nivel horizontal y/o vertical", "value": "H"},
    {"label": "Realidad aumentada", "value": "I"},
    {"label": "Inteligencia artificial", "value": "J"},
    {"label": "Blockchain", "value": "K"},
    {"label": "Otra", "value": "L"},
  ];

  const regions = [
    {"label": "Noroeste", "values":     ["BC", "BS","CH", "SL", "SR"]},
    {"label": "Noreste", "values":      ["CL", "DG", "NL", "SP", "TS"]},
    {"label": "Centro", "values":       ["DF", "GR", "HG", "MS", "PL", "TL", "MC"]},
    {"label": "Occidente", "values":    ["AS", "CM", "GT", "JC", "MN", "NT", "QO", "ZS"]},
    {"label": "Sureste", "values":      ["CC", "CS", "OC", "QR", "TC", "VZ", "YN"]}
  ];

  const findRegion = (state:string) => {
    let foundRegion:string = '';
    regions.forEach(region => {
      if(region.values.includes(state)) {
        foundRegion = region.label;
      }
    });
    return foundRegion;
  };

  areas.forEach((area) => {
    initialCheckedAreas[area.value] = true;
  });

  regions.forEach(region => {
    initialCheckedRegions[region.label] = true;
  })

  const sharedContext = useContext(SharedContext);
  const [requesterProfiles, setRequesterProfiles] = useState([]);
  const [suggestedProjects, setSuggestedProjects] = useState<Project[] | any>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[] | any>([]);
  const discoveryQuery = qs.parse(window.location.search, {ignoreQueryPrefix: true}).q;

  const [needChecked, setNeedChecked] = useState<boolean>(true);
  const [offerChecked, setOfferChecked] = useState<boolean>(true);
  const [supportChecked, setSupportChecked] = useState<boolean>(true);


  const [checkedAreas, setCheckedAreas] = useState<any>(initialCheckedAreas);
  const [checkedRegions, setCheckedRegions] = useState<any>(initialCheckedRegions);

  //Get FriendRequests
  useEffect(() => {
    sharedContext.updateHeaderTitle(`Explorar`);
    // FriendRequestApi.find({
    //   where: {appUserId: UserInfo.getAuthInfo().currentUserId},
    //   include: 'requester'
    // }).then((result) => {
    //   const requesters: AppUser[] = result.data.map((friendRequest: FriendRequest) => {
    //     return {
    //       ...friendRequest.requester,
    //       friendRequestId: friendRequest.id
    //     }
    //   });
    //   setRequesterProfiles(requesters as any)
    // }).catch((error) => {
    //   console.error(error);
    // });
  }, [sharedContext]);


  const renderProjects = (element: any, index: number) => {
    return (
      <ProjectCard
        project={element}
        key={element.id}/>
    );
  };

  useEffect(() => {
    ProjectApi.suggestedUserProjects(props.authInfo.currentUserId, discoveryQuery, 9999).then((projects) => {

      projects = projects.filter((project) => project.data);

      setSuggestedProjects(projects);

      setFilteredProjects(_.orderBy(projects, ['isPromoted'], ['desc']));
    });
  }, [props.authInfo.currentUserId, discoveryQuery]);


  useEffect(() => {

    setFilteredProjects(_.filter(_.orderBy(suggestedProjects, ['isPromoted'], ['desc']), (project:any) => {
      // console.log(project.data)
      let show = false;

      if(!project || !project.data || !project.data.areasOfInterest) {
        console.log('incomplete', project);
      }

      if(project && project.data && project.data.areasOfInterest) {
        project.data.areasOfInterest.forEach((area: string) => {
          if (checkedAreas[area]) {
            show = true;
          }
        });
      }

      if(!show) {
        console.log('hide: not in area of interest');
      }

      if((!needChecked && project.type === 'need') || (!offerChecked && project.type === 'offer') || (!supportChecked && project.type === 'support')) {
        show = false;
        console.log('hide: projectType not checked');
      }

      // console.log(project.appUser.profileData.mxStateCode, findRegion(project.appUser.profileData.mxStateCode), checkedRegions[findRegion(project.appUser.profileData.mxStateCode)]);

      if(!project.appUser || !project.appUser.profileData || !project.appUser.profileData.mxStateCode || !findRegion(project.appUser.profileData.mxStateCode) || !checkedRegions[findRegion(project.appUser.profileData.mxStateCode)]) {
        show = false;
        console.log('hide: no region match')
      }

      if(!show) {
        console.log('hidden project:', project);
      }

      return show;
    }));

  }, [suggestedProjects, checkedAreas, checkedRegions, needChecked, offerChecked, supportChecked]);


  const setChecked = (value:string, state:boolean) => {
    let tempCheckedAreas = {...checkedAreas};
    tempCheckedAreas[value] = state;
    setCheckedAreas({...tempCheckedAreas});
  };

  const setCheckedRegion = (value:string, state:boolean) => {
    let tempCheckedRegions = {...checkedRegions};
    tempCheckedRegions[value] = state;
    setCheckedRegions({...tempCheckedRegions});
  };

  // const setAllChecked = (value:boolean) => {
  //   if(value) {
  //
  //   } else {
  //
  //   }
  // };

  const renderAreaFilters = () => {
    return areas.map((area) =>
      <span key={[area.value, checkedAreas]}>
        <IonCheckbox color="primary" checked={checkedAreas[area.value]} onClick={(e) => {
          console.log('clicked', e);
          //setChecked(area.value, e.detail.checked)
          setChecked(area.value, !checkedAreas[area.value]);
        }}/>
        <label>{area.label}</label>
      </span>
    );
  };

  const renderRegionFilters = () => {
    return regions.map((region) =>
      <span key={[region.label, checkedRegions]}>
        <IonCheckbox color="primary" checked={checkedRegions[region.label]} onClick={(e) => {
          console.log('clicked', e);
          //setChecked(region.value, e.detail.checked)
          setCheckedRegion(region.label, !checkedRegions[region.label]);

          console.log('checkedRegions', checkedRegions);
        }}/>
        <label>{region.label}</label>
      </span>
    );
  };

  const exportJson = () => {
    // filteredProjects.forEach();

    let projects = filteredProjects.map((project:Project) => {

      let friendlyData:any = {};

      Object.entries(project.friendlyData).forEach(([key,value]:any)=>{
        friendlyData[key] = {
          questionIndex: value.questionIndex,
          label: value.es.label,
          value: value.es.value,

        }
      })


      return {
        id: project.id,
        email: project.appUser && project.appUser.email,
        user: project.appUser && (project.appUser.firstName + ' ' + project.appUser.lastName),
        companyName: project.appUser && project.appUser.profileData && project.appUser.profileData.companyName,
        ...friendlyData,

      }
    })

    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(projects, null, 2));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", 'fichas.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  return (
    <IonContent padding class="full-height">
      <h1 className="section-title">
        Explorar
        {isAdmin && <IonButton style={{float: 'right'}} onClick={() => exportJson()} size="small">Descargar JSON</IonButton>}
      </h1>
      <p className="section-subtitle">Selecciona uno o más filtros y comienza a explorar</p>
      <div className={styles['area-filters-container']}>
        <div className={styles['area-filters-title']}>
          <span>Industria 4.0</span>
        </div>
        {renderAreaFilters()}
        <br/>
        <IonButton size={'small'} onClick={() => setCheckedAreas({...initialCheckedAreas})}>Seleccionar todos</IonButton>
        <IonButton size={'small'} onClick={() => setCheckedAreas({})}>Deseleccionar todos</IonButton>
      </div>
      <div className={styles['area-filters-container']}>
        <div className={styles['area-filters-title']}>
          <span>Regiones</span>
        </div>
        {renderRegionFilters()}
        <br/>
        <IonButton size={'small'} onClick={() => setCheckedRegions({...initialCheckedRegions})}>Seleccionar todas</IonButton>
        <IonButton size={'small'} onClick={() => setCheckedRegions({})}>Deseleccionar todas</IonButton>
      </div>
      <div className={styles['filters-container']}>
        <div>
          <IonCheckbox color="primary" checked={offerChecked} onIonChange={(e) => {
            setOfferChecked(e.detail.checked)
          }}/>
          <label>Oferta</label>
        </div>
        <div>
          <IonCheckbox color="primary" checked={needChecked} onIonChange={(e) => {
            setNeedChecked(e.detail.checked)
          }}/>
          <label>Necesidad</label>
        </div>
        <div>
          <IonCheckbox color="primary" checked={supportChecked} onIonChange={(e) => {
            setSupportChecked(e.detail.checked)
          }}/>
          <label>Apoyo</label>
        </div>
      </div>

      {requesterProfiles.length > 0 && <Profiles listTitle="Solicitudes" profiles={requesterProfiles}/>}
      <GridList

        hideAdd={true}
        list={filteredProjects}
        renderListElement={renderProjects}
      />
    </IonContent>
  );
};
export default withAuthInfo(Discover);
