import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import SharedContext from "../../../../shared/context/SharedContext";
import {AgGridReact} from "ag-grid-react";
import {MarketReport} from "../../../../shared/interfaces/loopback";
import AlertService from "../../../../shared/services/AlertService";
import ToastService from "../../../../shared/services/ToastService";
import columnTypes from '../../../../shared/utils/columnTypes';
import {MarketReportApi} from "../../../../shared/services/MarketReportService";

const ActionsCell = (props:any) => {

  const deleteMarketReport = (marketReport:MarketReport|any) => {
    return MarketReportApi.deleteById(marketReport.id)
      .then(_ => {
        ToastService({
          message: `El reporte  ${marketReport.title} fue eliminado`,
        });
        // remove row from agGrid
        props.api.updateRowData({ remove: [props.data] });
      })
      .catch(err => {
        console.log(err.statusCode);
      });
  };

  const confirmDelete = (marketReport:MarketReport|any) => {
    AlertService({
      header: 'Eliminar reporte',
      message: `¿Desea eliminar el reporte <b>${marketReport.title}</b>?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Eliminar',
          handler: () => {
            deleteMarketReport(marketReport);
          }
        }
      ]
    });
  };



  return <>
    <IonButton size="small" href={`/private/admin/marketReports/edit/${props.value}`}>
      <IonIcon name={'create'}/>
    </IonButton>
    <IonButton color={'danger'} size="small" onClick={() => confirmDelete(props.data)}>
      <IonIcon name={'trash'}/>
    </IonButton>
  </>;
};


const Table = (props: any) => {
  const sharedContext = useContext(SharedContext);
  const [marketReports, setMarketReports] = useState<MarketReport[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  useEffect(() => {
    sharedContext.updateHeaderTitle('Usuarios');
  }, [sharedContext]);

  useEffect(() => {
    MarketReportApi.find({})
      .then(setMarketReports);
  }, []);

  const columnDefs:any =
    [
      {
        headerName: 'Título', field: 'title', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Descripción', field: 'description', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Archivo', field: '_file.fileName', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Fecha de creación', field: 'createdAt', sortable: true, type: 'shortDate'
      },
      {
        headerName: '',
        field: 'id',
        cellRendererFramework: ActionsCell,
        cellRendererParams: {history: props.history}
      }
    ];

  const gridReady = (data:any) => {
    console.log(data);
    setGridApi(data.api);
  }

  const exportCsv = () => {
    gridApi.exportDataAsCsv({})
  }

  return (
    <div className="full-height ag-theme-material" style={{width: '100%', height: '100%'}}>
      <h1 className={'section-title'}>
        Tendencias
        <IonButton style={{float: 'right'}} href={`/private/admin/marketReports/new`} size="small">Agregar reporte</IonButton>
        <IonButton style={{float: 'right'}} onClick={() => exportCsv()} size="small">Descargar CSV</IonButton>
      </h1>
      <AgGridReact
        columnTypes={columnTypes}
        floatingFilter={true}
        suppressCellSelection={true}
        rowSelection="true"
        columnDefs={columnDefs}
        onGridReady={gridReady}
        rowData={marketReports}>
      </AgGridReact>
    </div>
  );
};
export default Table;
