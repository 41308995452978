import React, {useState, useEffect, useRef, useContext} from 'react';
import UserInfo, {withAuthInfo} from "../../../shared/services/UserInfo";
import {ProjectApi} from "../../../shared/services/ProjectService";
import {AppUser, HelpRequest, Project, Questionnaire} from "../../../shared/interfaces/loopback";
import SharedContext from "../../../shared/context/SharedContext";
import {QuestionnaireApi} from "../../../shared/services/QuestionnaireService";
import {IonIcon, IonModal, IonButton, IonToolbar, IonHeader, IonButtons, IonTitle} from '@ionic/react';
import ProjectChat from "../../../shared/components/ProjectChat/ProjectChat";
import {OnboardingForm} from "../../../shared/components/onboarding-form";
import AlertService from "../../../shared/services/AlertService";
import ToastService from "../../../shared/services/ToastService";
import styles from "./Edit.module.css";
import {AppUserApi} from "../../../shared/services/AppUserService";
import FileUploader from "../../../shared/components/FileUploader/FileUploader";
import {HelpRequestApi} from "../../../shared/services/HelpRequestService";

const projectNamesByType:any = {
  'offer': 'Oferta',
  'need': 'Necesidad',
  'support': 'Apoyo'
};

const Edit = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const [project, setProject] = useState<Project>({type:  props.match.params.projectType, isPublished: false, data: {}, friendlyData: {}, appUserId: props.authInfo.currentUserId});
  const [formData, setFormData] = useState<Questionnaire | null>(null);
  const [showChat, setShowChat] = useState<any>(false);

  const [formValues, setFormValues] = useState<any>({values: {}, friendlyValues: {}});

  const isMounted = useRef(true);

  const modalRef:any = useRef();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, []);

  const createHelpRequest = () => {
    HelpRequestApi.upsert({
      projectId: project.id,
      requesterId: props.authInfo.currentUserId
    }).then((helpRequest:HelpRequest) => {
      ToastService({message: `Tu solicitud de ayuda ha sido enviada`});
      setShowChat(false);
    }).catch((e:any) => {
      ToastService({message: e.friendlyMessage || 'Ocurrio un error, intenta más tarde'});
      setShowChat(false);
    });
  };


  // const modal = await modalController.create({ component: MyModalComponent, componentProps: { 'prop1': value, 'prop2': value2 } });

  useEffect(() => {
    const id = props.match.params.id;
    let promise;
    let requestedProject: Project;
    if(id) {
      promise = ProjectApi.findById(id)
        .then((project: Project) => {
          if(!project.data) {
            project.data = {};
          }
          requestedProject = project;

          sharedContext.updateHeaderTitle(`${projectNamesByType[(project.type as string)]}: ${project.data.name}`);

          setProject(project);

          console.log('project edit:', project);

          // guard
          if(!props.authInfo.isStaff && props.authInfo.currentUser.id !== project.appUserId) {
            ToastService({message: `No tienes permisos para editar el proyecto`});
            props.history.push('/private/project/view/' + id);
            return Promise.resolve(null);
          } else {
            return QuestionnaireApi.findOne({where:{name:project.type}})
          }


        })
    } else {
      const projectType = props.match.params.projectType;
      sharedContext.updateHeaderTitle(`Crear ${projectNamesByType[projectType]}`);
      promise = QuestionnaireApi.findOne({where:{name:projectType}})
    }
    promise
      .then((questionnaire: Questionnaire | null ) => {
        if(questionnaire) {
          if(requestedProject) {
            questionnaire.values = requestedProject.data;
            setFormValues({
              values: requestedProject.data || {},
              friendlyValues: requestedProject.friendlyData || {}
            });
          }

          // console.log('questionnaire', questionnaire);
          setFormData(questionnaire);
        }
      });
  }, [sharedContext, props.authInfo, props.match.params, props.history]);

  const saveDraft = () => {
    console.log('formValues', formValues);

    let newProject:Project = {
      ...project,
      data: formValues.values,
      friendlyData: formValues.friendlyValues,
      appUserId: props.authInfo.currentUserId,
    };

    return ProjectApi.upsert(newProject)
      .then((project:any) => {
        ToastService({message: `Tu ficha ha sido actualizada`});
        setProject(project);
        props.history.push('/private/project/edit/' + project.id);
      })
  };

  const promptSaveBeforeChat = () => {
    AlertService({
      header: 'Guardar borrador',
      message: `Para poder pedir ayuda debe existir un borrador. ¿Deseas crearlo?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Guardar',
          handler: () => {
            saveDraft()
              .then(() => setShowChat(true));
          }
        }
      ]
    });
  };

  const promptSaveDraft = () => {
    AlertService({
      header: 'Guardar borrador',
      message: `Un borrador es una ficha  incompleta que no será publicada hasta que no termines de llenar la información. Puedes ver tus borradores en la sección de "Mis Fichas"`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Guardar',
          handler: () => {
            saveDraft()
          }
        }
      ]
    });
  };

  const deleteProject = () => {

    if(project.id) {
      ProjectApi.deleteById(project.id)
        .then((project: any) => {
          ToastService({message: `Tu ficha ha sido eliminada`});
          setProject(project);
          props.history.push('/private/dashboard');
        })
    }
  };

  const promptDelete = () => {
    AlertService({
      header: 'Eliminar ficha',
      message: `Si continúas la ficha ya no estará disponible en tu perfil. Esta operación es irreversible, por favor confirma que deseas continuar.`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Eliminar',
          handler: () => {
            deleteProject();
          }
        }
      ]
    });
  };


  const saveAndPublish = () => {
    console.log('formValues', formValues);

    let newProject:Project = {
      ...project,
      isPublished: true,
      data: formValues.values,
      friendlyData: formValues.friendlyValues,
      appUserId: props.authInfo.currentUserId,
    };

    ProjectApi.upsert(newProject)
      .then((project:any) => {
        ToastService({message: `Tu ficha ha sido publicada`});
        setProject(project);
        props.history.push('/private/project/edit/' + project.id);
      });
  };

  const promptSaveAndPublish = () => {
    AlertService({
      header: project.isPublished? 'Guardar cambios': 'Publicar ficha',
      message: project.isPublished? 'Confirma que deseas guardar los cambios': `Si continúas la ficha será publicada y otros usuarios podrán verla`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: project.isPublished? 'Guardar': 'Publicar',
          handler: () => {
            saveAndPublish();
          }
        }
      ]
    });
  };

  const onImageAssigned = (projectPromise:Promise<Project>) => {
    projectPromise.then((newProject:Project) => {
      console.log('image uploaded callback', newProject);

      const projectToSave = {...project};
      projectToSave._image = newProject._image;
      setProject(projectToSave);
    });
  };

  return (
    <>
      {formData && project && <OnboardingForm data={formData as Questionnaire} class="full-height" onFormHasFinished={promptSaveAndPublish} onFormHasChanged={({detail:data}) => setFormValues(data)} >
        <div slot="control-bar">

          <IonButton onClick={() => project.id? setShowChat(true): promptSaveBeforeChat()}>
            <IonIcon slot="start" name="help-circle-outline" />
            Ayuda
          </IonButton>
          {project.id && <FileUploader
            buttonText={'Cargar imagen'}
            modelApi={ProjectApi}
            modelId = {project.id}
            relationName = "image"
            onFileAssigned={onImageAssigned}
            instructions={'Arrastre una al recuadro o haga click para seleccionarla\n(jpg, png o gif)'}
          />}
          {!project.id && <IonButton onClick={() => promptSaveDraft()}>
            <IonIcon slot="start" name="save" />
            Guardar borrador
          </IonButton>}
          {project.id && <IonButton color="danger" onClick={() => promptDelete()}>
            <IonIcon slot="start" name="trash" />
            Eliminar ficha
          </IonButton>}
        </div>
      </OnboardingForm>}
      <IonModal
        children={null}
        isOpen={showChat}
        ref={modalRef}
        onDidDismiss={() =>  setShowChat(false)}>
        <IonHeader>
          <IonToolbar color={'primary'}>
            <IonButtons slot="end">
              <IonButton onClick={() => setShowChat(false)}><IonIcon name="close"/></IonButton>
            </IonButtons>
            <IonTitle>Videotutorial</IonTitle>
          </IonToolbar>

          <video width="100%" height="auto" controls>
            <source src="/assets/videos/crear-ficha.mp4" type="video/mp4" />
            <source src="/assets/videos/crear-ficha.webm" type="video/webm" />
              Tu explorador no soporta videos
          </video>

        </IonHeader>
        <div class={styles['modal-footer']}>
          <p class={styles['modal-footer-title']}>¿Necesitas más ayuda?</p>
          <p class={styles['modal-footer-subtitle']}>
            Haz click en el siguiente botón y un asesor se pondrá pronto en contacto contigo.
          </p>
          <IonButton color={'primary'} onClick={() => createHelpRequest()}>
            <IonIcon slot="start" name="help-circle-outline" />
            SOLICITAR AYUDA DE ASESOR
          </IonButton>
        </div>
      </IonModal>
    </>
  );
});
export default Edit;
