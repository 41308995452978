import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon} from "@ionic/react";
import styles from "./ProfileCard.module.css";
import React from "react";
import {withRouter} from "react-router";
import {UploadedFileApi} from "../../services/UploadedFileService";

const ProfileCard = (props: any) => {

  console.log('profileCard', props);

  function goToProfileHandler(e: Event) {
    props.history.push('/private/profile/view/'+props.profile.id);
  }

  return (
    <IonCard class={styles['profile-card']} onClick={goToProfileHandler}>
      <img src={UploadedFileApi.getImageUrl(props.profile._avatar)} alt={props.profile.firstName}/>
      <div className={styles['overlay']}>
        <h2 lang="es" className={styles['profile-title']}>{props.profile.profileData.companyName}</h2>
        <p lang="es" className={styles['description']}>
          <b>Nombre:</b><br />
          {props.profile.firstName} {props.profile.lastName}
        </p>
      </div>
    </IonCard>
  )
};
export default withRouter(ProfileCard);
