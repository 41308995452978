import React from "react";
// import styles from "./Analytics.css";
import {Route, Switch} from "react-router";
import Interests from './Interests/Interests';
import Friendships from './Friendships/Friendships';
import Users from './Users/Users';
import PageViews from './PageViews/PageViews';
import SectorsOfInterest from './SectorsOfInterest/SectorsOfInterest';
import Projects from './Projects/Projects';
import Searches from './Searches/Searches';
import Offers from './Offers/Offers';
import Needs from './Needs/Needs';
import Support from './Support/Support';

import {IonCol, IonContent, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow} from "@ionic/react";
import {Link, NavLink} from "react-router-dom";

const routes:any = [
  {
    label: 'Tendencias de Interés',
    path: 'interests',
    component: Interests
  },
  {
    label: 'Vinculaciones',
    path: 'friendships',
    component: Friendships
  },
  {
    label: 'Número de usuarios',
    path: 'users',
    component: Users
  },
  {
    label: 'Visitas a la página',
    path: 'pageViews',
    component: PageViews
  },
  // {
  //   label: 'Sectores de mayor interés',
  //   path: 'sectorsOfInterest',
  //   component: SectorsOfInterest
  // },
  {
    label: 'Número de fichas',
    path: 'projects',
    component: Projects
  },
  {
    label: 'Búsquedas',
    path: 'searches',
    component: Searches
  },
  {
    label: 'Análisis de oferta',
    path: 'offers',
    component: Offers
  },
  {
    label: 'Análisis de necesidades',
    path: 'needs',
    component: Needs
  },
  {
    label: 'Análisis de apoyos',
    path: 'supports',
    component: Support
  }
];

const Analytics = (props: any) => {

  return (
    <>

      <h1 className="section-title">Consulta de Datos</h1>
      <IonGrid>
        <IonRow>
          <IonCol class={'ion-align-self-stretch'} size={'12'} size-md={'3'}>
            {routes.map((route:any, index:number) =>
              // @ts-ignore
              <NavLink key={index} to={props.match.path + "/" + route.path} activeStyle={{ backgroundColor: 'red' }} activeClassName="active">
                <IonItem button>
                  <IonLabel>
                    {route.label}
                  </IonLabel>
                </IonItem>
              </NavLink>)}
          </IonCol>
          <IonCol size={'12'} size-md={'9'}>
            <Switch>
              {routes.map((route:any, index:number) => <Route key={index} path={props.match.path + "/" + route.path} component={route.component}/>)}
            </Switch>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>

  )
}
export default Analytics;
