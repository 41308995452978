import axios from 'axios';
import {AnalyticsEvent, LoopBackFilter} from "../interfaces/loopback";
import UserInfo from "./UserInfo"

export class AnalyticsEventApi {
  static RESOURCE_URL = '/AnalyticsEvents/';

  static find(filter: LoopBackFilter = {}): Promise<AnalyticsEvent[]> {
    return axios.get(`${AnalyticsEventApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static create(appUserId: string): Promise<AnalyticsEvent> {
    return axios.post(`${AnalyticsEventApi.RESOURCE_URL}`,
      {appUserId, requesterId: UserInfo.getAuthInfo().currentUserId},
      {
        headers: {...UserInfo.baseHeaders()}
      }
    ).then(resp => resp.data)
      .catch(err => Promise.reject(err.response.data.error));
  }

  static flatten(analyticsEvents: AnalyticsEvent[]) {

    const projectTypes:any = {
      need: 'Necesidad',
      offer: 'Oferta',
      support: 'Apoyo'
    };

    const mxStateCodes:any = {
      AS: "Aguascalientes",
      BC: "Baja California",
      BS: "Baja California Sur",
      CC: "Campeche",
      CS: "Chiapas",
      CH: "Chihuahua",
      DF: "Ciudad de México",
      CL: "Coahuila",
      CM: "Colima",
      DG: "Durango",
      GT: "Guanajuato",
      GR: "Guerrero",
      HG: "Hidalgo",
      JC: "Jalisco",
      MC: "México",
      MN: "Michoacán",
      MS: "Morelos",
      NT: "Nayarit",
      NL: "Nuevo León",
      OC: "Oaxaca",
      PL: "Puebla",
      QO: "Querétaro",
      QR: "Quintana Roo",
      SP: "San Luis Potosí",
      SL: "Sinaloa",
      SR: "Sonora",
      TC: "Tabasco",
      TS: "Tamaulipas",
      TL: "Tlaxcala",
      VZ: "Veracruz",
      YN: "Yucatán",
      ZS: "Zacatecas",
    };


    const sectorsOfInterest:any = {
      A: "Textil",
      B: "TIC´s",
      C: "Moldes y Troqueles",
    };


    const areasOfInterest:any = {
      A: "Big Data and Analytics",
      B: "Internet de las cosas",
      C: "Impresión 3D (Manufactura aditiva)",
      D: "La nube (cloud computing)",
      E: "Ciberseguridad",
      F: "Robots autónomos / Automatización",
      G: "Simulación",
      H: "Sistemas integrados a nivel horizontal y/o vertical",
      I: "Realidad aumentada",
      J: "Inteligencia artificial",
      K: "Blockchain",
      L: "Otra",
    };

    const accountTypes:any = {
      "1": "Cuenta institucional",
      "2": "Cuenta individual",
      "1A": "Centro Público de investigación (CPI)",
      "1B": "Institución de Educación Superior (IES)",
      "1C": "Oficina de Transferencia de Tecnología Privada (OTT)",
      "1D": "Oficina de Transferencia de Tecnología dentro de Institución de Educación Superior (OTT-IES)",
      "1E": "Oficina de Transferencia de Tecnología dentro de Centro Público de investigación (OTT-CPI)",
      "1F": "Empresa Privada/Industria",
      "1G": "Cámara",
      "1H": "Centro de Innovación Industrial",
      "1I": "Clúster ",
      "1J": "Incubadora ",
      "1K": "Aceleradora",
      "1L": "Fondo de Inversión de Capital Privado",
      "1M": "Otra",
      "2A": "Centro Público de investigación (CPI)",
      "2B": "Institución de Educación Superior (IES)",
      "2C": "Oficina de Transferencia de Tecnología Privada (OTT)",
      "2D": "Oficina de Transferencia de Tecnología dentro de Institución de Educación Superior (OTT-IES)",
      "2E": "Oficina de Transferencia de Tecnología dentro de Centro Público de investigación (OTT-CPI)",
      "2F": "Empresa Privada/Industria",
      "2G": "Cámara",
      "2H": "Centro de Innovación Industrial",
      "2I": "Clúster ",
      "2J": "Incubadora ",
      "2K": "Aceleradora",
      "2L": "Fondo de Inversión de Capital Privado",
      "2M": "Otra",
    };


    console.log('events to flatten', analyticsEvents);

    const headers:any = [
      'ID',
      'Fecha',
      'Sectores',
      'Áreas',
      'Tipos de fichas',
      'Tipo de cuenta',
      'Estado',
      'Tipo de evento',
    ];

    return [headers , ...analyticsEvents.map((e: AnalyticsEvent) => {
      return [
        e.id,
        e.createdAt,
        e.sectorsOfInterest? e.sectorsOfInterest.map((val:string) => sectorsOfInterest[val]).join(', '): '',
        e.areasOfInterest? e.areasOfInterest.map((val:string) => areasOfInterest[val]).join(', '): '',
        e.projectTypes? e.projectTypes.map((val:string) => projectTypes[val]).join(', '): '',
        e.accountType? accountTypes[e.accountType]: '',
        e.mxStateCode? mxStateCodes[e.mxStateCode]: '',
        e.eventType
      ];
    })];

  }


  static async getMockData(): Promise<AnalyticsEvent[]> {
    const mockData: AnalyticsEvent[] = [
      {
        "id": "5dfffb596ef980aec4678c33",
        "sectorsOfInterest": [
          "B",
          "A",
          "C"
        ],
        "areasOfInterest": [
          "G",
          "J",
          "K"
        ],
        "mxStateCode": "QR",
        "projectTypes": [
          "offer",
          "offer",
          "offer"
        ],
        "accountType": "2F",
        "eventType": "",
        "createdAt": "Thu Feb 14 2019 23:35:44 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59bf691bfb522ff99a",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "C",
          "J"
        ],
        "mxStateCode": "DF",
        "projectTypes": [
          "need",
          "offer"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Mon Aug 06 2018 14:31:13 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59457bafc1d123dcaa",
        "sectorsOfInterest": [
          "C",
          "C",
          "B"
        ],
        "areasOfInterest": [
          "J",
          "D"
        ],
        "mxStateCode": "CC",
        "projectTypes": [
          "need",
          "support",
          "need"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Sun Mar 18 2018 21:42:19 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59679e3bf7c6f829b9",
        "sectorsOfInterest": [
          "B",
          "C",
          "C"
        ],
        "areasOfInterest": [
          "H",
          "K"
        ],
        "mxStateCode": "CL",
        "projectTypes": [
          "support",
          "offer",
          "support"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Mon Apr 23 2018 02:59:18 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59ea2204c4630a2450",
        "sectorsOfInterest": [
          "A",
          "A",
          "B"
        ],
        "areasOfInterest": [
          "C"
        ],
        "mxStateCode": "CH",
        "projectTypes": [
          "support",
          "support",
          "support"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Tue Oct 02 2018 19:33:23 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59010538512a4a4efc",
        "sectorsOfInterest": [
          "A",
          "C"
        ],
        "areasOfInterest": [
          "A",
          "H"
        ],
        "mxStateCode": "CS",
        "projectTypes": [
          "support",
          "offer"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Fri Dec 13 2019 03:37:25 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59c4a861b7b525ebe4",
        "sectorsOfInterest": [
          "A",
          "A",
          "A"
        ],
        "areasOfInterest": [
          "D",
          "C",
          "J"
        ],
        "mxStateCode": "CH",
        "projectTypes": [
          "need",
          "offer"
        ],
        "accountType": "2J",
        "eventType": "",
        "createdAt": "Sat Jul 14 2018 01:38:24 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb598d579d07e05d6d5e",
        "sectorsOfInterest": [
          "C",
          "A",
          "C"
        ],
        "areasOfInterest": [
          "L",
          "F",
          "L"
        ],
        "mxStateCode": "CS",
        "projectTypes": [
          "need",
          "offer"
        ],
        "accountType": "2A",
        "eventType": "",
        "createdAt": "Sat Jan 20 2018 17:28:36 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb598e23f181be39047e",
        "sectorsOfInterest": [
          "C",
          "B"
        ],
        "areasOfInterest": [
          "D"
        ],
        "mxStateCode": "CH",
        "projectTypes": [
          "offer"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Thu Dec 05 2019 22:02:33 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59121ead99a6875eaa",
        "sectorsOfInterest": [
          "A"
        ],
        "areasOfInterest": [
          "C"
        ],
        "mxStateCode": "DG",
        "projectTypes": [
          "need",
          "offer",
          "offer"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Mon Jul 15 2019 00:17:50 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59d55383afa70f3859",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "F",
          "A",
          "A"
        ],
        "mxStateCode": "SR",
        "projectTypes": [
          "need",
          "need"
        ],
        "accountType": "2A",
        "eventType": "",
        "createdAt": "Sun Dec 23 2018 03:17:12 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5984b73f8e4bf0b13d",
        "sectorsOfInterest": [
          "C",
          "C"
        ],
        "areasOfInterest": [
          "G",
          "D",
          "D"
        ],
        "mxStateCode": "QO",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Tue May 14 2019 12:08:47 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59acb46630c6b887e8",
        "sectorsOfInterest": [
          "C",
          "B",
          "B"
        ],
        "areasOfInterest": [
          "D",
          "D",
          "D"
        ],
        "mxStateCode": "MS",
        "projectTypes": [
          "need"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Mon Jun 24 2019 00:49:22 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5986abc0a1cb13270f",
        "sectorsOfInterest": [
          "A"
        ],
        "areasOfInterest": [
          "K",
          "G"
        ],
        "mxStateCode": "NL",
        "projectTypes": [
          "support",
          "support",
          "support"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Thu Dec 27 2018 07:10:56 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb598b89d911dede978d",
        "sectorsOfInterest": [
          "C",
          "A"
        ],
        "areasOfInterest": [
          "H",
          "G"
        ],
        "mxStateCode": "TL",
        "projectTypes": [
          "need",
          "need",
          "need"
        ],
        "accountType": "2B",
        "eventType": "",
        "createdAt": "Mon Jun 24 2019 19:13:01 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59e15e9cdda49d796e",
        "sectorsOfInterest": [
          "C",
          "C",
          "C"
        ],
        "areasOfInterest": [
          "K",
          "F",
          "A"
        ],
        "mxStateCode": "QO",
        "projectTypes": [
          "need",
          "offer",
          "need"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Thu Jul 25 2019 17:57:04 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59eefd55a74a20612b",
        "sectorsOfInterest": [
          "B",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "L"
        ],
        "mxStateCode": "JC",
        "projectTypes": [
          "offer",
          "need",
          "need"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Wed Apr 24 2019 02:32:47 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59b128ce14248d18a9",
        "sectorsOfInterest": [
          "B",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "H",
          "H",
          "G"
        ],
        "mxStateCode": "YN",
        "projectTypes": [
          "offer",
          "offer",
          "support"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Wed Dec 04 2019 21:00:04 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb593f80c8c974bbde33",
        "sectorsOfInterest": [
          "B",
          "C"
        ],
        "areasOfInterest": [
          "G"
        ],
        "mxStateCode": "SP",
        "projectTypes": [
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Wed Apr 24 2019 03:38:27 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb592e288eca65c7f17b",
        "sectorsOfInterest": [
          "C",
          "C"
        ],
        "areasOfInterest": [
          "H"
        ],
        "mxStateCode": "NL",
        "projectTypes": [
          "support",
          "support",
          "support"
        ],
        "accountType": "2E",
        "eventType": "",
        "createdAt": "Thu Sep 12 2019 02:20:11 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb590959b6f83501f1f7",
        "sectorsOfInterest": [
          "C",
          "A",
          "B"
        ],
        "areasOfInterest": [
          "J"
        ],
        "mxStateCode": "CS",
        "projectTypes": [
          "support",
          "need",
          "need"
        ],
        "accountType": "2F",
        "eventType": "",
        "createdAt": "Sat Aug 04 2018 16:21:51 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb592ea32aa7d9af17f7",
        "sectorsOfInterest": [
          "C",
          "C"
        ],
        "areasOfInterest": [
          "F",
          "A",
          "E"
        ],
        "mxStateCode": "DF",
        "projectTypes": [
          "offer",
          "support",
          "need"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Fri Jun 29 2018 09:00:55 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5916c0ce2d2db68705",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "G",
          "F",
          "F"
        ],
        "mxStateCode": "PL",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2A",
        "eventType": "",
        "createdAt": "Thu Feb 07 2019 01:25:04 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb592009f05bf4934206",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "J",
          "G"
        ],
        "mxStateCode": "MS",
        "projectTypes": [
          "need",
          "support",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Sat Apr 28 2018 20:28:09 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59c276b4c82cf4e1fe",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "I"
        ],
        "mxStateCode": "CS",
        "projectTypes": [
          "need"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Fri Jun 15 2018 18:41:36 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5960bc45c7a85f4adf",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "G",
          "K",
          "D"
        ],
        "mxStateCode": "SR",
        "projectTypes": [
          "need"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Mon Oct 22 2018 00:55:20 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59c84b2b0c95262595",
        "sectorsOfInterest": [
          "A",
          "A",
          "B"
        ],
        "areasOfInterest": [
          "I",
          "D"
        ],
        "mxStateCode": "SL",
        "projectTypes": [
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Sun Aug 12 2018 13:42:34 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59273b87fc5ff762a1",
        "sectorsOfInterest": [
          "A",
          "C"
        ],
        "areasOfInterest": [
          "B",
          "G",
          "F"
        ],
        "mxStateCode": "SP",
        "projectTypes": [
          "support",
          "support"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Sat Mar 09 2019 18:47:01 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59db0eb2d885bb3255",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "A",
          "G",
          "I"
        ],
        "mxStateCode": "YN",
        "projectTypes": [
          "support",
          "need",
          "offer"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Thu Nov 07 2019 13:11:47 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb590d5d0e886f632997",
        "sectorsOfInterest": [
          "B",
          "A",
          "A"
        ],
        "areasOfInterest": [
          "F",
          "B"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "support",
          "need",
          "offer"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Fri Nov 22 2019 20:35:33 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59439f29acf2995e91",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "A"
        ],
        "mxStateCode": "BC",
        "projectTypes": [
          "need",
          "support",
          "support"
        ],
        "accountType": "2F",
        "eventType": "",
        "createdAt": "Sun Sep 02 2018 12:21:13 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5957c03e16a411d3d6",
        "sectorsOfInterest": [
          "A",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "F",
          "J"
        ],
        "mxStateCode": "BC",
        "projectTypes": [
          "support"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Mon Oct 07 2019 02:22:53 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59287516d1510fdcb2",
        "sectorsOfInterest": [
          "A",
          "A",
          "A"
        ],
        "areasOfInterest": [
          "I",
          "I"
        ],
        "mxStateCode": "QR",
        "projectTypes": [
          "need",
          "support",
          "offer"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Sat Feb 24 2018 17:06:01 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59be16b1c02151598a",
        "sectorsOfInterest": [
          "A"
        ],
        "areasOfInterest": [
          "I"
        ],
        "mxStateCode": "DF",
        "projectTypes": [
          "need"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Thu Dec 05 2019 23:38:16 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59af0b1287efb85818",
        "sectorsOfInterest": [
          "C",
          "C"
        ],
        "areasOfInterest": [
          "J"
        ],
        "mxStateCode": "JC",
        "projectTypes": [
          "offer",
          "need"
        ],
        "accountType": "2J",
        "eventType": "",
        "createdAt": "Sat Sep 08 2018 07:14:18 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59541f223f3ed83286",
        "sectorsOfInterest": [
          "C",
          "B",
          "A"
        ],
        "areasOfInterest": [
          "A",
          "K",
          "G"
        ],
        "mxStateCode": "DF",
        "projectTypes": [
          "offer",
          "support",
          "need"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Wed May 30 2018 21:36:53 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb593575d1f70ff88a08",
        "sectorsOfInterest": [
          "B",
          "B"
        ],
        "areasOfInterest": [
          "G",
          "I"
        ],
        "mxStateCode": "SL",
        "projectTypes": [
          "offer",
          "need",
          "support"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Mon Apr 23 2018 22:13:10 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb595729c4effd1f14a1",
        "sectorsOfInterest": [
          "C",
          "C"
        ],
        "areasOfInterest": [
          "K"
        ],
        "mxStateCode": "HG",
        "projectTypes": [
          "offer",
          "support",
          "offer"
        ],
        "accountType": "2B",
        "eventType": "",
        "createdAt": "Fri Jan 11 2019 06:27:30 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59122b362b09a4d31f",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "I",
          "J"
        ],
        "mxStateCode": "QO",
        "projectTypes": [
          "support",
          "support"
        ],
        "accountType": "2J",
        "eventType": "",
        "createdAt": "Sun Nov 03 2019 18:38:27 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59c1b6ef070b2e3d19",
        "sectorsOfInterest": [
          "A"
        ],
        "areasOfInterest": [
          "E"
        ],
        "mxStateCode": "SL",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Sat Apr 07 2018 03:31:55 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb595286652254ad6335",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "B",
          "B"
        ],
        "mxStateCode": "MC",
        "projectTypes": [
          "need"
        ],
        "accountType": "2A",
        "eventType": "",
        "createdAt": "Sun Oct 20 2019 07:55:24 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb591e86d9130fbb7ffa",
        "sectorsOfInterest": [
          "A"
        ],
        "areasOfInterest": [
          "D"
        ],
        "mxStateCode": "MN",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2F",
        "eventType": "",
        "createdAt": "Mon Aug 13 2018 08:09:38 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59dbb0684b9eb06d52",
        "sectorsOfInterest": [
          "B",
          "B"
        ],
        "areasOfInterest": [
          "D"
        ],
        "mxStateCode": "JC",
        "projectTypes": [
          "need"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Sat Apr 13 2019 15:25:53 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5991a4fd5c8f4c0454",
        "sectorsOfInterest": [
          "C",
          "B",
          "A"
        ],
        "areasOfInterest": [
          "G",
          "F",
          "G"
        ],
        "mxStateCode": "CL",
        "projectTypes": [
          "need",
          "need",
          "offer"
        ],
        "accountType": "2B",
        "eventType": "",
        "createdAt": "Tue Dec 10 2019 07:02:52 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59e52a3dceb355cb1b",
        "sectorsOfInterest": [
          "B",
          "A"
        ],
        "areasOfInterest": [
          "G"
        ],
        "mxStateCode": "BS",
        "projectTypes": [
          "support",
          "need"
        ],
        "accountType": "2J",
        "eventType": "",
        "createdAt": "Sat Apr 07 2018 07:52:22 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb599285a007dab16664",
        "sectorsOfInterest": [
          "C",
          "B",
          "C"
        ],
        "areasOfInterest": [
          "B"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "offer",
          "support",
          "need"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Tue Mar 13 2018 13:20:49 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59f14476894913de53",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "A",
          "H",
          "C"
        ],
        "mxStateCode": "GR",
        "projectTypes": [
          "need",
          "offer",
          "offer"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Thu Jan 18 2018 03:24:09 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb596e8ff61040a6ffbe",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "G",
          "K"
        ],
        "mxStateCode": "SL",
        "projectTypes": [
          "support"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Mon Mar 26 2018 04:38:33 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb593abd91ad61d8c65b",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "B"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "need",
          "offer"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Sun May 12 2019 19:33:34 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59c726c405b2009307",
        "sectorsOfInterest": [
          "A",
          "B"
        ],
        "areasOfInterest": [
          "E",
          "L",
          "I"
        ],
        "mxStateCode": "QR",
        "projectTypes": [
          "need",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Mon Oct 21 2019 04:55:10 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5961cbebece6846282",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "C",
          "I"
        ],
        "mxStateCode": "BC",
        "projectTypes": [
          "need",
          "support"
        ],
        "accountType": "2A",
        "eventType": "",
        "createdAt": "Sat Dec 29 2018 23:36:45 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59f1296b8869b41a84",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "E",
          "B",
          "C"
        ],
        "mxStateCode": "TL",
        "projectTypes": [
          "need",
          "need"
        ],
        "accountType": "2L",
        "eventType": "",
        "createdAt": "Sat Mar 17 2018 18:27:30 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb591c50ca4c02b09dac",
        "sectorsOfInterest": [
          "C",
          "B",
          "B"
        ],
        "areasOfInterest": [
          "B",
          "K",
          "F"
        ],
        "mxStateCode": "CM",
        "projectTypes": [
          "offer",
          "offer"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Mon May 20 2019 16:48:38 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59c184bb58f751a19e",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "D",
          "D"
        ],
        "mxStateCode": "TL",
        "projectTypes": [
          "need"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Mon Apr 02 2018 20:01:53 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5957f77bbad059244c",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "C"
        ],
        "mxStateCode": "NL",
        "projectTypes": [
          "support",
          "need",
          "offer"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Wed Jun 26 2019 20:09:23 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59268acaa493327ca4",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "D",
          "G"
        ],
        "mxStateCode": "DG",
        "projectTypes": [
          "support",
          "offer",
          "offer"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Tue Feb 20 2018 23:41:50 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5917ec6f70d0355a63",
        "sectorsOfInterest": [
          "A",
          "C",
          "B"
        ],
        "areasOfInterest": [
          "K"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "need"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Wed Jun 19 2019 04:44:52 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59cfaa3f57a214b767",
        "sectorsOfInterest": [
          "A",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "D"
        ],
        "mxStateCode": "CC",
        "projectTypes": [
          "support",
          "support"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Thu Feb 14 2019 16:49:40 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5994b3e042018a7143",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "H"
        ],
        "mxStateCode": "MC",
        "projectTypes": [
          "need",
          "need",
          "offer"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Tue May 14 2019 00:33:00 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59d3048f2995face69",
        "sectorsOfInterest": [
          "A",
          "A",
          "C"
        ],
        "areasOfInterest": [
          "I",
          "J"
        ],
        "mxStateCode": "NL",
        "projectTypes": [
          "support",
          "need"
        ],
        "accountType": "2B",
        "eventType": "",
        "createdAt": "Mon Jul 01 2019 16:36:21 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5974293de4bd5ddd38",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "B",
          "I"
        ],
        "mxStateCode": "HG",
        "projectTypes": [
          "need"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Wed Jan 17 2018 07:40:51 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59be6a33b05dc62b6d",
        "sectorsOfInterest": [
          "C",
          "B",
          "A"
        ],
        "areasOfInterest": [
          "D",
          "H",
          "L"
        ],
        "mxStateCode": "CL",
        "projectTypes": [
          "offer",
          "need",
          "support"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Thu Jan 31 2019 14:05:38 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5941a35628d2cefd68",
        "sectorsOfInterest": [
          "B",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "B"
        ],
        "mxStateCode": "ZS",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Sun Oct 21 2018 16:47:49 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb598aa427d9452e65d4",
        "sectorsOfInterest": [
          "B",
          "B"
        ],
        "areasOfInterest": [
          "F"
        ],
        "mxStateCode": "QO",
        "projectTypes": [
          "support",
          "offer",
          "need"
        ],
        "accountType": "2L",
        "eventType": "",
        "createdAt": "Thu Aug 02 2018 02:22:40 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59899f085817173c75",
        "sectorsOfInterest": [
          "C",
          "B",
          "A"
        ],
        "areasOfInterest": [
          "K",
          "B",
          "F"
        ],
        "mxStateCode": "BS",
        "projectTypes": [
          "support",
          "offer",
          "need"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Sat Aug 11 2018 20:43:17 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59b3c3daadd6f2e7a1",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "A",
          "H",
          "D"
        ],
        "mxStateCode": "CH",
        "projectTypes": [
          "offer",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Sat Mar 16 2019 20:37:22 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5982c8879b165608d7",
        "sectorsOfInterest": [
          "C",
          "C"
        ],
        "areasOfInterest": [
          "A"
        ],
        "mxStateCode": "MN",
        "projectTypes": [
          "need"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Mon Dec 24 2018 05:51:06 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59114d6087b207a75b",
        "sectorsOfInterest": [
          "B",
          "C"
        ],
        "areasOfInterest": [
          "F"
        ],
        "mxStateCode": "OC",
        "projectTypes": [
          "offer",
          "offer",
          "offer"
        ],
        "accountType": "2E",
        "eventType": "",
        "createdAt": "Thu Nov 29 2018 10:32:35 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb594f2d7334e9060d2e",
        "sectorsOfInterest": [
          "B",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "A"
        ],
        "mxStateCode": "GR",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Thu Mar 07 2019 05:08:32 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb592809af77c46ad3c2",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "D"
        ],
        "mxStateCode": "SR",
        "projectTypes": [
          "need",
          "offer",
          "offer"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Fri Jul 13 2018 21:03:45 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb597b88ed9fe3bbe0a1",
        "sectorsOfInterest": [
          "C",
          "B",
          "A"
        ],
        "areasOfInterest": [
          "E"
        ],
        "mxStateCode": "GR",
        "projectTypes": [
          "offer",
          "support"
        ],
        "accountType": "2F",
        "eventType": "",
        "createdAt": "Wed Apr 10 2019 11:18:00 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59742404dcf6e38957",
        "sectorsOfInterest": [
          "A",
          "A",
          "A"
        ],
        "areasOfInterest": [
          "J",
          "E"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "need",
          "support",
          "offer"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Fri Feb 09 2018 19:43:58 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb599442a1e0639058a5",
        "sectorsOfInterest": [
          "B",
          "A",
          "A"
        ],
        "areasOfInterest": [
          "F",
          "I"
        ],
        "mxStateCode": "PL",
        "projectTypes": [
          "offer",
          "support"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Wed Feb 27 2019 01:05:06 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59bcd0cb3c1bb9674d",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "A",
          "K",
          "E"
        ],
        "mxStateCode": "CC",
        "projectTypes": [
          "offer",
          "support"
        ],
        "accountType": "2F",
        "eventType": "",
        "createdAt": "Sat Jun 23 2018 16:55:32 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59cd9848374d63fcf7",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "G",
          "D",
          "F"
        ],
        "mxStateCode": "DF",
        "projectTypes": [
          "need",
          "support",
          "offer"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Tue May 22 2018 11:59:07 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb590e9bdf1126c4f5e8",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "G"
        ],
        "mxStateCode": "BC",
        "projectTypes": [
          "support",
          "support"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Fri Sep 14 2018 03:25:59 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59f6edc647655440a6",
        "sectorsOfInterest": [
          "B",
          "A"
        ],
        "areasOfInterest": [
          "L",
          "D",
          "J"
        ],
        "mxStateCode": "SP",
        "projectTypes": [
          "support",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Tue Feb 05 2019 20:33:53 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb595284a64c9532c868",
        "sectorsOfInterest": [
          "B",
          "C"
        ],
        "areasOfInterest": [
          "F",
          "A",
          "K"
        ],
        "mxStateCode": "BS",
        "projectTypes": [
          "need"
        ],
        "accountType": "2L",
        "eventType": "",
        "createdAt": "Mon Jul 01 2019 22:43:55 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5964e6641ad131ac90",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "B",
          "F"
        ],
        "mxStateCode": "TS",
        "projectTypes": [
          "offer",
          "need"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Fri Nov 02 2018 16:34:05 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59b8f1c1568c4fa7df",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "B",
          "B"
        ],
        "mxStateCode": "QR",
        "projectTypes": [
          "support",
          "offer",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Mon Aug 26 2019 06:54:42 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb594dae314549bd2bfd",
        "sectorsOfInterest": [
          "C",
          "B"
        ],
        "areasOfInterest": [
          "J"
        ],
        "mxStateCode": "NL",
        "projectTypes": [
          "need",
          "support",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Mon Jan 29 2018 06:27:48 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb591b9f39446e471fa6",
        "sectorsOfInterest": [
          "B",
          "C",
          "B"
        ],
        "areasOfInterest": [
          "I",
          "L"
        ],
        "mxStateCode": "DG",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Mon Mar 19 2018 22:10:44 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb591781dd16580ae93f",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "L"
        ],
        "mxStateCode": "VZ",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Sat Nov 16 2019 19:03:23 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5979be795d78589d3b",
        "sectorsOfInterest": [
          "C",
          "B",
          "B"
        ],
        "areasOfInterest": [
          "C",
          "A",
          "F"
        ],
        "mxStateCode": "SR",
        "projectTypes": [
          "need",
          "support"
        ],
        "accountType": "2I",
        "eventType": "",
        "createdAt": "Fri Jun 28 2019 13:12:31 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5953b8931304522c2c",
        "sectorsOfInterest": [
          "C",
          "A",
          "C"
        ],
        "areasOfInterest": [
          "B",
          "L"
        ],
        "mxStateCode": "CC",
        "projectTypes": [
          "support",
          "need",
          "offer"
        ],
        "accountType": "2K",
        "eventType": "",
        "createdAt": "Wed Jan 16 2019 09:58:58 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59360ad48192514305",
        "sectorsOfInterest": [
          "C",
          "A",
          "C"
        ],
        "areasOfInterest": [
          "D",
          "D"
        ],
        "mxStateCode": "MS",
        "projectTypes": [
          "need",
          "support",
          "support"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Mon Jun 18 2018 05:39:49 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59a9b42b27a3233fc6",
        "sectorsOfInterest": [
          "C",
          "A",
          "C"
        ],
        "areasOfInterest": [
          "K",
          "J",
          "K"
        ],
        "mxStateCode": "PL",
        "projectTypes": [
          "need",
          "need"
        ],
        "accountType": "2M",
        "eventType": "",
        "createdAt": "Sun Oct 27 2019 02:06:50 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59ed965347820c1cdc",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "G",
          "B",
          "D"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "offer"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Fri Oct 19 2018 04:40:41 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59073cc26d71779746",
        "sectorsOfInterest": [
          "B"
        ],
        "areasOfInterest": [
          "D"
        ],
        "mxStateCode": "TC",
        "projectTypes": [
          "offer",
          "offer",
          "support"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Thu Jul 18 2019 05:26:51 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59f8d372c6597c2834",
        "sectorsOfInterest": [
          "B",
          "C"
        ],
        "areasOfInterest": [
          "E"
        ],
        "mxStateCode": "NT",
        "projectTypes": [
          "support"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Wed Jan 24 2018 22:32:05 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59fa113153ba31ae73",
        "sectorsOfInterest": [
          "A",
          "A",
          "A"
        ],
        "areasOfInterest": [
          "C",
          "C",
          "A"
        ],
        "mxStateCode": "MS",
        "projectTypes": [
          "offer",
          "support",
          "support"
        ],
        "accountType": "2D",
        "eventType": "",
        "createdAt": "Wed Jul 17 2019 17:55:12 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59a0764c86d58288e7",
        "sectorsOfInterest": [
          "B",
          "B"
        ],
        "areasOfInterest": [
          "J"
        ],
        "mxStateCode": "CL",
        "projectTypes": [
          "need",
          "offer"
        ],
        "accountType": "2B",
        "eventType": "",
        "createdAt": "Sat Jun 01 2019 08:37:27 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb597a631ff52b3a00d8",
        "sectorsOfInterest": [
          "A",
          "A"
        ],
        "areasOfInterest": [
          "E"
        ],
        "mxStateCode": "PL",
        "projectTypes": [
          "offer",
          "need",
          "support"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Mon Aug 27 2018 12:07:28 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59e41f5242582fa0e8",
        "sectorsOfInterest": [
          "A",
          "C",
          "A"
        ],
        "areasOfInterest": [
          "E"
        ],
        "mxStateCode": "CL",
        "projectTypes": [
          "need",
          "support"
        ],
        "accountType": "2A",
        "eventType": "",
        "createdAt": "Mon Jun 10 2019 22:13:50 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59821b31beb7991415",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "H",
          "L"
        ],
        "mxStateCode": "QR",
        "projectTypes": [
          "support"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Fri Sep 13 2019 11:58:12 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb590d9579aff8e944d4",
        "sectorsOfInterest": [
          "B",
          "A"
        ],
        "areasOfInterest": [
          "D",
          "J"
        ],
        "mxStateCode": "CS",
        "projectTypes": [
          "need"
        ],
        "accountType": "2H",
        "eventType": "",
        "createdAt": "Thu Aug 30 2018 22:52:26 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb597f5fc6169e85c32d",
        "sectorsOfInterest": [
          "C",
          "B"
        ],
        "areasOfInterest": [
          "A",
          "E"
        ],
        "mxStateCode": "MN",
        "projectTypes": [
          "need",
          "support",
          "offer"
        ],
        "accountType": 1,
        "eventType": "",
        "createdAt": "Tue Jan 22 2019 04:10:13 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb599d005925389ae241",
        "sectorsOfInterest": [
          "C"
        ],
        "areasOfInterest": [
          "K",
          "L",
          "K"
        ],
        "mxStateCode": "CC",
        "projectTypes": [
          "offer",
          "need"
        ],
        "accountType": "2C",
        "eventType": "",
        "createdAt": "Tue Aug 21 2018 16:10:11 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb59759ecc79b7ee7543",
        "sectorsOfInterest": [
          "C",
          "A",
          "B"
        ],
        "areasOfInterest": [
          "G"
        ],
        "mxStateCode": "CM",
        "projectTypes": [
          "offer",
          "need",
          "support"
        ],
        "accountType": "2G",
        "eventType": "",
        "createdAt": "Wed Jul 11 2018 01:03:53 GMT+0000 (UTC)"
      },
      {
        "id": "5dfffb5990cb7a841e913e47",
        "sectorsOfInterest": [
          "A"
        ],
        "areasOfInterest": [
          "K",
          "E",
          "H"
        ],
        "mxStateCode": "SP",
        "projectTypes": [
          "offer",
          "support"
        ],
        "accountType": "2E",
        "eventType": "",
        "createdAt": "Fri Aug 24 2018 17:24:23 GMT+0000 (UTC)"
      }
    ];
    return mockData;
  }
}


