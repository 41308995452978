import React from 'react';
import Login from "./login/Login";
import {Route, Switch} from "react-router";
import Discover from "./discover/Discover";
import Register from './Register/Register';
import Info from './Info/Info';
import About from './About/About';
import Help from './Help/Help';
import Trends from './Trends/Trends';

const Public = (props: any) => {
  return (
    <Switch>
      <Route path={props.match.path + "/info"} component={Info}/>
      <Route path={props.match.path + "/about"} component={About}/>
      <Route path={props.match.path + "/help"} component={Help}/>
      <Route path={props.match.path + "/login"} component={Login}/>
      <Route path={props.match.path + "/register"} component={Register}/>
      <Route path={props.match.path + "/discover"} component={Discover}/>
      <Route path={props.match.path + "/trends"} component={Trends}/>
    </Switch>
  )
};
export default Public;
