import {IonCard, IonIcon} from "@ionic/react";
import styles from "./MarketReportCard.module.css";
import React from "react";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
// import {ProjectApi} from "../../services/ProjectService"
import {withAuthInfo} from "../../services/UserInfo";
import {UploadedFileApi} from "../../services/UploadedFileService";

const MarketReportCard = (props: any) => {

    if (!props.marketReport) {
        return null;
    }

    // let projectTypeData = ProjectApi.getProjectTypeData(props.marketReport.type);

    // let projectTypeStyle = {
    //   backgroundColor: projectTypeData.backgroundColor,
    //   borderColor: projectTypeData.color,
    //   color: projectTypeData.color
    // };
    //
    // let projectIconStyle = {
    //   backgroundColor: projectTypeData.color,
    //   backgroundImage: `url(${projectTypeData.iconPath})`
    // };

    let linkTo = '';

    if (props.marketReport._file) {
        linkTo = UploadedFileApi.getFileUrl(props.marketReport._file)
    } else if (props.marketReport.externalUrl) {
        linkTo = '/public/login';
    }


    return (
        <div className={styles['wrapper']}>
            <a href={linkTo} target={'_blank'}>
                <IonCard class={styles['project-card']}>
                    <img className={styles['project-image']}
                         src={UploadedFileApi.getImageUrl(props.marketReport._image)} alt={props.marketReport.title}/>
                    <div className={styles['overlay']}>
                        <h2 lang="es" className={styles['project-title']}>{props.marketReport.title ||
                        <i>Sin título</i>}</h2>

                        <p lang="es" className={styles['description']}>
                            {props.marketReport.description}
                        </p>
                    </div>
                </IonCard>
            </a>
          {props.marketReport.readMoreUrl && props.marketReport.readMoreUrl.length && <a className={styles['read-more']} href={props.marketReport.readMoreUrl} target={'_blank'}>
                Ver más
            </a>}
          {(!props.marketReport.readMoreUrl || !props.marketReport.readMoreUrl.length) && <div className={styles['read-more-placeholder']}></div>}
        </div>
    )
};

export default withAuthInfo(withRouter(MarketReportCard));
