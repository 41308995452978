import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import {AppUserApi} from "../../../../shared/services/AppUserService";
import SharedContext from "../../../../shared/context/SharedContext";
import {AgGridReact} from "ag-grid-react";
import {AppUser, Role} from "../../../../shared/interfaces/loopback";
import AlertService from "../../../../shared/services/AlertService";
import ToastService from "../../../../shared/services/ToastService";
import columnTypes from '../../../../shared/utils/columnTypes';
import {withRouter} from "react-router";

const ActionsCell = (props:any) => {

  const deleteAppUser = (appUser:AppUser|any) => {
    return AppUserApi.deleteById(appUser.id)
      .then(_ => {
        ToastService({
          message: `El usuario con email ${appUser.email} fue eliminado`,
        });
        // remove row from agGrid
        props.api.updateRowData({ remove: [props.data] });
      })
      .catch(err => {
        console.log(err.statusCode);
      });
  };

  const confirmDeleteAppUser = (appUser:AppUser|any) => {
    AlertService({
      header: 'Eliminar usuario',
      message: `¿Desea eliminar al usuario <b>${appUser.email}</b>?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Eliminar',
          handler: () => {
            deleteAppUser(appUser);
          }
        }
      ]
    });
  };

  const promptEdit = (id:string) => {
    AlertService({
      header: 'Editar información',
      message: '¿Deseas editar información básica (nombre, correo electrónico, contraseña) o la información del perfil?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Información básica',
          handler: () => {
            console.log('props', props);
            props.history.push(`/private/admin/user-manager/edit/${id}`)
          }
        },
        {
          text: 'Perfil',
          handler: () => {
            props.history.push(`/private/profile/edit/${id}`)
          }
        }
      ]
    })
  };

  return <>
    <IonButton size="small" href={'/private/profile/view/' + props.value}>
      <IonIcon name={'eye'}/>
    </IonButton>
    <IonButton size="small" onClick={() => promptEdit(props.value)}>
      <IonIcon name={'create'}/>
    </IonButton>
    <IonButton color={'danger'} size="small" onClick={() => confirmDeleteAppUser(props.data)}>
      <IonIcon name={'trash'}/>
    </IonButton>
  </>;
};


const Table = (props: any) => {
  const sharedContext = useContext(SharedContext);
  const [appUsers, setAppUsers] = useState<AppUser[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  useEffect(() => {
    sharedContext.updateHeaderTitle('Usuarios');
  }, [sharedContext]);

  useEffect(() => {
    AppUserApi.find({include: 'roles'})
      .then(setAppUsers);
  }, []);

  const columnDefs:any =
    [
      {
        headerName: 'Nombre', field: 'firstName', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Apellidos', field: 'lastName', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Email', field: 'email', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Último acceso', field: 'lastAccess', sortable: true, type: 'shortDate'
      },
      {
        headerName: 'Roles', field: 'roles', sortable: true, filter: 'agTextColumnFilter',
        cellRenderer: (params:any) => {
          return params.value
            .filter((role:Role) => role.name !== 'generalUser')
            .map((role:Role) => role.friendlyName)
            .join(', ') || 'Usuario general'
        }
      },
      // {
      //   headerName: 'Fecha de creación', field: 'createdAt', sortable: true, filter: 'agDateColumnFilter',
      //   cellRenderer: (params:any) => moment(params.value).format('L')
      // },
      {
        headerName: 'Fecha de creación', field: 'createdAt', type: 'shortDate'
      },
      {
        headerName: 'Tipo de cuenta', field: 'profileData.accountType', sortable: true, filter: 'agTextColumnFilter',
        cellRenderer: (params:any) => params.value === 2? 'Individual': 'Institucional'
      },
      {
        headerName: 'Tipo de institución', field: 'friendlyProfileData.companyType.es.value', sortable: true, filter: 'agTextColumnFilter',
        cellRenderer: (params:any) => params.value
      },
      {
        headerName: 'Empresa', field: 'profileData.companyName', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: '',
        field: 'id',
        cellRendererFramework: ActionsCell,
        cellRendererParams: {history: props.history}
      }
    ];

  const gridReady = (data:any) => {
    console.log(data);
    setGridApi(data.api);
  }

  const exportCsv = () => {
    gridApi.exportDataAsCsv({
      processCellCallback: (params:any) => {
        if(params.column.colId === 'roles') {
          console.log(params);
          return params.value
            .filter((role:Role) => role.name !== 'generalUser')
            .map((role:Role) => role.friendlyName)
            .join(', ') || 'Usuario general'
        } else {
          return params.value
        }

      }
    })
  }

  return (
    <div className="full-height ag-theme-material" style={{width: '100%', height: '100%'}}>
      <h1 className={'section-title'}>
        Administración de usuarios
        <IonButton style={{float: 'right'}} onClick={() => exportCsv()} size="small">Descargar CSV</IonButton>
      </h1>
      <AgGridReact
        columnTypes={columnTypes}
        floatingFilter={true}
        suppressCellSelection={true}
        rowSelection="true"
        columnDefs={columnDefs}
        onGridReady={gridReady}
        rowData={appUsers}>
      </AgGridReact>
    </div>
  );
};
export default Table;



