import {useContext} from 'react';
//context
import SharedContext from './context/SharedContext'

export const breakpoints = ['xs','sm', 'md','lg','xl'];

export default class SharedFunctions {
    static isActiveBpGraterThan = (comparision: string) =>{
        const sharedContext = useContext(SharedContext);
        const activeIndex =  breakpoints.findIndex(breakPoint => sharedContext.activeBreakpoint === breakPoint);
        const comparisionIndex =  breakpoints.findIndex(breakPoint => comparision === breakPoint);
        return activeIndex > comparisionIndex;
    };
    static isActiveBpLessThan = (comparision: string) =>{
        const sharedContext = useContext(SharedContext);
        const activeIndex =  breakpoints.findIndex(breakPoint => sharedContext.activeBreakpoint === breakPoint);
        const comparisionIndex =  breakpoints.findIndex(breakPoint => comparision === breakPoint);
        return activeIndex < comparisionIndex;
    };
    static getProperty( propertyName: string, object: any ) {
        if(!propertyName.includes('.')) return object[propertyName];

        let undefinedFlag = false;
        var parts = propertyName.split( "." ),
          i,
          property = object || this;

        for ( i = 0; i < parts.length; i++ ) {
            property = property[parts[i]];
            if(!property && i !== parts.length-1) {
                undefinedFlag = true;
                break;
            }
        }

        return undefinedFlag ? undefined : property;
    }
}
