import axios, {AxiosPromise} from 'axios';
import UserInfo from "./UserInfo";


export class RoleMappingApi {
  static RESOURCE_URL = '/RoleMappings/';

  static create(data: any): AxiosPromise {
    return axios.post(`${RoleMappingApi.RESOURCE_URL}`,
      data,
      {
        headers: {...UserInfo.baseHeaders()}
      }
    )
  }

}


