import React, {useContext, useEffect, useState} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import SharedContext from "../../../shared/context/SharedContext";
import {AgGridReact} from "ag-grid-react";
import {FriendRequest, Role} from "../../../shared/interfaces/loopback";
import columnTypes from '../../../shared/utils/columnTypes';
import {FriendshipApi} from "../../../shared/services/FriendshipService";

const ActionsCell = (props:any) => {

  return <>
    {/*<IonButton size="small" href={'/private/profile/view/' + props.value}>*/}
    {/*  <IonIcon name={'eye'}/>*/}
    {/*</IonButton>*/}
    {/*<IonButton size="small" href={'/private/profile/edit/' + props.value}>*/}
    {/*  <IonIcon name={'create'}/>*/}
    {/*</IonButton>*/}
    {/*<IonButton color={'danger'} size="small" onClick={() => confirmDeleteAppUser(props.data)}>*/}
    {/*  <IonIcon name={'trash'}/>*/}
    {/*</IonButton>*/}
  </>;
};


const Table = (props: any) => {
  const sharedContext = useContext(SharedContext);
  // const [appUsers, setAppUsers] = useState<AppUser[]>([]);
  const [gridApi, setGridApi] = useState<any>();

  const [friendships, setFriendships] = useState<FriendRequest[]>([]);

  useEffect(() => {
    sharedContext.updateHeaderTitle('Usuarios');
  }, [sharedContext]);

  useEffect(() => {
    FriendshipApi.find({include: ['appUser1', 'appUser2']})
      .then((friendships:any) => {
        // setAppUsers([])
        setFriendships(friendships);
        console.log('friendships', friendships);
      });
  }, []);

  const columnDefs:any = [
    {
      headerName: 'Nombre creador', field: 'appUser1.firstName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Apellidos creador', field: 'appUser1.lastName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Email creador', field: 'appUser1.email', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Nombre invitado', field: 'appUser2.firstName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Apellidos invitado', field: 'appUser2.lastName', sortable: true, filter: 'agTextColumnFilter'
    },
    {
      headerName: 'Email invitado', field: 'appUser2.email', sortable: true, filter: 'agTextColumnFilter'
    },
    // {
    //   headerName: 'Fecha de creación', field: 'createdAt', sortable: true, filter: 'agDateColumnFilter',
    //   cellRenderer: (params:any) => moment(params.value).format('L')
    // },
    {
      headerName: 'Fecha de creación', field: 'createdAt', type: 'shortDate'
    },
    {
      headerName: '',
      field: 'id',
      cellRendererFramework: ActionsCell
    }
  ];

  const gridReady = (data:any) => {
    console.log(data);
    setGridApi(data.api);
  }

  const exportCsv = () => {
    gridApi.exportDataAsCsv({
      processCellCallback: (params:any) => {
        if(params.column.colId === 'roles') {
          console.log(params);
          return params.value
            .filter((role:Role) => role.name !== 'generalUser')
            .map((role:Role) => role.friendlyName)
            .join(', ') || 'Usuario general'
        } else {
          return params.value
        }

      }
    })
  }

  return (
    <div className="full-height ag-theme-material" style={{width: '100%', height: '95%'}}>
      <h1 className={'section-title'}>
        Vinculaciones
        <IonButton style={{float: 'right'}} onClick={() => exportCsv()} size="small">Descargar CSV</IonButton>
      </h1>
      <AgGridReact
        columnTypes={columnTypes}
        floatingFilter={true}
        suppressCellSelection={true}
        rowSelection="true"
        onGridReady={gridReady}
        columnDefs={columnDefs}
        rowData={friendships}>
      </AgGridReact>
    </div>
  );
};
export default Table;



