// imports
import React, {useState, useEffect, useContext} from 'react';
import {
  IonButton,
  IonCheckbox,
  IonContent,
} from "@ionic/react";
// @ts-ignore
import qs from "qs";

// styles


import GridList from "../../shared/components/GridList/GridList";
import SharedContext from "../../shared/context/SharedContext";

import styles from "./Trends.module.css";
import MarketReportCard from "../../shared/components/MarketReportCard/MarketReportCard";
import {MarketReportApi} from "../../shared/services/MarketReportService";

const Discover = (props: any) => {
  const sharedContext = useContext(SharedContext);

  const [marketReports, setMarketReports] = useState<any>([]);

  //Get FriendRequests
  useEffect(() => {
    sharedContext.updateHeaderTitle(`Explorar`);

  }, [sharedContext]);


  const renderMarketReports = (element: any, index: number) => {
    return (
      <MarketReportCard
        marketReport={element}
        key={element.id}/>
    );
  };





  // setCheckedAreas(checkedAreas);

  //Get Featured Profiles
  useEffect(() => {
    MarketReportApi.find({}).then(setMarketReports);
    // setMarketReports([
    //   {
    //     "id": "1",
    //     "title": "2025 Vision: Future of Pumps in a Connected World",
    //     "description": "Innovative business models with services as a central theme is expected to define competitiveness and unlock new revenue streams for the pumps industry.",
    //     "_file": {
    //       "fileName": "report1.pdf",
    //       "containerFileName": "report1.pdf"
    //     }
    //   },
    //   {
    //     "id": "2",
    //     "title": "Industry 4.0 — Adoption Index",
    //     "description": "Where are the End Users?",
    //     "_file": {
    //       "fileName": "report2.pdf",
    //       "containerFileName": "report2.pdf"
    //     }
    //   }
    // ])
  }, []);



  return (
    <IonContent padding class="full-height">
      <h1 className="section-title">Tendencias</h1>

        <h3>Sección patrocinada por</h3>

        <p className={styles['centered-text']}>
            <a className={styles['sponsor-logo-link']} href={'https://ww2.frost.com/'} target={'_blank'} >
                <img src={'/assets/imgs/marketReportsSponsor.png'} alt={'Frost & Sullivan'} className={styles['sponsor-logo']} />
            </a>
        </p>

        <p className={styles['centered-text']}>
            Agradecemos las aportaciones de Frost & Sullivan. <br />
            RED OTT y Frost &amp; Sullivan acuerdan establecer y trabajar en conjunto en una alianza estratégica para
            facilitar a los participantes de la comunidad de LINKME con el análisis de las últimas tendencias
            tecnológicas y de mercado relacionadas con la Industria 4.0.
        </p>
        <br />

      <h3>Reportes</h3>
      <p className={styles['centered-subtitle']}>
        Haz click sobre cada reporte para visualizar y descargar
      </p>

      <p>
        Acceda a los boletines mensuales de Frost &amp; Sullivan con artículos de las últimas tendencias tecnológicas y de mercado.
      </p>

      <GridList

        hideAdd={true}
        list={marketReports}
        renderListElement={renderMarketReports}
      />

      <h2>
        Frost Store
        <a className={styles['external-link']}
           href={'https://store.frost.com/industries/industrial-automation.html?utm_source=LinkmeStore&amp;utm_medium=web&amp;utm_campaign=Linkme'}
           target={'_blank'}>Ingresar</a>
      </h2>

      <p>
        Usted ha ingresado a el área comercial de Frost &amp; Sullivan. Acceda al listado y base completa de
        los últimos reportes de estudios de Mercado de Frost &amp; Sullivan. En caso de estar interesado en
        adquirir un reporte, por favor complete el formulario de consultas.
      </p>
      <p>
        Como miembro de LINKME usted tiene acceso a un 20% de descuento en la adquisición de reportes.
      </p>





      <h2>
        Consultas
        <a className={styles['external-link']} target={'_blank'} href={'https://go.frost.com/MIPC_INDUSTRIALEXPERTS?utm_source=LinkmeLandingpage&amp;utm_medium=web&amp;utm_campaign=Linkme'}>Ingresar</a>
      </h2>

      <p>
        Usted ha ingresado a el área comercial de Frost &amp; Sullivan. Si está interesado en adquirir un
        reporte, subscripción o discutir proyectos de desarrollo que requieran de nuestros servicios de
        consultoría, aquí podrá interactuar con expertos de la industria.
      </p>

      <br />
      <br />
      <br />
      <br />
      <br />











    </IonContent>
  );
};
export default Discover;
