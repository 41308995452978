// import {IonAnchorAttributes} from "@ionic/core/dist/types/components";

// import {PopoverOptions} from "@ionic/react/dist/components/IonPopover";
import {Components} from "@ionic/core";

class PopoverPage {

  message = '';
  pop: any;

  constructor(navParams: any) {
    this.message = navParams.message;
    this.pop = navParams.popoverController;
  }

  public close = () => {
    this.pop.dismiss();
  }

}

export class PopoverButtonComponent {

  constructor(private pop: any) {
  }

  showPopover(event: Event): Promise<void> {
    return this.pop.create({
      component: PopoverPage, ev: event,
      componentProps: {message: 'message', popoverController: this.pop}
    })
      .then((popover:any) => popover.present());
  }
}


export default async function PopoverService(popoverConfig:Components.IonPopoverAttributes) {

  const defaultConfig:any = {

  };

  const mergedConfig = Object.assign({}, defaultConfig, popoverConfig);

  const popoverController:any = document.querySelector('ion-popover-controller');
  await popoverController.componentOnReady();

  const popover = await popoverController.create(popoverConfig);
  return await popover.present();
}
