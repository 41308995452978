// imports
import React, {useContext, useEffect, useRef} from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid, IonIcon,
  IonRow
} from "@ionic/react";
import styles from './About.module.css';
import classes from '../../shared/utils/classes';
import {withAuthInfo} from '../../shared/services/UserInfo';
import SharedContext from "../../shared/context/SharedContext";
import Slider from 'react-slick';
import SharedFunctions from "../../shared/SharedFunctions";




const Login = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const refIsMounted = useRef(true);

  useEffect(() => {
    sharedContext.updateHeaderTitle(`Home`);
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, [sharedContext]);




  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  // const authInfo = UserInfo.getAuthInfo();

  // console.log('authInfo', authInfo);

  return (
    <IonContent style={{height: '100%'}} class={classes('full-height', styles['about-content'])}>

      <h1 className="section-title">Nosotros</h1>

      <div className={styles['section']}>
        <div className={styles['section-content']}>
          <h2>¿Qué es?</h2>
          <p className={styles['center']} style={{color: '#1b4052'}}>
            <strong>LINKME</strong> es una plataforma digital interactiva para impulsar la transferencia tecnológica en México
            mediante la articulación de demandas tecnológicas y ofertas tecnológicas aplicadas a la industria 4.0 en sectores
            económicos específicos. <strong>LINKME</strong> busca ser un mecanismo permanente para la vinculación efectiva entre
            los generadores de tecnología, mercado, sector productivo y tomadores de decisión en política pública.
          </p>
        </div>
      </div>
      <div className={styles['section'] + ' ' + styles['section-blue']}>
        <div className={styles['section-content']}>
          <h2>¿A quién va dirigido?</h2>
          <p className={styles['center']} style={{color: '#1b4052', fontSize: '15px'}}>
            Se dirige principalmente a 8 actores del ecosistema de innovación, que si bien cada uno tiene un perfil predominante
            por su naturaleza, todos pueden realizar acciones de ofertar, demandar y apoyar.
          </p>
          <IonGrid>
            <IonRow>
              <IonCol class={styles['type-column']}>
                <h2>Demandantes</h2>
                <img src={'/assets/imgs/home-need.png'} alt={'Demandantes'} />
                <div className={styles['type-box']}>
                  <div>Textiles</div>
                  <div>Moldes y troqueles</div>
                  <div>TIC's</div>
                </div>
              </IonCol>
              <IonCol class={styles['type-column']}>
                <h2>Ofertantes</h2>
                <img src={'/assets/imgs/home-offer.png'} alt={'Ofertantes'} />
                <div className={styles['type-box']}>
                  <div>OTT's</div>
                  <div>CPI's</div>
                  <div>IES</div>
                </div>
              </IonCol>
              <IonCol class={styles['type-column']}>
                <h2>Habilitadores</h2>
                <img src={'/assets/imgs/home-support.png'} alt={'Habilitadores'} />
                <div className={styles['type-box']}>
                  <div>Fondos de inversión</div>
                  <div>Incubadoras y aceleradoras</div>
                  <div>OTT's</div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>

      <div className={styles['section']}>
        <div className={styles['section-content']}>
          <h2>¿Qué puedo hacer en la plataforma?</h2>
          <p className={styles['center']} style={{color: '#1b4052', fontSize: '15px'}}>
            <strong>LINKME</strong> ofrece diferentes accesos dependiendo del usuario:
          </p>
          <IonGrid>
            <IonRow>
              <IonCol size={'12'} sizeMd={'6'} class={styles['user-column']}>
                <img src={'/assets/imgs/registrado.png'} alt={'Usuario registrado'} />
              </IonCol>
              <IonCol size={'12'} sizeMd={'6'} class={styles['user-column']}>
                <img src={'/assets/imgs/visitante.png'} alt={'Visitante'} />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>

      {/*<img src={'/assets/imgs/nosotros-bg.png'} style={{pointerEvents: 'none'}} />*/}

      <div className={styles['section']}>
        <div className={styles['section-content']}>
          <IonGrid>
            <IonRow>
              <IonCol size={'12'} sizeMd={'7'} class={styles['ott-column']} style={{backgroundColor: '#E5EAED'}}>
                <h3>¿Para qué?</h3>
                <p>
                  De acuerdo con el reporte “Crafting the Future: A Road Map for Industry 4.0 in Mexico”, publicado en 2016 por la Secretaría de Economía, México no es reconocido como un líder de innovación en este sector a pesar de contar con bienes de alta y media tecnología. Además, cuenta con importantes deficiencias en términos de capacidad de innovación, gastos en investigación y desarrollo de las empresas, procuramiento del gobierno para la adquisición de tecnología avanzada y disponibilidad de talento.
                </p>
                <p>
                  En el reporte de Competitividad Global 2017-2018, presentado por el World Economic Forum, se posiciona a México en el puesto número 51 en el ranking de Índice de Competitividad Global, en donde se evalúan 12 pilares, siendo la innovación uno de ellos. Como parte de las recomendaciones otorgadas por el World Economic Forum, se observó la necesidad de impulsar la innovación, incentivar la adopción de tecnologías en las empresas y la transferencia de tecnología.
                </p>
                <p>
                  La plataforma LinkMe facilita las actividades de transferencia de tecnología al ser una herramienta confiable y dinámica para la vinculación entre los actores del Ecosistema Nacional de Ciencia, Tecnología e Innovación. A través de las interacciones entre los diversos actores que la componen, esta plataforma busca incrementar la integración de tecnología 4.0 en México, así como mejorar en los indicadores de innovación a nivel país.
                </p>
              </IonCol>
              {!SharedFunctions.isActiveBpLessThan('md') && <IonCol size={'12'} sizeMd={'5'} class={styles['logo-column'] + ' ion-align-items-stretch'} style={{padding: '0px 0px 0 30px'}}>
                <img src={'/assets/imgs/placeholder1.png'} alt={''} />
                <img src={'/assets/imgs/placeholder2.png'} alt={''} style={{marginTop: '15px'}} />
              </IonCol>}
            </IonRow>
          </IonGrid>
        </div>
      </div>

      <div className={styles['section']}>
        <div className={styles['section-content']}>
          <IonGrid>
            <IonRow>
              <IonCol size={'12'} sizeMd={'5'} class={styles['logo-column'] + ' ion-align-self-center'}>
                <img src={'/assets/imgs/logo.png'} alt={'redOTT'} />
              </IonCol>
              <IonCol size={'12'} sizeMd={'7'} class={styles['ott-column']}>
                <h3>La Red de Oficinas de Transferencia de Tecnología de México</h3>
                <p>
                  En 2012 se constituyó una Red de Oficinas de Transferencia Tecnológica nacional que ha estado realizando actividades de vinculación e integración en el ecosistema de innovación en México. En junio de 2017 se formalizó la Red OTT México A.C. (REDOTT), como una asociación civil sin fines de lucro para agrupar a las Oficinas de Transferencia de Tecnología, empresas, organizaciones, profesionistas y emprendedores interesados en apoyar y promover la innovación, comercialización y transferencia de tecnología en México.
                </p>
                <p>
                  La REDOTT tiene como misión contribuir a la sociedad del conocimiento en México, colegiando las actividades de las OTT’s para facilitar su desarrollo y profesionalización, siendo el representante del gremio ante las instancias gubernamentales enfocadas en ciencia, tecnología e innovación impulsando y aportando al desarrollo de las políticas públicas para la innovación. Su visión al 2020 es ser un organismo de referencia, reconocido por oferentes y demandantes de tecnología, debido a su liderazgo al representar a las OTT’s nacionales y formar parte de redes internacionales.
                </p>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>

      <div className={styles['section']}>
        <div className={styles['section-content']}>
          <img src={'/assets/imgs/prosoft.png'} alt={'PROSOFT'} style={{maxHeight: '75px', margin: 'auto', display: 'block'}}/>
          <p className={styles['center']} style={{ fontWeight: '900', textTransform: 'uppercase', color: '#163B47', fontSize: '20px'}}>
            La RedOTT México agradece el apoyo de la Secretaría de Economía y del Programa para el Desarrollo de la Industria de Software y la Innovación (PROSOFT) para el desarrollo de esta plataforma digital interactiva para impulsar la transferencia tecnológica en México.
          </p>

        </div>
      </div>



    </IonContent>
  );
});
export default Login;
