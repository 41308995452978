import {IonCard, IonCardHeader, IonCardTitle} from "@ionic/react";
import styles from "./ProjectTypesChart.module.css";
import React from "react";
import {Pie} from "react-chartjs-2";
import _ from "lodash";

const ProjectTypesChart = (props: any) => {

  if(!props.analyticsEvents) {
    return null;
  }

  const categoriesData = [
    {value: 'offer', label: 'Ofertas', color: '#41bbc5'},
    {value: 'need', label: 'Necesidades', color: '#3dffc4'},
    {value: 'support', label: 'Apoyos', color: '#68c030'}
  ];



  const groupByNested = (data:any, property:string) => {
    return _.groupBy(_.flatMap(data, (datum) => {return _.flatMap(datum[property], (propertyValue) => {return {...datum, [property]: propertyValue}})}), property);
  };

  const countByNested = (data:any, property:string) => {
    const group = groupByNested(data, property);
    return _.mapValues(group, (datum) => datum.length);
  };

  const countCategories = (categories:any) => {
    const countByValue = countByNested(props.analyticsEvents, 'projectTypes');

    return _.map(categories, (category:any) => countByValue[category.value]);

  };

  const data = {
    labels: _.map(categoriesData, 'label'),
    datasets: [
      {
        label: props.title || 'Clasificación',
        backgroundColor: _.map(categoriesData, 'color'),
        data: countCategories(categoriesData)
      }
    ]
  };

  const options = {
    // legend: {
    //   display: false
    // },
    // tooltips: {
    //   callbacks: {
    //     label: (tooltipItem:any) => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
    //     title: () => null,
    //   }
    // },
  };


  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{props.title || 'Clasificación'}</IonCardTitle>
      </IonCardHeader>
      <Pie options={options} data={data}/>
    </IonCard>
  )
};
export default ProjectTypesChart;
