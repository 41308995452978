import React, {useContext, useEffect, useRef, useState} from "react";
import {
  IonButton,
  IonCard, IonCardContent, IonCardHeader, IonCheckbox,
  IonCol, IonGrid, IonIcon, IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";

import styles from './Edit.module.css';

import isEmail from 'validator/lib/isEmail';
import Toast from "../../../../shared/components/toast/Toast";
import {MarketReport, Role} from "../../../../shared/interfaces/loopback";
import SharedContext from "../../../../shared/context/SharedContext";
import FileUploader from "../../../../shared/components/FileUploader/FileUploader";
import {MarketReportApi} from "../../../../shared/services/MarketReportService";
import {UploadedFileApi} from "../../../../shared/services/UploadedFileService";

const New = (props: any) => {
  const sharedContext = useContext(SharedContext);
  // variables
  const [emailError, setEmailError] = useState<boolean>(false);
  const [marketReport, setMarketReport] = useState<MarketReport>({title: '', description: ''});
  const [toastConfig, setToastConfig] = useState<any>({type: '', message: '', isOpen: false});
  const refIsMounted = useRef(true);

  // functions
  useEffect(() => {
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, []);
  useEffect(() => {
    sharedContext.updateHeaderTitle('Editar reporte');
    const id = props.match.params.id;
    if (id) {
      MarketReportApi.findById(id, {})
        .then((marketReport: MarketReport) => {
          console.log(marketReport);
          setMarketReport(marketReport);
        });
    }

  }, [props.match.params.id, sharedContext]);
  // const handleEmail = (e: any) => {
  //   const email = e.target.value;
  //   handleElementSetter('email', email);
  //   setEmailError(!isEmail(email));
  // };
  const handleOnDidDismiss = () => {
    if (refIsMounted.current) {
      setToastConfig({type: '', message: '', isOpen: false})
    }
  };
  const handleElementSetter = (property: string, value: any) => {
    const newMarketReport = {...marketReport};

    // @ts-ignore
    newMarketReport[property] = value;

    setMarketReport(newMarketReport);
  }
  const handleSave = () => {
    const promise = marketReport.id ? MarketReportApi.patch(marketReport) : MarketReportApi.upsert(marketReport);
    promise
      .then(_ => {
        props.history.push({pathname: '/private/admin/marketReports/list'})
      })
  }
  const isFormInvalid = (): boolean => {
    return !marketReport.title;
  }

  return (
    <>
      <IonGrid class="full-height">
        <IonRow class="full-height ion-justify-content-center">
          <IonCol
            size-xs="12"
            size-sm="12"
            size-md="12"
            size-lg="12"
            size-xl="12"
            class="ion-align-self-start">
            <form onSubmit={(e: any) => e.preventDefault()}>
              {marketReport.id && <>
              <IonCard>
                <IonCardHeader>
                  <h3>Documento del reporte</h3>
                </IonCardHeader>
                <IonCardContent>
                  <FileUploader
                    modelApi={MarketReportApi}
                    modelId={marketReport.id}
                    relationName="file"/>
                  {marketReport._file &&
                  <IonButton href={UploadedFileApi.getFileUrl(marketReport._file)}>
                    <IonIcon slot="start" name={'cloud-download'}/>
                    Ver archivo
                  </IonButton>}
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <h3>Imagen de fondo</h3>
                </IonCardHeader>
                <IonCardContent>
                  <FileUploader
                    modelApi={MarketReportApi}
                    modelId={marketReport.id}
                    relationName="image"/>
                  {marketReport._image &&
                  <IonButton href={UploadedFileApi.getImageUrl(marketReport._image)}>
                    <IonIcon slot="start" name={'cloud-upload'}/>
                    Ver imagen
                  </IonButton>}
                </IonCardContent>
              </IonCard>
              </>}
              {!marketReport.id &&
                <IonCard>
                  <IonCardContent>
                    Para agregar archivos es necesario guardar los datos generales del reporte primero
                  </IonCardContent>
                </IonCard>
              }
              <IonCard>
                <IonCardHeader>
                  <h3>Datos generales</h3>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel
                      class="flex-1"
                      position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Título:</IonLabel>
                    <IonInput
                      color="primary"
                      value={marketReport.title}
                      onIonChange={(e: any) => {
                        handleElementSetter('title', e.target.value)
                      }}
                      placeholder="Título"
                      name="name"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel
                      class="flex-1"
                      position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Descripción:</IonLabel>
                    <IonInput
                      color="primary"
                      value={marketReport.description}
                      onIonChange={(e: any) => {
                        handleElementSetter('description', e.target.value)
                      }}
                      placeholder="Descripción"
                      name="name"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel
                        class="flex-1"
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                    >Link externo:</IonLabel>
                    <IonInput
                        color="primary"
                        value={marketReport.readMoreUrl}
                        onIonChange={(e: any) => {
                          handleElementSetter('readMoreUrl', e.target.value)
                        }}
                        placeholder="URL"
                        name="name"
                    />
                  </IonItem>



                </IonCardContent>
                <IonItem>
                  <IonButton
                    slot="end"
                    onClick={handleSave}
                    disabled={isFormInvalid()}
                  >Guardar</IonButton>
                </IonItem>
              </IonCard>


            </form>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Toast
        onDidDismiss={handleOnDidDismiss}
        isOpen={toastConfig.isOpen}
        message={toastConfig.message}
        type={toastConfig.type}/>
    </>
  );
};
export default New;
