import xlsx from 'xlsx';

export class Excel {


  static fromArray(flattenedData:any, config?:any) {
    let defaultConfig = {
      title: 'Indicadores',
      fileName: 'indicadores.xlsx'
    };

    console.log('flattenedData', flattenedData);

    if(config.title && !config.fileName) {
      config.fileName = config.title + '.xlsx';
    }

    config = Object.assign({}, defaultConfig, config);

    if(config.title.length > 31) {
      config.title = config.title.substring(0, 31);
    }

    let wb = xlsx.utils.book_new();

    // let flattenedData = flattenData(data, config);
    let ws = xlsx.utils.aoa_to_sheet(flattenedData);

    xlsx.utils.book_append_sheet(wb, ws, config.title);

    xlsx.writeFile(wb, config.fileName, {cellDates: true});

  }
}
