import {ToastOptions} from "@ionic/react/dist/components/IonToast";

export default async function ToastService(toastConfig:ToastOptions) {

  const defaultConfig:ToastOptions = {
    duration: 2000,
    color: 'success',
    showCloseButton: true,
    closeButtonText: 'Ok'
  };

  const mergedConfig = Object.assign({}, defaultConfig, toastConfig);

  if(mergedConfig.message && !mergedConfig.header) {
    mergedConfig.header = mergedConfig.message;
    delete mergedConfig.message;
  }

  const toastController:any = document.querySelector('ion-toast-controller');
  await toastController.componentOnReady();

  const toast = await toastController.create(mergedConfig);
  return await toast.present();
}
