import axios, {AxiosPromise} from 'axios';
import {LoopBackFilter, Project} from "../interfaces/loopback";
import UserInfo from "./UserInfo";


export class FriendshipApi {
  static RESOURCE_URL = '/Friendships/';

  // static find(filter: LoopBackFilter): AxiosPromise {
  //   return axios.get(`${FriendshipApi.RESOURCE_URL}`, {params: {filter}, headers: {...UserInfo.baseHeaders()}})
  // }

  static find(filter: LoopBackFilter = {}): Promise<Project[]> {
    return axios.get(`${FriendshipApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static destroyById(id: string): AxiosPromise {
    return axios.delete(`${FriendshipApi.RESOURCE_URL}${id}`, {headers: {...UserInfo.baseHeaders()}})
  }
}


