import axios from 'axios';
import {AppUser, LoopBackFilter, Project} from "../interfaces/loopback";
import UserInfo from './UserInfo';

export class AppUserApi {
  static RESOURCE_URL = '/AppUsers/';

  static login(email: string, password: string): Promise<any> {
    let authInfo = {currentUser: {}, accessToken: '', currentUserId: ''}
    return axios.post( `${AppUserApi.RESOURCE_URL}login`, {email, password})
      .then(resp => {
        console.log(resp);
        const {data} = resp;
        axios.defaults.headers.common['Authorization'] = data.id;
        authInfo.accessToken = data.id;
        authInfo.currentUserId = data.userId;
        return this.findById(data.userId, {include: ['roles']})
      })
      .then(currentUser => {
        authInfo.currentUser = currentUser;
        // AuthService.setUser(authInfo.accessToken, currentUser);
        UserInfo.setAuthInfo(authInfo);
        return authInfo;
      })
      .catch(err => Promise.reject(err.response.data.error))
  }

  static logout(): Promise<any> {
    return axios.post( `${AppUserApi.RESOURCE_URL}logout?access_token=${UserInfo.getAuthInfo().accessToken}`, {})
      .then(resp => {
        console.log(resp);
        axios.defaults.headers.common['Authorization'] = null;
        window.location.pathname = '';
        UserInfo.clear();
      })
      .catch(err => Promise.reject(err))
  }

  static validateAccessToken(userId: string, accessToken: string): Promise<any> {
    return axios.post(`${AppUserApi.RESOURCE_URL}validateAccessToken`, {userId, accessToken})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error))
  }

  static find(filter: LoopBackFilter = {}): Promise<AppUser[]> {
    return axios.get(`${AppUserApi.RESOURCE_URL}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static findById(id: string, filter: LoopBackFilter = {}): Promise<AppUser> {
    return axios.get(`${AppUserApi.RESOURCE_URL}${id}`, {params: {filter}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static getUsersByRoles(roleNames: Array<string>, filter: LoopBackFilter = {}): Promise<AppUser[]> {
    return axios.get(`${AppUserApi.RESOURCE_URL}getUsersByRoles`, {params: {filter, roleNames}})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static assignRole(roleName: string, email: string): Promise<any> {
    return axios.post(`${AppUserApi.RESOURCE_URL}assignRole`, {roleName, email})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static revokeRole(roleName: string, email: string): Promise<any> {
    return axios.post(`${AppUserApi.RESOURCE_URL}revokeRole`, {roleName, email})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static deleteById(id: string): Promise<AppUser> {
    return axios.delete(`${AppUserApi.RESOURCE_URL}${id}`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static post(data: AppUser): Promise<AppUser[]> {
    return axios.post(`${AppUserApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static upsert(data: AppUser): Promise<AppUser> {
    return axios.put(`${AppUserApi.RESOURCE_URL}`, data)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static patch(data: AppUser): Promise<AppUser> {
    return axios.patch(`${AppUserApi.RESOURCE_URL}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }
  static patchById(data: AppUser, id: string): Promise<AppUser[]> {
    return axios.patch(`${AppUserApi.RESOURCE_URL}${id}`, data)
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static getRandomUser(count: number, loopbackFilter?: LoopBackFilter): Promise<AppUser[]> {
    return axios.get(`${AppUserApi.RESOURCE_URL}getRandomUsers`, {params: {count: count}})
    .then(resp => {
      return resp.data;
    })
    .catch(err => Promise.reject(err.response.data.error));
  }

  static favoriteProjects(appUserId: string): Promise<Project[]> {
    return axios.get(`${AppUserApi.RESOURCE_URL}${appUserId}/favoriteProjects`)
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }

  static trackPageView(appUserId?: string): any {
    return axios.post(`${AppUserApi.RESOURCE_URL}/trackPageView`, {appUserId})
      .then(resp => {
        return resp.data;
      })
      .catch(err => Promise.reject(err.response.data.error));
  }
}
