import React from "react";
import {AppUser, Role} from "../interfaces/loopback";
import {AppUserApi} from "./AppUserService";

export interface AuthInfo {
  accessToken: string|any;
  currentUser?: AppUser|any;
  currentUserId?: string|any;
}


class UserInfo {

  private propsPrefix = '$redOtt$';

  private propsStored = ['accessToken', 'currentUserId'];

  private storage = localStorage;

  private authInfo:AuthInfo = {
    currentUser: null,
    accessToken: null,
    currentUserId: null,
  };

  private subscribers:any[] = [];

  constructor() {
    this.loadAll();
  }

  public setAuthInfo = (authInfo:AuthInfo) => {
    this.authInfo = authInfo;
    this.saveAll();
    this.subscribers.forEach((subscriber:any) => subscriber());
  };

  public setCurrentUser = (currentUser:AppUser) => {
    this.authInfo.currentUser = currentUser;
    this.authInfo.currentUserId = currentUser.id;
    this.subscribers.forEach((subscriber:any) => subscriber());
  };

  public getAuthInfo = () => this.authInfo;

  public baseHeaders = () => {
    return {
      Authorization: this.authInfo.accessToken
    }
  }

  private getDisplayName(WrappedComponent:React.Component|any) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  }

  private subscribe = (subscriber:any) => {
    this.subscribers.push(subscriber);
    return () => this.unsubscribe(subscriber);
  };

  private unsubscribe = (subscriber:any) => {
    const index = this.subscribers.findIndex(subscriber);
    ~index && this.subscribers.splice(index, 1);
  };

  public withAuthInfo = (Component:any) => {
    const self = this;
    class WithAuth extends React.Component<any, any> {

      static propTypes = Component.propTypes;

      static displayName = `WithAuth(${self.getDisplayName(Component)})`;

      unsubscribe:any = null;

      // private _isMounted = false;

      componentDidMount() {
        // this._isMounted = true;
        this.unsubscribe = self.subscribe(this.forceUpdate.bind(this));
      }

      render() {
        const authInfo = {
          ...self.authInfo,
          isAuth: self.isAuth(),
          isStaff: self.isStaff(),
          isAdmin: self.isAdmin(),
          // isAdmin: false,
          isDataConsultant: self.isDataConsultant(),
          isGeneralUser: self.isGeneralUser()
        };
        const newProps = { ...this.props, authInfo };
        // console.log('withAuthInfo', newProps);
        return <Component {...newProps} />;
      }

      componentWillUnmount() {
        // this._isMounted = false;
        this.unsubscribe();
      }
    };

    // WithAuth.displayName = `WithAuth(${this.getDisplayName(Component)})`;

    return WithAuth;
  };

  public clear = () => {
    this.setAuthInfo({
      currentUser: null,
      accessToken: null,
      currentUserId: null,
    });
  };

  private hasRole = (roleName:string):boolean => {
    if(this.authInfo.currentUser && this.authInfo.currentUser.roles) {
      return !!this.authInfo.currentUser.roles.filter((role:Role) => role.name === roleName).length;
    }
    return false;
  };

  public isAuth = ():boolean => {
    return !!(this.authInfo.currentUser && this.authInfo.currentUser.id);
  };

  public isAdmin = ():boolean => {
    return this.hasRole('admin');
  };

  public isGeneralUser = ():boolean => {
    return this.hasRole('generalUser');
  };

  public isDataConsultant = ():boolean => {
    return this.hasRole('dataConsultant');
  }

  public isStaff = ():boolean => {
    if(this.authInfo.currentUser && this.authInfo.currentUser.roles) {
      return (this.hasRole('generalUser') && this.authInfo.currentUser.roles.length > 1) || this.authInfo.currentUser.roles.length === 2;
    }
    return false;
  };

  public init = () => {
    return new Promise((resolve, reject) => {
          if(this.authInfo.currentUserId && this.authInfo.accessToken) {
            return this.validateToken()
              .then((isValid) => {
                if(!isValid) {
                  this.clear();
                  resolve();
                } else {
                  AppUserApi.findById(this.authInfo.currentUserId, {include:'roles'})
                    .then((appUser) => {

                      // if(appUser.id === '5d01dd5fb4f008833f1137e6') {
                      //   // @ts-ignore
                      //   appUser.roles.pop();
                      // }

                      // console.log(appUser);

                      this.setAuthInfo({...this.authInfo, currentUser: appUser});
                      resolve();
                    })
                    .catch(() => {
                      this.clear();
                      reject();
                    });
                }
              })
              .catch(reject);
          } else {
            resolve();
          }
        });
  };

  public component = () => {
    return this.withAuthInfo((props: any) => {
      return (
        <div>
          {props.children}
        </div>
      );
    });
  };

  private validateToken = () => {
    return AppUserApi.validateAccessToken(this.authInfo.currentUserId, this.authInfo.accessToken);
  };

  private saveAll = () => {
    this.propsStored.forEach((key:any) => {
      // @ts-ignore
      this.save(this.storage, key, this.authInfo[key]);
    });
  };

  private loadAll = () => {
    this.propsStored.forEach((key) => {
      // @ts-ignore
      this.authInfo[key] = this.load(key);
    });
  };

  private save = (storage: Storage, name: string, value: any) => {
    try {
      const key = this.propsPrefix + name;
      if (value == null) value = '';
      this.storage[key] = value;
    } catch (err) {
      // console.log('Cannot access local/session storage:', err);
    }
  };

  private load = (name: string) => {
    const key = this.propsPrefix + name;
    // console.log('load:', key);
    return this.storage[key] || null;
  };

}


// const CurrentUserConsumerComponent = (props: any) => {
//   // console.log('CurrentUserConsumerComponent props', props);
//   //
//   //
//   //
//   // let showContent = true;
//   // if(props.auth && !props.authInfo.currentUser) {
//   //   showContent = false;
//   // }
//   // if('' + props.auth === 'false' &&  props.authInfo.currentUser) {
//   //
//   // }
//   return (props.children((props.authInfo.currentUser as AppUser)) || null);
// };

// CurrentUserConsumerComponent.propTypes = {
//   children: PropTypes.func.isRequired,
//   role: PropTypes.string
// };

const userInfo = new UserInfo();

// export const CurrentUserConsumer:any = userInfo.withAuthInfo(CurrentUserConsumerComponent);

export const withAuthInfo = userInfo.withAuthInfo;

export default userInfo;

