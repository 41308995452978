// imports
import React, {useState, useContext, useEffect, useRef} from 'react';
import {
  IonButton, IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow
} from "@ionic/react";
import styles from './Login.module.css';
import classes from '../../shared/utils/classes';
import {AppUserApi} from "../../shared/services/AppUserService";
import Toast from "../../shared/components/toast/Toast";
import {withAuthInfo} from '../../shared/services/UserInfo';
import {Link} from "react-router-dom";
import SharedContext from "../../shared/context/SharedContext";

const Login = (props: any) => {
  const sharedContext = useContext(SharedContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // toast
  const [toastConfig, setToastConfig] = useState<any>({type: '', message: '', isOpen: false});
  const refIsMounted = useRef(true);

  useEffect(() => {
    sharedContext.updateHeaderTitle(`Iniciar sesión`);
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, [sharedContext]);



  const handleSubmit = () => {
    AppUserApi.login(email, password)
      .then(authInfo => {
        // setToastConfig({type: 'success', message: `Bienvenido`, isOpen: true});
        const redirectLink = authInfo.currentUser.profileData.isComplete ? '/private/dashboard' : '/private/profile/edit';
        props.history.push(redirectLink);

      })
      .catch(err => {
        if (err.statusCode === 401) setToastConfig({
          type: 'error',
          message: `Email o contraseña no válidos`,
          isOpen: true
        });
        if (err.statusCode === 404) setToastConfig({type: 'error', message: `Usuario no registrado`, isOpen: true});
      })
  };
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleOnDidDismiss = () => {
    if (refIsMounted.current) {
      setToastConfig({type: '', message: '', isOpen: false})
    }
  };

  // const authInfo = UserInfo.getAuthInfo();

  // console.log('authInfo', authInfo);

  return (
    <IonContent style={{height: '100%'}} class={classes('full-height', styles['login-content'])}>
      <IonGrid style={{height: '100%'}} class={'full-height ' + styles['bg-overlay']}>
        <IonRow style={{height: '100%'}} class={'full-height ion-justify-content-center'}>
          <IonCol
            size="fixed"
            class="ion-align-self-center">
            <IonCard class={styles['login-card']}>
              <form className={styles['middle-column']}>
                <IonItem class="card-input">
                  <IonLabel position="stacked" color="primary">Email</IonLabel>
                  <IonInput
                    value={email}
                    onInput={(e: any) => setEmail(e.target.value)}
                    onIonChange={(e: any) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </IonItem>
                <IonItem class="card-input">
                  <IonLabel position="stacked" color="primary">Contraseña</IonLabel>
                  <IonInput
                    value={password}
                    onKeyDown={handleKeyDown}
                    onInput={(e: any) => setPassword(e.target.value)}
                    onIonChange={(e: any) => setPassword(e.target.value)}
                    placeholder="Contraseña"
                    type="password"
                    name="password"
                    required
                  />
                </IonItem>
                <p className={styles['pass-recovery']}>¿Olvidaste la contraseña?</p>
                <div className={styles['buttons-wrapper']}>
                  <IonButton
                    onClick={handleSubmit}
                    color="secondary"
                    expand="full"
                    disabled={email === '' || password === ''}>Ingresar</IonButton>
                  <div className={classes('text-center', styles['button-divider'])}>
                    ó
                  </div>
                  <Link to="/public/register">
                    <IonButton
                      color="secondary"
                      expand="full">Registrarme</IonButton>
                  </Link>
                </div>

                {/*<IonButton onClick={() => {*/}
                {/*  UserInfo.setAuthInfo({*/}
                {/*    currentUser: Math.floor(Math.random() * Math.floor(10)),*/}
                {/*    accessToken: Math.floor(Math.random() * Math.floor(10)),*/}
                {/*    currentUserId: Math.floor(Math.random() * Math.floor(10)),*/}
                {/*  })*/}
                {/*}} expand="full">test</IonButton>*/}

                {/*<CurrentUserConsumer>*/}
                {/*  {(currentUser:any) => JSON.stringify(currentUser)}*/}
                {/*</CurrentUserConsumer>*/}

              </form>
            </IonCard>

          </IonCol>
        </IonRow>
      </IonGrid>
      <Toast
        onDidDismiss={handleOnDidDismiss}
        isOpen={toastConfig.isOpen}
        message={toastConfig.message}
        type={toastConfig.type}/>
    </IonContent>
  );
};

Login.displayName = 'Login';

export default withAuthInfo(Login);
