import React, {useContext, useEffect, useState} from "react";
import SharedContext from "../../shared/context/SharedContext";
import {ProjectApi} from "../../shared/services/ProjectService";
import {Project} from "../../shared/interfaces/loopback";
import GridList from "../../shared/components/GridList/GridList";
import ProjectCard from "../../shared/components/ProjectCard/ProjectCard";
import {withAuthInfo} from "../../shared/services/UserInfo";
import {AppUserApi} from "../../shared/services/AppUserService";

const Dashboard = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const [offerList, setOfferList] = useState<Project[]>([]);
  const [needList, setNeedList] = useState<Project[]>([]);
  const [supportList, setSupportList] = useState<Project[]>([]);
  const [draftsList, setDraftsList] = useState<Project[]>([]);
  const [aIList, setAIList] = useState<Project[]>([]);
  const [favoriteProjects, setFavoriteProjects] = useState<Project[]>([]);


  const getProjectList = (appUserId:string) => {
    return ProjectApi.find({where:{appUserId}})
      .then((projects: Project[]) => {
        setOfferList(projects.filter(project => project.type === 'offer' && project.isPublished === true));
        setNeedList(projects.filter(project => project.type === 'need' && project.isPublished === true));
        setSupportList(projects.filter(project => project.type === 'support' && project.isPublished === true));
        setDraftsList(projects.filter(project => project.isPublished === false));
      });
  };

  const getAIProjects = (appUserId:string) => {
    return ProjectApi.getRandom(5, {where:{appUserId:{neq: appUserId}}}).then(setAIList)
  };

  useEffect(() => {
    sharedContext.updateHeaderTitle('Inicio');
    getProjectList(props.authInfo.currentUser.id).then(() => getAIProjects(props.authInfo.currentUser.id));

    AppUserApi.favoriteProjects(props.authInfo.currentUser.id)
      .then((favoriteProjects) => {
        console.log('dashboard favorites', favoriteProjects)
        setFavoriteProjects(favoriteProjects);
      });


  }, [sharedContext, props.authInfo.currentUser]);

 const renderProjects = (element: any, index: number) => {
   return (
      <ProjectCard
        project = {element}
        key = {element.id}/>
   );
 }
  return (
    <div className="full-height" style={{padding: '15px'}}>
      <h1 className="section-title">Mis Fichas</h1>
      <GridList
        listTitle = "Mis Ofertas Tecnológicas"
        linkAdd="/private/project/new/offer"
        list = {offerList}
        noContentMessage="Aún no agregas fichas de este tipo"
        renderListElement = {renderProjects}
      />
      <GridList
        listTitle = "Mis Necesidades Tecnológicas"
        linkAdd="/private/project/new/need"
        noContentMessage="Aún no agregas fichas de este tipo"
        list = {needList}
        renderListElement = {renderProjects}
      />
      <GridList
        listTitle = "Mis Apoyos para Vinculación"
        linkAdd="/private/project/new/support"
        noContentMessage="Aún no agregas fichas de este tipo"
        list = {supportList}
        renderListElement = {renderProjects}
      />
      <GridList
        listTitle = "Mis favoritos"
        noContentMessage="Aún no agregas favoritos"
        list = {favoriteProjects}
        renderListElement = {renderProjects}
      />
      <GridList
        listTitle = "Mis Borradores"
        list = {draftsList}
        noContentMessage="No tienes borradores"
        renderListElement = {renderProjects}
      />
    </div>
  );
});
export default Dashboard;
