import React from "react";
import {Route, Switch} from "react-router";
import UserManager from "./user-manager/UserManager";
import MarketReports from "./MarketReports/MarketReports";
import PendingProjects from './PendingProjects/PendingProjects';
import FriendRequests from './FriendRequests/FriendRequests';
import Friendships from './Friendships/Friendships';

const Admin = (props: any) =>{
  return (
    <>
      <Switch>
        <Route path = {props.match.path + "/marketReports"} component={MarketReports} />
        <Route path = {props.match.path + "/user-manager"} component={UserManager} />
        <Route path = {props.match.path + "/pendingProjects"} component={PendingProjects} />
        <Route path = {props.match.path + "/friendRequests"} component={FriendRequests} />
        <Route path = {props.match.path + "/friendships"} component={Friendships} />
      </Switch>
    </>

  )
}
export default Admin;
