import React, {useContext, useEffect, useState} from "react";
import {IonButton} from "@ionic/react";
import {ProjectApi} from "../../../shared/services/ProjectService";
import SharedContext from "../../../shared/context/SharedContext";
import {AgGridReact} from "ag-grid-react";
import {Project} from "../../../shared/interfaces/loopback";
import AlertService from "../../../shared/services/AlertService";
import ToastService from "../../../shared/services/ToastService";
import columnTypes from '../../../shared/utils/columnTypes';

import PopoverService from "../../../shared/services/PopoverService";

const ActionsCell = (props:any) => {

  const deleteProject = (project:Project|any) => {
    return ProjectApi.deleteById(project.id)
      .then(_ => {
        ToastService({
          message: `El usuario con email ${project.email} fue eliminado`,
        });
        // remove row from agGrid
        props.api.updateRowData({ remove: [props.data] });
      })
      .catch(err => {
        console.log(err.statusCode);
      });
  };

  const confirmDeleteProject = (project:Project|any) => {
    AlertService({
      header: 'Eliminar ficha',
      message: `¿Desea eliminar la ficha <b>${project.data.name}</b>?`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Eliminar',
          handler: () => {
            deleteProject(project);
          }
        }
      ]
    });
  };

  return <>
    <IonButton size="small" href={'/private/project/view/' + props.value}>Ver</IonButton>
    <IonButton size="small" href={'/private/project/edit/' + props.value}>Editar</IonButton>
    <IonButton size="small" onClick={() => confirmDeleteProject(props.data)}>Eliminar</IonButton>
  </>;
};

const handleClick = (event:Event) => {
  PopoverService({
    component: ActionsCell,
    event
  });
};




const PendingProjects = (props: any) => {
  const sharedContext = useContext(SharedContext);
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    sharedContext.updateHeaderTitle('Usuarios');
  }, [sharedContext]);

  useEffect(() => {
    ProjectApi.find({
      include: [
        {
          relation: 'messages',
          limit: 1
        },
        'appUser'
      ]
    })
      .then(setProjects);
  }, []);

  const columnDefs:any =
    [
      {
        headerName: 'Título', field: 'data.name', sortable: true, filter: 'agTextColumnFilter'
      },
      {
        headerName: 'Creador', field: 'appUser', type: 'appUser'
      },
      {
        headerName: 'Tipo', field: 'type', type:'projectType'
      },
      {
        headerName: 'Subtipo', field: 'friendlyData.subtype.es.value', type:'projectType'
      },
      {
        headerName: 'Fecha de creación', field: 'createdAt', type: 'shortDate'
      },
      {
        headerName: 'Fecha último mensaje', field: 'messages[0].createdAt', type: 'shortDate'
      },
      {
        headerName: '',
        field: 'id',
        cellRendererFramework: ActionsCell
      }
    ];

  return (
    <div className="full-height ag-theme-material" style={{width: '100%'}}>
      <IonButton onClick={handleClick}>test</IonButton>
      <AgGridReact
        columnTypes={columnTypes}
        floatingFilter={true}
        suppressCellSelection={true}
        rowSelection="true"
        columnDefs={columnDefs}
        rowData={projects}>
      </AgGridReact>
    </div>
  );
};
export default PendingProjects;



