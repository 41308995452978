import React, {useContext, useEffect, useState} from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader, IonCardSubtitle,
  IonCardTitle,
  IonFab,
  IonFabButton,
  IonIcon
} from "@ionic/react";

import styles from "./View.module.css";
import {AppUserApi} from "../../../shared/services/AppUserService";
import {AppUser, FriendRequest, Friendship, Project} from "../../../shared/interfaces/loopback";
import {FriendshipApi} from "../../../shared/services/FriendshipService";
import {FriendRequestApi} from "../../../shared/services/FriendRequestService";
import {withAuthInfo} from "../../../shared/services/UserInfo";
import SharedContext from "../../../shared/context/SharedContext";
import GridList from "../../../shared/components/GridList/GridList";
import ProjectCard from "../../../shared/components/ProjectCard/ProjectCard";
import {Link} from "react-router-dom";
import _ from "lodash";
import AlertService from "../../../shared/services/AlertService";
import ToastService from "../../../shared/services/ToastService";
import ProfileCard from "../../../shared/components/ProfileCard/ProfileCard";
import {UploadedFileApi} from "../../../shared/services/UploadedFileService";


const View = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const [appUser, setAppUser] = useState<AppUser | any>();
  const [isCompany, setIsCompany] = useState<boolean | any>();
  const [isPerson, setIsPerson] = useState<boolean | any>();
  const [friendship, setFriendship] = useState<Friendship | any>();
  const [friendRequest, setFriendRequest] = useState<FriendRequest | any>();

  const [friendships, setFriendships] = useState<Friendship | any>([]);
  const [friendRequests, setFriendRequests] = useState<FriendRequest | any>([]);
  const [favoriteProjects, setFavoriteProjects] = useState<Project[]>([]);

  const [isFriend, setIsFriend] = useState<boolean>(false);

  const currentUserId = props.authInfo.currentUserId;

  // const appUserId = props.match.params.id === 'me' ? currentUserId : props.match.params.id;
  const appUserId = (props.match.params.id === 'me' ? currentUserId : props.match.params.id) || props.authInfo.currentUserId;

  const isMe = currentUserId === appUserId;

  const promptCreateFriendRequest = () => {
    AlertService({
      header: 'Vincular con usuario',
      message: '¿Deseas enviar una invitación de vinculación?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Continuar',
          handler: () => {
            AppUserApi.logout();
          }
        }
      ]
    })
  };

  useEffect(() => {


    AppUserApi.findById(appUserId, {include: 'projects'}).then((appUser) => {
      setIsCompany(appUser.profileData.accountType === '1');
      setIsPerson(appUser.profileData.accountType === '2');
      // console.log('appUser', appUser);
      // sharedContext.updateHeaderTitle(isMe ? 'Mi Perfil' : `Perfil de ${appUser.firstName}`);
      sharedContext.updateHeaderTitle(renderTitle());
      setAppUser(appUser);
    });
    if (currentUserId !== appUserId) { // is not current user
      FriendshipApi.find({
        where: {
          or: [
            {appUser1Id: currentUserId, appUser2Id: appUserId},
            {appUser2Id: currentUserId, appUser1Id: appUserId}
          ]
        }
      }).then((friendships:any) => {
        console.log(friendships);
        if (friendships.length) {
          setFriendship(friendships[0]);
          setIsFriend(true);
        }

      });

      FriendRequestApi.find({
        where: {or: [
            {requesterId: appUserId, appUserId: currentUserId},
            {requesterId: currentUserId, appUserId: appUserId}
          ]}
      }).then((friendRequests) => {
        if (friendRequests.length) setFriendRequest(friendRequests[0]);
      });
    } else if(currentUserId) { // is current user
      FriendshipApi.find({
        where: {
          or: [
            {appUser1Id: currentUserId},
            {appUser2Id: currentUserId}
          ]
        },
        include: ['appUser1', 'appUser2']
      }).then((friendships) => {
        console.log('friendships current', friendships)
        if(friendships && friendships.length) {
          setFriendships(friendships);
        }
      });

      FriendRequestApi.find({
        where: {or: [
            {appUserId: currentUserId},
            {requesterId: currentUserId}
          ]},
        include: ['appUser', 'requester']
      }).then((friendRequests) => {
        console.log('friendRequests current', friendRequests)
        if (friendRequests && friendRequests.length) {
          setFriendRequests(friendRequests);
        }
      });

      AppUserApi.favoriteProjects(appUserId)
        .then((favoriteProjects) => {
          console.log('profile favorites', favoriteProjects)
          setFavoriteProjects(favoriteProjects);
        });
    }
  }, [appUserId, currentUserId]);

  function removeFriendshipHandler() {
    AlertService({
      header: 'Desvincular',
      message: '¿Deseas eliminar al usuario de tu lista?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Continuar',
          handler: () => {
            FriendshipApi.destroyById(friendship.id).then((response) => {
              console.log(response);
            }).catch((error) => {
              console.error(error);
            })
          }
        }
      ]
    })


  }

  function requestFriendshipHandler() {
    FriendRequestApi.create(appUserId).then((friendRequest) => {
      console.log('friendRequest', friendRequest);
      setFriendRequest(friendRequest);
      ToastService({message: `Invitación enviada`});
    }).catch((error) => {
      console.error(error);
    })
  }

  function answerFriendshipHandler(accept:boolean) {
    FriendRequestApi.answerRequest(friendRequest.id, accept).then((friendship:Friendship) => {
      console.log('friendship', friendship);
      setFriendRequest(null);
      setFriendship(friendship);
      ToastService({message: accept?`Invitación aceptada`: `Invitación cancelada`});
    }).catch((error:any) => {
      console.error(error);
    })
  }

  const renderProjects = (element: any, index: number) => {
    return (
      <ProjectCard
        project={element}
        key={element.id}/>
    );
  };

  const renderAreasOfInterest = (appUser: any) => {
    if (appUser.friendlyProfileData && appUser.friendlyProfileData.areasOfInterest && appUser.friendlyProfileData.areasOfInterest.es.value) {
      return appUser.friendlyProfileData.areasOfInterest.es.value.map((areaOfInterest: string, index: number) =>
        <div key={index} className={styles['profile-area']}>{areaOfInterest}</div>
      )
    }
  };

  const excludedFields = [
    'companyDescription',
    'userCV',
    'areasOfInterest',
    'companyPhone',

    'accountType',
    'companySector1',
    'companySector2',
    'companySector3',
    'specificActivities',
    'referralEmails',
    'goals',
    'otherGoal',
    'profileService',
    'profileDemand',
    'profileSupport',
  ];

  const friendFields = [
    'accountType',
    'companyPosition',
    'professionalDegree',
    // 'companyPhone'
  ];

  const authFields = [
    'companyType',
    'companyName',
    'companyDescription'
  ];

  const renderProfileData = (data: any) => {

    if(!data) {
      return '';
    }

    let filteredData = _.omit(data, excludedFields);

    if(!props.authInfo.isAuth && !props.authInfo.isAdmin) {
      filteredData = _.omit(filteredData, authFields);
    }

    if(!isFriend && !isMe && !props.authInfo.isAdmin) {
      filteredData = _.omit(filteredData, friendFields);
    }


    // filteredData = _.filter(filteredData, (o) => );

    return _.map(filteredData, (datum: any, index: number) =>
      datum.es.value && <div key={index} className={styles['profile-datum']}>
        <div className={styles['profile-datum-label']}>{datum.es.label}</div>
        <div
          className={styles['profile-datum-value']}>{typeof datum.es.value === 'string' ? datum.es.value : datum.es.value.join(', ')}</div>
      </div>
    );
  }

  const renderFriendRequests = (element: any, index: number) => {
    let profile = element.requester.id === currentUserId? element.appUser: element.requester;
    return (
      <ProfileCard
        profile={profile}
        key={profile.id}/>
    );
  };

  const renderFriendships = (element: any, index: number) => {
    let profile = element.appUser1.id === currentUserId? element.appUser2: element.appUser1;
    return (
      <ProfileCard
        profile={profile}
        key={profile.id}/>
    );
  };

  const promptEdit = () => {
    AlertService({
      header: 'Editar información',
      message: '¿Deseas editar tu información básica (nombre, correo electrónico, contraseña) o la información de tu perfil?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Información básica',
          handler: () => {
            props.history.push('/private/profile/editAppUser/' + appUser.id)
          }
        },
        {
          text: 'Perfil',
          handler: () => {
            props.history.push('/private/profile/edit/' + appUser.id)
          }
        }
      ]
    })
  };

  const renderTitle = ():string => {
    if(isMe) {
      if(isCompany) {
        return appUser.profileData.companyName + '(Mi perfil)';
      } else {
        return 'Mi perfil';
      }
    }

    if(isCompany) {
      // return appUser.profileData.companyName;

      if(props.authInfo.isAdmin) {
        // return `Perfil de ${appUser.firstName} ${appUser.lastName}`;
        // return appUser.profileData.companyName + '(Mi perfil)';
        return `${appUser.profileData.companyName} (${appUser.firstName} ${appUser.lastName})`;
      }

      return `${appUser.profileData.companyName}`;

    }

    if(isFriend) {
      return `Perfil de ${appUser.firstName} ${appUser.lastName}`;
    }

    return 'Perfil de usuario';

  };

  return (
    <div>
      {appUser && <div className="full-height" style={{padding: '15px'}}>

        <h1 className={'section-title'}>
          {/*{isMe ? 'Mi perfil' : `Perfil de ${appUser.firstName} ${appUser.lastName}`}*/}
          {renderTitle()}
        </h1>

        <div className={styles['round-card']}>

          <img className={styles['profile-header-image']}
               src={UploadedFileApi.getImageUrl(appUser._avatar)}
               alt="img"/>
          {appUser.profileData.companyDescription && <>
            <h3 className={styles['profile-subheader']}>Descripción general</h3>
            <p className={styles['profile-description']}>
              {appUser.profileData.companyDescription}
            </p>
          </>}
          {appUser.profileData.userCV && <>
            <h3 className={styles['profile-subheader']}>CV</h3>
            <p className={styles['profile-description']}>
              {appUser.profileData.userCV}
            </p>
          </>}

          {renderAreasOfInterest(appUser)}

          <div className={styles['action-buttons']}>

            {friendRequest && friendRequest.requesterId !== props.authInfo.currentUserId && <>
              <div className={styles['action-button']}
                   onClick={() => answerFriendshipHandler(true)}>
                <IonIcon name="checkmark"/> Aceptar invitación
              </div>

              <div className={styles['action-button']}
                   onClick={() => answerFriendshipHandler(false)}>
                <IonIcon name="close"/> Rechazar invitación
              </div>
            </>}

            {friendRequest && friendRequest.requesterId === props.authInfo.currentUserId && <>

              <div className={styles['action-button']}
                   onClick={() => answerFriendshipHandler(false)}>
                <IonIcon name="close"/> Cancelar invitación
              </div>
            </>}

            {!friendRequest && !friendship && !isMe && <>
              <div className={styles['action-button']} onClick={requestFriendshipHandler}>
                <IonIcon name="checkmark"/> Enviar invitación
              </div>
            </>}
            {friendship && <>
              <div className={styles['action-button']} onClick={removeFriendshipHandler}>
                <IonIcon name="close"/> Desconectar
              </div>
            </>}
            {(isMe || props.authInfo.isAdmin) && <>
              <div onClick={() => promptEdit()} className={styles['action-button']}>
                <IonIcon name={'create'}/> Editar
              </div>
            </>}
          </div>
        </div>

        <br/>
        <br/>

        {(isFriend || props.authInfo.isAdmin) && <>
          <div className={styles['profile-subtitle']}>Información de contacto</div>
          <br/>
          <div className={styles['round-card-data']}>
            <div className={styles['profile-datum']}>
              <div className={styles['profile-datum-label']}>Nombre</div>
              <div className={styles['profile-datum-value']}>{appUser.firstName} {appUser.lastName}</div>
            </div>
            <div className={styles['profile-datum']}>
              <div className={styles['profile-datum-label']}>Email</div>
              <div className={styles['profile-datum-value']}>{appUser.email}</div>
            </div>
            {isCompany && appUser.profileData.companyPhone && <div className={styles['profile-datum']}>
              <div className={styles['profile-datum-label']}>Teléfono</div>
              <div className={styles['profile-datum-value']}>{appUser.profileData.companyPhone}</div>
            </div>}
          </div>
          <br/>
          <br/>
        </>}

        <div className={styles['profile-subtitle']}>Información general</div>
        <br/>
        <div className={styles['round-card-data']}>
          {renderProfileData(appUser.friendlyProfileData)}
        </div>
        <br/>
        <br/>

        {isMe && <>

          <br/>
          <GridList
            listTitle = "Solicitudes de vinculación"
            list = {friendRequests}
            noContentMessage="No tienes invitaciones pendientes"
            renderListElement = {renderFriendRequests}
          />

          <br/>
          <GridList
            listTitle = "Vinculaciones aprobadas"
            list = {friendships}
            noContentMessage="No tienes amistades"
            renderListElement = {renderFriendships}
          />

          <GridList
            listTitle = "Mis fichas favoritas"
            noContentMessage="Aún no agregas favoritos"
            list = {favoriteProjects}
            renderListElement = {renderProjects}
          />

        </>}



      </div>}

    </div>);
});

export default View;
