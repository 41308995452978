import {IonCard, IonCardHeader, IonCardTitle} from "@ionic/react";
import styles from "./AccountTypeChart.module.css";
import React from "react";
import {Bar} from "react-chartjs-2";
import _ from "lodash";

const AccountTypeChart = (props: any) => {

  if (!props.analyticsEvents) {
    return null;
  }


  const categoriesData = [
    {label: "Cuenta Individual", value: "2", color: '#58b5e1'},
    {label: "CPI", value: "1A", color: '#39eec0'},
    {label: "IES", value: "1B", color: '#5ba063'},
    {label: "OTT", value: "1C", color: '#c0dea4'},
    {label: "OTT-IES", value: "1D", color: '#79be02'},
    {label: "OTT-CPI", value: "1E", color: '#cc6820'},
    {label: "Empresa Privada/Industria", value: "1F", color: '#f989a4'},
    {label: "Cámara", value: "1G", color: '#f1cbd5'},
    {label: "Centro de Innovación Industrial", value: "1H", color: '#f4327e'},
    {label: "Clúster ", value: "1I", color: '#f2be72'},
    {label: "Incubadora ", value: "1J", color: '#f14330'},
    {label: "Aceleradora", value: "1K", color: '#a07a7c'},
    {label: "Fondo de Inversión", value: "1L", color: '#36f459'},
    {label: "Otra", value: "1M", color: '#818bd7'},
    {label: "Sin cuenta", value: "none", color: '#ab719e'},
  ];

  const countByNested = (data: any, property: string) => {
    const group = _.groupBy(data, property);
    return _.mapValues(group, (datum) => datum.length);
  };

  const countCategories = (categories: any) => {
    const countByValue = countByNested(props.analyticsEvents, 'accountType');

    return _.map(categories, (category: any) => countByValue[category.value]);

  };

  const data = {
    labels: _.map(categoriesData, 'label'),
    datasets: [
      {
        label: props.title || 'Tipo de actor',
        backgroundColor: _.map(categoriesData, 'color'),
        data: countCategories(categoriesData)
      }
    ]
  };

  const options = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem:any) => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
        title: () => null,
      }
    },
  };


  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{props.title || 'Tipo de actor'}</IonCardTitle>
      </IonCardHeader>
      <Bar options={options} data={data}/>
    </IonCard>
  )
};
export default AccountTypeChart;
