import React, {useContext, useEffect} from 'react';
import styles from './Header.module.css';
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonSearchbar,
  IonToolbar
} from "@ionic/react";
import SharedFunctions from '../../SharedFunctions';
import {withRouter} from 'react-router-dom';
import SharedContext from "../../context/SharedContext";
import {withAuthInfo} from '../../services/UserInfo';
import {Link} from "react-router-dom";
import {UploadedFileApi} from "../../services/UploadedFileService";

const Header = (props: any) => {
  const sharedContext = useContext(SharedContext);

  // const currentUser = AuthService.getUser();

  // const currentUser = authContext.authInfo.currentUser;
  // const isAuth = AuthService.getUser();
  // const lessThanlg = sharedFunctions.isActiveBpLessThan('lg');

  function searchChangedHandler (e: any) {
    // console.log(e.detail.value);
    props.history.push('/public/discover?q='+e.detail.value);
  };

  // console.log('authInfo', props.authInfo);

  return (
    <IonHeader>
      <IonToolbar class={styles['header-container']}>
        {SharedFunctions.isActiveBpGraterThan('sm') &&
        <>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false}/>
          </IonButtons>
          <div style={{cursor: 'pointer'}} onClick={() => props.history.push('/public/info')} slot="start" className={styles['logo-wrapper']}>
            <img src={process.env.PUBLIC_URL + '/assets/imgs/logo-complete.png'} width="150px" alt="logo"/>
          </div>
          <div>
            <IonSearchbar placeholder={"Busca, ofrece, haz link..."} class={styles['search-bar']} debounce={500} onIonChange={searchChangedHandler}/>
          </div>

          {!props.authInfo.isAuth && <>
            <IonButtons slot="end">
              <IonButton onClick={() => props.history.push('/public/register')}>Registrarse</IonButton>
            </IonButtons>
            <IonButton
              onClick={() => props.history.push('/public/login')}
              slot="end" color="secondary"> Iniciar Sesión</IonButton>
          </>}

          {props.authInfo.isAuth && <>
            <label slot="end">{props.authInfo.currentUser!['firstName']}</label>
            <IonButtons slot="end">
              <Link to={'/private/profile/view/'+props.authInfo.currentUserId}>
                <IonButton>
                  <IonAvatar slot="end" style={{height: '25px', width: '25px'}}>
                    <img src={UploadedFileApi.getImageUrl(props.authInfo.currentUser._avatar)} alt="Avatar"/>
                  </IonAvatar>
                </IonButton>
              </Link>
            </IonButtons>
          </>}

        </>}
        {SharedFunctions.isActiveBpLessThan('md') &&
        <>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false} />
          </IonButtons>

          <div style={{textAlign: 'center'}}>
            {/*<p>{sharedContext.headerTitle}</p>*/}
            <IonSearchbar placeholder={"Busca, ofrece, haz link..."} class={styles['search-bar']} debounce={500} onIonChange={searchChangedHandler}/>
          </div>
        </>
        }

      </IonToolbar>
    </IonHeader>
  )
};

export default withRouter(withAuthInfo(Header));
