import React, {useState, useEffect, useRef, useContext} from 'react';
import {AppUserApi} from "../../../shared/services/AppUserService";
import {OnboardingForm} from "../../../shared/components/onboarding-form";
import {withAuthInfo} from "../../../shared/services/UserInfo";
import UserInfo from "../../../shared/services/UserInfo";
import SharedContext from "../../../shared/context/SharedContext";
import {QuestionnaireApi} from "../../../shared/services/QuestionnaireService";
import {AppUser, Questionnaire} from "../../../shared/interfaces/loopback";
import ToastService from "../../../shared/services/ToastService";
import AlertService from "../../../shared/services/AlertService";
import {ProjectApi} from "../../../shared/services/ProjectService";
import FileUploader from "../../../shared/components/FileUploader/FileUploader";


const Edit = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const refIsMounted = useRef(true);
  const [profileForm, setProfileForm] = useState<Questionnaire | null>(null);
  const [appUser, setAppUser] = useState<AppUser | any>();

  const currentUserId = props.authInfo.currentUserId;
  const appUserId = (props.match.params.id === 'me' ? currentUserId : props.match.params.id) || props.authInfo.currentUserId;

  console.log('appUserId', appUserId);


  useEffect(() => {
    sharedContext.updateHeaderTitle('Mi Perfil');
    return function ToastCleanUp() {
      refIsMounted.current = false
    };
  }, [sharedContext]);

  useEffect(() => {
    QuestionnaireApi.findOne({where: {name: 'profileData'}})
      .then((questionnaire: Questionnaire) => {
        AppUserApi.findById(appUserId).then((appUser) => {
          setAppUser(appUser);
          const profileData = appUser.profileData;
          questionnaire.values = {...profileData};
          setProfileForm(questionnaire);
        });
      });


  }, [props.authInfo.currentUser.profileData, appUserId]);

  const saveData = ({detail: data}: any) => {
    console.log('handleFormFinished', data);
    const appUserToSave = {...appUser};
    appUserToSave.profileData = data.values;
    appUserToSave.profileData.isComplete = true;
    appUserToSave.friendlyProfileData = data.friendlyValues;

    delete appUserToSave.roles;
    AppUserApi.patch(appUserToSave)
      .then((appUser) => {
        ToastService({message: `Los datos han sido actualizados`});
        if(currentUserId === appUser.id) {
          UserInfo.setCurrentUser(appUserToSave);
          props.history.push('/private/dashboard');
        } else {
          props.history.push(`/private/profile/view/${appUser.id}`);
        }



      })
      .catch(err => {
        if (err.statusCode === 401) ToastService({
          color: 'error',
          message: `No cuentas con permisos para la operación`
        });
      })
  };

  const promptSaveInfo = (formData:any) => {
    AlertService({
      header: 'Actualizar información',
      message: '¿Desea guardar los cambios?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Guardar',
          handler: () => {
            saveData(formData)
          }
        }
      ]
    })
  };

  const onImageAssigned = (appUserPromise:Promise<AppUser>) => {
    appUserPromise.then((newAppUser:AppUser) => {
      console.log('image uploaded callback', newAppUser);

      const appUserToSave = {...appUser};
      appUserToSave._avatar = newAppUser._avatar;
      UserInfo.setCurrentUser(appUserToSave);
    });
  };

  return (
    <>
      {profileForm && <>
        <OnboardingForm data={profileForm} onFormHasFinished={promptSaveInfo}>
          <div slot={'control-bar'}>
            {appUser && <FileUploader
              buttonText={'Cargar imagen'}
              modelApi={AppUserApi}
              modelId = {appUser.id}
              relationName = "avatar"
              instructions={'Arrastre una al recuadro o haga click para seleccionarla (jpg, png o gif)'}
              onFileAssigned={onImageAssigned}
            />}
          </div>
        </OnboardingForm>
      </>}
    </>
  );
});
export default Edit;
