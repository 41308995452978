import React from 'react';
import {Redirect, Route, Switch} from "react-router";
import Admin from "./admin/Admin";
import Analytics from "./Analytics/Analytics";
import UserInfo from "../shared/services/UserInfo";
import {withAuthInfo} from "../shared/services/UserInfo";
import Profile from "./profile/Profile";
import Dashboard from "./Dashboard/Dashboard";
import Trends from "./Trends/Trends";
import Project from "./Project/Project";

const Private = withAuthInfo((props: any) => {
  const isAdmin = UserInfo.isAdmin();
  const isDataConsultant = UserInfo.isDataConsultant();
  const profileCompleted = props.authInfo.currentUser.profileData.isComplete;
  return (
    <>
      <Switch>
        {isAdmin && <Route path={props.match.path + "/admin"} component={Admin}/>}
        {(isAdmin || isDataConsultant) && <Route path={props.match.path + "/analytics"} component={Analytics}/>}
        <Route path={props.match.path + "/profile"} component={Profile}/>
        {(profileCompleted || isAdmin) &&
        <>
          <Route path={props.match.path + "/project"} component={Project}/>
          <Route path={props.match.path + "/dashboard"} component={Dashboard}/>
          <Route path={props.match.path + "/trends"} component={Trends}/>
        </>}
        {!profileCompleted &&
        <Redirect from="/" to="/private/profile/edit"/>
        }
      </Switch>
    </>

  )
});
export default Private;
