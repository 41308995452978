import {
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonItem, IonLabel,
  IonList,
  IonMenu, IonMenuButton, IonMenuToggle,
  IonToolbar
} from "@ionic/react";
import React from "react";
import {withRouter} from "react-router";
import {withAuthInfo} from "../../services/UserInfo";
import styles from './Menu.module.css';
import {Link} from "react-router-dom";
import {AppUserApi} from "../../services/AppUserService";
import AlertService from "../../services/AlertService";
import SharedFunctions from "../../SharedFunctions";

const Menu = withAuthInfo((props: any) => {

  let {isAuth, isAdmin, isDataConsultant} = props.authInfo;

  const promptLogout = () => {
    AlertService({
      header: 'Cerrar sesión',
      message: '¿Desea terminar la sesión?',
      buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'Continuar',
        handler: () => {
          AppUserApi.logout();
        }
      }
    ]
    })
  };

  const menuOptions = [
    {visible: isAuth, type: 'link', title: 'Mi perfil', path: '/private/profile/view', icon: 'person'},
    {visible: isAdmin, type: 'link', title: 'Usuarios', path: '/private/admin/user-manager/list', icon: 'contacts'},
    {visible: isAdmin, type: 'link', title: 'Solicitudes de vinculación', path: '/private/admin/friendRequests', icon: 'contacts'},
    {visible: isAdmin, type: 'link', title: 'Vinculaciones', path: '/private/admin/friendships', icon: 'contacts'},
    {visible: isAdmin, type: 'link', title: 'Admin. Tendencias', path: '/private/admin/marketReports/list', customIcon: 'tendencias.png'},
    {visible: true, type: 'link', title: 'Inicio', path: '/public/info', customIcon: 'home.png'},
    {visible: true, type: 'link', title: 'Nosotros', path: '/public/about', customIcon: 'nosotros.png'},
    {visible: true, type: 'link', title: 'Explorar', path: '/public/discover', customIcon: 'explorar.png'},
    {visible: true, type: 'url', title: 'Blog', url: 'https://blog.linkme.mx/category/blog/', customIcon: 'blog.png'},
    {visible: true, type: 'url', title: 'Eventos', url: 'https://blog.linkme.mx/eventos/', customIcon: 'eventos.png'},
    {visible: true, type: 'link', title: 'Tendencias', path: '/public/trends', customIcon: 'tendencias.png'},
    {visible: isAuth && !isDataConsultant, type: 'link', title: 'Mis fichas', path: '/private/dashboard', icon: 'document'},
    {visible: (isAdmin || isDataConsultant), type: 'link', title: 'Indicadores', path: '/private/analytics/interests', icon: 'analytics'},
    {visible: isAuth, type: 'callback', title: 'Cerrar sesión', icon: 'log-out', callback: promptLogout},
    {visible: !isAuth, type: 'link', title: 'Registrarme', path: '/public/register', icon: 'person-add'},
    {visible: !isAuth, type: 'link', title: 'Iniciar sesión', path: '/public/login', icon: 'log-in'},
    {visible: true, type: 'link', title: 'Tutoriales', path: '/public/help', icon: 'help'}
  ];

  const renderMenuOptions = () => {
    return menuOptions.filter(menuOption => menuOption.visible).map((menuOption:any) =>
      <IonMenuToggle key={menuOption.title} auto-hide="false">
        {menuOption.type === 'link' &&
        <Link to={menuOption.path}>
          <IonItem button>
            {menuOption.icon &&  <IonIcon class= {styles.ionIcon} slot="start" name={menuOption.icon}/>}
            {menuOption.customIcon &&  <div className={styles.customIconContainer}><img src={process.env.PUBLIC_URL + `/assets/icons/${menuOption.customIcon}`} width="25px" alt="logo"/></div>}
            <IonLabel>
              {menuOption.title}
            </IonLabel>
          </IonItem>
        </Link>}
        {menuOption.type === 'url' &&
        <a href={menuOption.url} target="_blank" rel="noopener noreferrer">
          <IonItem button>
            {menuOption.icon &&  <IonIcon class= {styles.ionIcon} slot="start" name={menuOption.icon}/>}
            {menuOption.customIcon &&  <div className={styles.customIconContainer}><img src={process.env.PUBLIC_URL + `/assets/icons/${menuOption.customIcon}`} width="25px" alt="logo"/></div>}
            <IonLabel>
              {menuOption.title}
            </IonLabel>
          </IonItem>
        </a>}
        {menuOption.type === 'callback' &&
        <IonItem button onClick={menuOption.callback}>
          <IonIcon slot="start"  class= {styles.ionIcon} name={menuOption.icon}/>
          <IonLabel>
            {menuOption.title}
          </IonLabel>
        </IonItem>}
      </IonMenuToggle>
    );
  };

  return (
    <IonMenu contentId="main" type="overlay" class="main-menu">
      <IonHeader>
        <IonToolbar class={styles.header}>
          <IonButtons slot="start">
            <IonMenuButton autoHide={false}/>
          </IonButtons>
          <div slot="start" className={styles['logo-wrapper']}>
            <img src={process.env.PUBLIC_URL + '/assets/imgs/logo-complete.png'} width="150px" alt="logo"/>
          </div>        </IonToolbar>
      </IonHeader>
      <IonContent class={styles.outerContent}>
        {/*<a href={'http://semtech.mty.itesm.mx/covid19mx'} target={'_blank'}>*/}
        {/*  <img src={'/assets/imgs/covid.png'} />*/}
        {/*</a>*/}
        <IonList lines="none">
          {renderMenuOptions()}
          {SharedFunctions.isActiveBpLessThan('md') &&
          <a href={'https://linkme.mx/privacyPolicy.pdf'} target="_blank" rel="noopener noreferrer">
            <IonItem button>
              <IonLabel>
                Aviso de privacidad
              </IonLabel>
            </IonItem>
          </a>}
        </IonList>
        {!SharedFunctions.isActiveBpLessThan('md') &&
        <a className={styles['privacy-policy']} href={'https://linkme.mx/privacyPolicy.pdf'} target={'_blank'}>
          Aviso de privacidad
        </a>}
      </IonContent>
    </IonMenu>);
});
export default withRouter((Menu as any));
