import React, {useContext, useEffect, useState} from "react";
import {
  IonCard, IonCardContent, IonCardHeader, IonCheckbox,
  IonCol,
  IonContent, IonFab, IonFabButton, IonGrid, IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";

import styles from './View.module.css';

import {AppUserApi} from "../../../../shared/services/AppUserService";
import {AppUser, Role} from "../../../../shared/interfaces/loopback";
import SharedContext from "../../../../shared/context/SharedContext";
import {RoleApi} from "../../../../shared/services/RoleService";

const New = (props: any) => {
  const sharedContext = useContext(SharedContext);
  // variables
  const [appUser, setAppUser] = useState<AppUser>({email: '', name: '', roles: [], profileData:{}});
  const [roles, setRoles] = useState<Role[]>([]);

  // functions

  useEffect(() => {
    sharedContext.updateHeaderTitle('Ver Usuario');
    const id = props.match.params.id;
    if (id) {
      AppUserApi.findById(id, {include: 'roles'})
      .then((user: AppUser) => {
        setAppUser(user);
      });
    }
    RoleApi.get({where: {name: {neq: 'generalUser'}}})
    .then((roles: Role[]) => {
      console.log(roles);
      setRoles(roles);
    })
  }, [sharedContext, props.match.params.id]);

  const hasRole = (roleName: string) => {
    if (!appUser.roles || appUser.roles.length === 0) return false;
    return appUser.roles.findIndex(role => role.name === roleName) !== -1;
  }

  return (
    <>
      <IonContent class="full-height">
        <IonGrid class="full-height">
          <IonRow class="full-height ion-justify-content-center">
            <IonCol
              size-xs="12"
              size-sm="12"
              size-md="12"
              size-lg="12"
              size-xl="12"
              class="ion-align-self-start">
              <form onSubmit={(e: any) => e.preventDefault()}>
                <IonCard>
                  <IonCardHeader>
                    <h3>Usuario</h3>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel
                        class="flex-1"
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                      >Nombre:</IonLabel>
                      <label className={styles['field-value']}>{appUser.firstName || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class="flex-1"
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                      >Apellidos:</IonLabel>
                      <label className={styles['field-value']}>{appUser.lastName || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class="flex-1"
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                      >Correo Electrónico:</IonLabel>
                      <label className={styles['field-value']}>{appUser.email || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class="flex-1"
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}
                      >Contraseña:</IonLabel>
                      <label className={styles['field-value']}>{appUser.password || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="podium"></IonIcon>
                        Compañía:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.companyName || ''}</label>

                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="compass"></IonIcon>
                        Código de Estado:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.mxStateCode || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="compass"></IonIcon>
                        Código de Ciudad:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.mxCity || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="briefcase"></IonIcon>
                        Posición:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.companyPosition || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="logo-buffer"></IonIcon>
                        Área de empresa:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.companyArea || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="call"></IonIcon>
                        Teléfono personal:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.personalPhone || ''}</label>
                    </IonItem>
                    <IonItem>
                      <IonLabel
                        class={"flex-1 " + styles['icon-container']}
                        position={sharedContext.activeBreakpoint === 'xs' ? "stacked" : "fixed"}>
                        <IonIcon class={styles['icon']} name="call"></IonIcon>
                        Teléfono de compañía:</IonLabel>
                      <label className={styles['field-value']}>{appUser.profileData.companyPhone || ''}</label>
                    </IonItem>
                  </IonCardContent>
                </IonCard>
                {/*<IonGrid>*/}
                {/*  <IonRow>*/}
                {/*    <IonCol>*/}
                {/*      <IonCard>*/}
                {/*        <IonCardContent>*/}
                {/*          FileUploader*/}
                {/*        </IonCardContent>*/}
                {/*      </IonCard>*/}
                {/*    </IonCol>*/}
                {/*  </IonRow>*/}
                {/*</IonGrid>*/}
                {appUser.id &&
                <IonCard>
                  <IonCardHeader>
                    <h3>Roles</h3>
                  </IonCardHeader>
                  <IonCardContent>
                    {
                      roles.map((role, index) => {
                        return (
                          <IonItem key={role.id}>
                            <IonLabel>{role.friendlyName}</IonLabel>
                            <IonCheckbox slot="end"
                                         disabled = {true}
                                         checked={hasRole(role.name)}/>
                          </IonItem>)
                      })
                    }
                  </IonCardContent>
                </IonCard>
                }
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            color="primary"
            onClick={() => props.history.push('/private/admin/user-manager/edit/' + appUser.id)}>
            <IonIcon name="create"/>
          </IonFabButton>
        </IonFab>
      </IonContent>

    </>
  );
};
export default New;

