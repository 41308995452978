import {IonCard, IonCardContent, IonCardHeader, IonCardTitle} from "@ionic/react";
import styles from "./AreasOfInterestChart.module.css";
import React from "react";
import {Bar} from "react-chartjs-2";
import _ from "lodash";

const AreasOfInterestChart = (props: any) => {

  if(!props.analyticsEvents) {
    return null;
  }

  const categoriesData = [
    {value: 'A', label: 'Big Data', color: '#41bbc5'},
    {value: 'B', label: 'IoT', color: '#3dffc4'},
    {value: 'C', label: 'Impresión 3D', color: '#68c030'},
    {value: 'D', label: 'La nube', color: '#b0fff9'},
    {value: 'E', label: 'Ciberseguridad', color: '#79acfd'},
    {value: 'F', label: 'Automatización', color: '#f89ade'},
    {value: 'G', label: 'Simulación', color: '#d8fe8d'},
    {value: 'H', label: 'Sistemas int.', color: '#a0b460'},
    {value: 'I', label: 'AR', color: '#f6eefa'},
    {value: 'J', label: 'IA', color: '#f5943b'},
    {value: 'K', label: 'Blockchain', color: '#fcd107'},
    {value: 'L', label: 'Otra', color: '#cfa191'},
  ];



  const groupByNested = (data:any, property:string) => {
    return _.groupBy(_.flatMap(data, (datum) => {return _.flatMap(datum[property], (propertyValue) => {return {...datum, [property]: propertyValue}})}), property);
  };

  const countByNested = (data:any, property:string) => {
    const group = groupByNested(data, property);
    return _.mapValues(group, (datum) => datum.length);
  };

  const countCategories = (categories:any) => {
    const countByValue = countByNested(props.analyticsEvents, 'areasOfInterest');

    return _.map(categories, (category:any) => countByValue[category.value]);

  };

  const data = {
    labels: _.map(categoriesData, 'label'),
    datasets: [
      {
        label: props.title || 'Industria 4.0',
        backgroundColor: _.map(categoriesData, 'color'),
        data: countCategories(categoriesData)
      }
    ]
  };

  const options = {
    legend: {
      display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem:any) => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
        title: () => null,
      }
    },
  };


  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>{props.title || 'Industria 4.0'}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <Bar options={options} data={data}/>
      </IonCardContent>
    </IonCard>
  )
};
export default AreasOfInterestChart;
