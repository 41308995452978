// imports
import React from 'react';
import styles from './Profiles.module.css';
import ProfileCard from "../../../shared/components/ProfileCard/ProfileCard";

const Profiles = (props: any) => {
  return (
    <section className={styles['profiles']}>
      <h4 className={styles['list-title']}>{props.listTitle}</h4>
      <div className={styles['cards-container']}>
        {props.profiles.map((profile: any, index:number) => <ProfileCard key={profile.id || index} profile={profile}/>)}
      </div>
    </section>
  );
};
export default Profiles;
