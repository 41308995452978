import React from 'react';



const sharedContext = React.createContext({
    headerTitle: '',
    updateHeaderTitle: (title: string) =>{},
    activeBreakpoint: '',
    updateBreakpoint: (breakpoint: string) =>{},
    isMobile: false,
    updateIsMobile: (flag: boolean) =>{}
});

export default sharedContext;
