import {UploadedFile} from "../interfaces/loopback";


export class UploadedFileApi {
  static RESOURCE_URL = '/Images/';
  static baseUrl = `https://${process.env.REACT_APP_CONTAINER}/`

  static getFileUrl(file: UploadedFile | any): any {
    if(!file) {
      return null;
    }
    return `${UploadedFileApi.baseUrl}${file.containerFileName}`;
  }

  static getImageUrl(file: UploadedFile | any, projectType?: string): any {
    if(!file) {
      if(projectType) {
        return `/assets/imgs/default-${projectType}.jpg`;
      }
      return `/assets/imgs/placeholder.png`;
      // return 'https://picsum.photos/300/300?random=' + Math.floor(Math.random() * Math.floor(100))
    }
    return `${UploadedFileApi.baseUrl}${file.containerFileName}`;
  }



}
