import {IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle} from "@ionic/react";
import styles from "./TotalsChart.module.css";
import React from "react";
import {Line} from "react-chartjs-2";
import _ from "lodash";
import {AnalyticsEvent} from "../../../interfaces/loopback";
import moment from "moment";

const TotalsChart = (props: any) => {

  if(!props.analyticsEvents) {
    return null;
  }

  const groupByTimeUnit = (analyticsEvents:AnalyticsEvent[], unitOfTime:any) => {
    const groupedObject =_.reduce(analyticsEvents, (results:any, analyticsEvent:AnalyticsEvent) => {
      // console.log(analyticsEvent.createdAt);
      const startDate = moment(analyticsEvent.createdAt).startOf(unitOfTime).valueOf();
      // console.log(moment(analyticsEvent.createdAt).startOf(unitOfTime));
      if(!results[startDate]) {
        results[startDate] = 0;
      }
      results[startDate]++;
      return results;
    }, {});

    // console.log('groupByTimeUnit', groupByTimeUnit);

    const groupedArray: any[] = [];

    _.forEach(groupedObject, (value:number, date:any) => {
      groupedArray.push({
        t: +date,
        y: value
      });
    });

    // console.log('groupedArray', _.orderBy(groupedArray, 't'));

    return _.orderBy(groupedArray, 't');

  };

  const data = {
    datasets: [
      {
        data: groupByTimeUnit(props.analyticsEvents, props.unitOfTime || 'month')
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: -10,
        right: 0,
        top: 0,
        bottom: -10
      }
    },
    scales: {
      xAxes: [{
        type: 'time',
        time: {
          unit: props.unitOfTime
        },
        ticks: {
          display: false
        },
        gridLines: {
          display:false
        }
      }],
      yAxes: [{
        ticks: {
          display: false
        },
        gridLines: {
          display:false
        }
      }]
    },
    legend: {
      display: false
    },
    tooltips: {
      enabled: false
    },
  };


  return (
    <IonCard class={styles['totals-chart-card']} style={{backgroundColor: props.color}}>
      <IonCardHeader>
        <IonCardTitle>{props.analyticsEvents.length}</IonCardTitle>
        <IonCardSubtitle>{props.title}</IonCardSubtitle>
      </IonCardHeader>
      <Line options={options} data={data}/>
    </IonCard>
  )
};
export default TotalsChart;
