import React from "react";
import {IonToast} from "@ionic/react";

const Toast = (props: any) => {
  return (
    <IonToast
      color={(props.type === 'error' ? 'danger' : '')}
      isOpen={props.isOpen}
      onDidDismiss={props.onDidDismiss}
      message={props.message}
      duration={2000}
      buttons={[{
        text: 'Aceptar',
        role: 'cancel',
      }]}
    >
    </IonToast>
  );
};
export default Toast;
