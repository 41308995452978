// imports
import React, {useContext, useEffect, useRef} from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid, IonIcon, IonItem, IonLabel,
  IonRow
} from "@ionic/react";

import {withAuthInfo} from '../../shared/services/UserInfo';
import SharedContext from "../../shared/context/SharedContext";

import {NavLink} from "react-router-dom";
import {Route, Switch} from "react-router";

import ViewVideo from "./ViewVideo";


const routes:any = [
  {
    label: 'Crear cuenta',
    path: 'crear-cuenta'
  },
  {
    label: 'Crear ficha',
    path: 'crear-ficha'
  },
  {
    label: 'Editar imagen',
    path: 'editar-imagen'
  },
  {
    label: 'Editar perfil',
    path: 'editar-perfil'
  },
  {
    label: 'Explorar',
    path: 'explorar'
  },
  {
    label: 'Favoritos',
    path: 'favoritos'
  },
  {
    label: 'Llenar perfil',
    path: 'llenar-perfil'
  },
  {
    label: 'Vinculación',
    path: 'vinculacion'
  },
];


const Help = withAuthInfo((props: any) => {
  const sharedContext = useContext(SharedContext);
  const refIsMounted = useRef(true);

  useEffect(() => {
    sharedContext.updateHeaderTitle(`Tutoriales`);
  }, [sharedContext]);


  return (
    <>

      <h1 className="section-title">Tutoriales</h1>
      <IonGrid>
        <IonRow>
          <IonCol class={'ion-align-self-stretch'} size={'12'} size-md={'3'}>
            {routes.map((route:any, index:number) =>
              // @ts-ignore
              <NavLink key={index} to={props.match.path + "/video/" + route.path} activeStyle={{ backgroundColor: 'red' }} activeClassName="active">
                <IonItem button>
                  <IonLabel>
                    {route.label}
                  </IonLabel>
                </IonItem>
              </NavLink>)}
          </IonCol>
          <IonCol size={'12'} size-md={'9'}>
            <Switch>
              <Route path={props.match.path + "/video/:path/"} component={ViewVideo}/>
            </Switch>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
    // <IonContent style={{height: '100%'}} class={classes('full-height', styles['about-content'])}>
    //
    //   <h1 className={'section-title'}>Crear cuenta</h1>
    //
    //   <h1 className={'section-title'}>Llenar perfil</h1>
    //
    //   <h1 className={'section-title'}>Crear ficha</h1>
    //   <video width="100%" height="auto" controls>
    //     <source src="/assets/videos/crear-ficha.mp4" type="video/mp4" />
    //     <source src="/assets/videos/crear-ficha.webm" type="video/webm" />
    //     Tu explorador no soporta videos
    //   </video>
    //
    //   <h1 className={'section-title'}>Editar imagen</h1>
    //
    //   <h1 className={'section-title'}>Editar perfil</h1>
    //
    //   <h1 className={'section-title'}>Explorar</h1>
    //
    //   <h1 className={'section-title'}>Vinculación</h1>
    //
    //   <h1 className={'section-title'}>Favoritos</h1>
    //
    //
    // </IonContent>
  );
});
export default Help;
